import { ListPrinterIdResponse, ListPrinterIdResults } from './list-printer-id.service.types';

export const ListPrinterIdTransform: (response: ListPrinterIdResponse) => ListPrinterIdResults = (listPrinterIdResults) => {
  const { data, is_error, message } = listPrinterIdResults;
  const result: ListPrinterIdResults = {
    data: [],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data;
    result.message = 'executed';
  }
  return result;
};

import React from 'react';
import { Line } from 'react-chartjs-2';
import { ChartOptions, ChartType } from 'chart.js';
import { SATrendResult } from '../../../services/reports-all/superadmin-dashboard/superadmin-trend-status/superadmin-trend-sts.types';

interface LineChartComponentProps {
  data: SATrendResult[];
}

const LineChartComponent: React.FC<LineChartComponentProps> = ({ data }) => {
  const stateDateTotalsMap = new Map<string, Map<string, number>>();

  data.forEach((status) => {
    const dateMap = stateDateTotalsMap.get(status.st) || new Map<string, number>();

    status.statusData.forEach((entry) => {
      dateMap.set(status.dt, (dateMap.get(status.dt) || 0) + entry.tCnt);
    });

    stateDateTotalsMap.set(status.st, dateMap);
  });

  const stateCodes = Array.from(stateDateTotalsMap.keys());
  const allDates = Array.from(new Set(data.map((status) => status.dt)));

  const getRandomDarkColor = (): string => {
    const getRandomHex = () => Math.floor(Math.random() * 128).toString(16).padStart(2, '0');
    // Generate dark colors in shades of red, green, and purple
    const red = getRandomHex();
    const green = getRandomHex();
    const purple = getRandomHex();

    return `#${red}${green}${purple}`;
  };

  const datasets = stateCodes.map((state) => ({
    label: state,
    data: allDates.map((date) => stateDateTotalsMap.get(state)?.get(date) || 0),
    fill: false,
    borderColor: getRandomDarkColor(),
    borderWidth: 2,
  }));

  const chartData = {
    labels: allDates,
    datasets,
  };

  const chartOptions: ChartOptions<ChartType> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Total Count',
        },
      },
    },
  };

  return (
    <div>
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};

export default LineChartComponent;

// import React from 'react';
// import { Bar } from 'react-chartjs-2';
// import { ChartOptions, ChartType } from 'chart.js';
// import { SATrendResult } from '../../../services/reports-all/superadmin-dashboard/superadmin-trend-status/superadmin-trend-sts.types';

// interface BarChartComponentProps {
//   data: SATrendResult[];
// }

// const BarChartComponent: React.FC<BarChartComponentProps> = ({ data }) => {
//   const stateTotals: { [key: string]: number } = {};

//   // Calculate the total tCnt for each state
//   data.forEach((status) => {
//     const total = status.statusData.reduce((acc, entry) => acc + entry.tCnt, 0);
//     stateTotals[status.st] = total;
//   });

//   const stateCodes = Object.keys(stateTotals);
//   const totalCounts = Object.values(stateTotals);

//   const getRandomDarkColor = (): string => {
//     const getRandomHex = () => Math.floor(Math.random() * 128).toString(16).padStart(2, '0');
//     const red = getRandomHex();
//     const green = getRandomHex();
//     const purple = getRandomHex();
//     return `#${red}${green}${purple}`;
//   };

//   const chartData = {
//     labels: stateCodes,
//     datasets: [
//       {
//         label: 'Total Count',
//         data: totalCounts,
//         backgroundColor: stateCodes.map(() => getRandomDarkColor()),
//         borderWidth: 1,
//       },
//     ],
//   };

//   const chartOptions: ChartOptions<ChartType> = {
//     responsive: true,
//     maintainAspectRatio: false,
//     scales: {
//       x: {
//         title: {
//           display: true,
//           text: 'State',
//         },
//       },
//       y: {
//         title: {
//           display: true,
//           text: 'Total Count',
//         },
//       },
//     },
//   };

//   return (
//     <div>
//       <Bar data={chartData} options={chartOptions} />
//     </div>
//   );
// };

// export default BarChartComponent;

import React, { useState } from 'react';
import {
  Button,
  Card,
  Col, Input, Modal, ModalBody, ModalHeader, Row,
} from 'reactstrap';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileAlt, faXmarkSquare,
} from '@fortawesome/free-solid-svg-icons';
// import { successAlert } from '../../../../utils/alert';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import LoaderData from '../../../../utils/loader';
import { ArrayFileData, CreateAWSFilesResult } from '../../../../services/upload-AWS-files/create-awsfiles.types';
import {
  clearAWSFilesUpload,
  clearCheckRefFileReq,
  clearPrintId,
  clearRefFileListReq, getAWSFilesUpload, getListPrinterId, getPrintId, getStateListReq,
} from '../../../../store/yoCard/actions';
import { getConfig } from '../../../../utils/config/config';
import { getTokenFromLocalStorage, getUserProfileSessionStorage, getUserTpSessionStorage } from '../../../../utils/service/localstorage-service';
import { APIHeader, UserType } from '../../../../utils/constants';
import { errorAlert, successAlert } from '../../../../utils/alert';
import { ActiveTabProps } from './print.type';
import { StateResult } from '../../../../services/meta-data/list-state/list-state.types';
import { PrinterIdData } from '../../../../services/meta-data/list-printer-id/list-printer-id.types';

const { AWS_CARD_ACTIVITY_BUCKET } = getConfig();

export const UploadPrintZip: React.FC<ActiveTabProps> = ({ activeTab }) => {
  const dispatch = useDispatch();
  const userTp = getUserTpSessionStorage();
  const tokenData = getTokenFromLocalStorage();
  const userProfile = getUserProfileSessionStorage();
  const [fileSuccess, setFileSuccess] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [stateData, setStateData] = useState('');
  const [printUserData, setPrintUserData] = useState('');
  const [loaderState, setLoaderState] = useState(false);
  const [loaderPrinter, setLoaderPrinter] = useState(false);
  const [stateListData, setStateListData] = useState<StateResult[]>([]);
  const [printerListData, setPrinterListData] = useState<PrinterIdData[]>([]);
  const stateResData = useSelector((state: RootState) => state.yoCard.getStateMetaList);
  const printResData = useSelector((state: RootState) => state.yoCard.getAllPrinterId);
  const [alertAPIMsg, setAlertAPIMsg] = useState('');
  const [alertMsg] = useState<CreateAWSFilesResult>();
  const [userId, setUserId] = useState<string>('');
  const [uploadLoader, setUploadLoader] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<ArrayFileData[]>([]);
  const [distCd, setDistCd] = useState<string[]>([]);
  const [dateData, setDateData] = useState<string>('');
  const [isS3Upload, setIsS3Upload] = useState(false);
  const uploadFilesResponse = useSelector((state: RootState) => state.yoCard.updateAWSFilesData);
  const updateCardStatusResponse = useSelector((state: RootState) => state.yoCard.createPrintId);
  const [isUploaded, setIsUploaded] = useState(false);
  const isUploadDisabled = selectedFiles.length === 0 || isUploaded;

  // Start Get Printer User List

  const printeroptions = printerListData && printerListData.map((print) => ({
    label: print.nme,
    value: print.UsrCd,
  }));

  const selectedPrinterOptions = printeroptions && printeroptions.find(
    (option) => option.value === printUserData,
  );

  React.useEffect(() => {
    setLoaderPrinter(true);
    dispatch(getListPrinterId({
      requestType: APIHeader.REQ_ALL_PRINTER_ID,
      token: tokenData,
      userType: UserType.SADM,
    }));
  }, []);

  React.useEffect(() => {
    if (printResData.isError && printResData.message !== '') {
      setLoaderPrinter(false);
    }
  }, [printResData.data]);

  React.useEffect(() => {
    if (!printResData.isError && printResData.message === 'executed') {
      setLoaderPrinter(false);
      setPrinterListData(printResData.data);
    }
  }, [printResData.data]);

  const handleSelectPrinterId = (e:any) => {
    setPrintUserData(e.value);
  };

  // End Get Printer User List
  const stateoptions = stateListData && stateListData.map((state) => ({
    label: state.nme,
    value: state.MetaId,
  }));

  const selectedStateOptions = stateoptions && stateoptions.find(
    (option) => option.value === stateData,
  );

  React.useEffect(() => {
    setLoaderState(true);
    dispatch(getStateListReq({
      requestType: APIHeader.REQ_GET_ALL_ST,
    }));
  }, []);

  React.useEffect(() => {
    if (!stateResData.isError) {
      setLoaderState(false);
      setStateListData(stateResData.stData);
    }
  }, [stateResData.stData]);

  const handleSelectState = (e:any) => {
    setStateData(e.value);
    // dispatch(getDistListReq({
    //   requestType: APIHeader.REQ_GET_ALL_DIST,
    //   state: e.value,
    // }));
  };

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.UserCd) {
      setUserId(userProfile && JSON.parse(userProfile).profileData.UserCd);
    }
  }, [userId, userProfile && JSON.parse(userProfile).profileData.UserCd]);

  const converttoKb = (byteData:number) => {
    const kilobytes = byteData / 1024;
    return Math.ceil(kilobytes);
  };

  const handleClearRequest = () => {
    dispatch(clearCheckRefFileReq());
    dispatch(clearRefFileListReq());
    dispatch(clearAWSFilesUpload());
    setFileSuccess(false);
  };

  const handleFieldChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDateData(e.target.value);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsS3Upload(false);
    setIsUploaded(false);
    // setUploadLoader(true);
    const newFiles: File[] = Array.from(event.target.files || []);
    const newFileItems: ArrayFileData[] = newFiles.map((file, index) => ({
      id: index,
      file,
      status: false,
      loc: '',
    }));

    const extractedFileNames = newFileItems.map((newFileItem) => newFileItem.file.name.split('_').pop()?.split('.')[0] || '');
    const uniqueExtractedFileNames = Array.from(new Set(extractedFileNames));
    const nonDupRefFiles = newFileItems.filter((newFileItem) => {
      const existingFile = selectedFiles.find((selectedFile) => selectedFile.file.name === newFileItem.file.name);
      return !existingFile;
    });
    setSelectedFiles([...selectedFiles, ...nonDupRefFiles]);
    setDistCd((prevDistCd) => [...prevDistCd, ...uniqueExtractedFileNames]);
  };

  React.useEffect(() => {
    if (updateCardStatusResponse.error && updateCardStatusResponse.message !== '' && activeTab === 'zipUpload') {
      setUploadLoader(false);
      setAlertAPIMsg(updateCardStatusResponse.message);
      setSuccessShowAlert(true);
    }
  }, [updateCardStatusResponse.error, updateCardStatusResponse.message]);

  React.useEffect(() => {
    if (!updateCardStatusResponse.error && updateCardStatusResponse.message === 'Data updated successfully' && activeTab === 'zipUpload') {
      setUploadLoader(false);
      setAlertAPIMsg(updateCardStatusResponse.message);
      dispatch(clearPrintId());
      setSuccessShowAlert(true);
    }
  }, [updateCardStatusResponse.error, updateCardStatusResponse.message]);

  const handleRemoveFile = (filenameToDelete: string) => {
    const updatedFiles = selectedFiles.filter((file) => file.file.name !== filenameToDelete);
    setSelectedFiles(updatedFiles);
  };

  const formatTimestamp = (timestamp: number) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const handleUpload = () => {
    setUploadLoader(true);
    Promise.resolve(
      dispatch(getAWSFilesUpload({
        bucketNme: AWS_CARD_ACTIVITY_BUCKET,
        fileDate: dateData,
        selectedFiles,
        requestType: '',
        stCd: stateData,
        distFolderId: '',
        isZip: true,
        cardTp: '',
      })),
    )
      .then(() => {
        dispatch(clearAWSFilesUpload());
      });
  };

  React.useEffect(() => {
    if (uploadFilesResponse.message === 'executed' && activeTab === 'zipUpload') {
      setUploadLoader(true);
      dispatch(getPrintId({
        userType: userTp,
        requestType: APIHeader.REQ_CREATE_PRINTER_ID,
        distCd,
        crdDt: dateData,
        printId: printUserData,
        token: tokenData,
      }));
      // dispatch(getCardMassStatus({
      //   userType: userTp,
      //   requestType: APIHeader.REQ_MASS_STS_UPDATE,
      //   stData: stateData,
      //   crdDt: dateData,
      //   crdSts: 'PRNTR',
      //   token: tokenData,
      // }));
      const updatedSelectedFiles = selectedFiles.map((fileData) => {
        if (uploadFilesResponse.success.includes(fileData.file.name)) {
          return { ...fileData, status: true };
        } if (uploadFilesResponse.failed.includes(fileData.file.name)) {
          return { ...fileData, status: false };
        }
        return fileData;
      });
      setSelectedFiles(updatedSelectedFiles);
      setIsS3Upload(true);
      setIsUploaded(true);
    } else {
      setUploadLoader(false);
      setIsUploaded(false);
    }
  }, [uploadFilesResponse.message, uploadFilesResponse.success, uploadFilesResponse.failed, activeTab]);

  return (
    <div className="border-tabs pt-2">
      { uploadLoader ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertAPIMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {showSuccessAlert ? (
        successAlert(false, alertAPIMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      <Row className="mx-2 align-center print-sadmin-card-box">
        <span>Upload Zip Files</span>
        <Col lg="6" className="pt-4">
          <Row className="align-center mb-2">
            <Col lg="12" className="pt-2">
              <h6 className="input-header-text">State<span className="mandate-star">*</span></h6>
              <Select
                value={stateData === '' ? null : selectedStateOptions}
                options={stateoptions}
                onChange={handleSelectState}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (base) => ({
                    ...base,
                    // position: 'relative',
                  }),
                  control: (base: any) => ({
                    ...base,
                    '&:hover': { borderColor: '#880000' },
                    border: '1px solid #DDE0E4',
                    minHeight: '40px',
                    borderRadius: '5px',
                    padding: 1,
                    fontSize: '15px',
                    fontWeight: 400,
                    color: '#575656',
                    boxShadow: 'none',
                    backgroundColor: '#fff',
                    '&:focus': {
                      borderColor: '#E1EDF8',
                      boxShadow: 'none',
                      color: '#575656',
                      background: '#fff',
                    },
                  }),
                  option: (provided: any, state: { isSelected: any; }) => ({
                    ...provided,
                    color: state.isSelected ? '#383838' : '#212121',
                    padding: 15,
                    textAlign: 'left',
                    background: state.isSelected ? '#FAFCFB' : '#fff',
                    '&:hover': {
                      background: '#FAFCFB',
                    },
                  }),
                }}
                isLoading={loaderState}
                noOptionsMessage={() => 'No State Found'}
                placeholder="Select State"
              />
            </Col>
            <Col lg="5" className="pt-2">
              <div>
                <h6 className="input-header-text mx-1 my-1">Date<span className="mandate-star">*</span></h6>
                <Input
                  type="date"
                  name="refDt"
                  onChange={handleFieldChangeDate}
                  className="yocard-input-ref"
                />
              </div>
            </Col>
            <Col lg="7" className="pt-2">
              <h6 className="input-header-text">Assigned Printer<span className="mandate-star">*</span></h6>
              <Select
                value={printUserData === '' ? null : selectedPrinterOptions}
                options={printeroptions}
                onChange={handleSelectPrinterId}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (base) => ({
                    ...base,
                    // position: 'relative',
                  }),
                  control: (base: any) => ({
                    ...base,
                    '&:hover': { borderColor: '#880000' },
                    border: '1px solid #DDE0E4',
                    minHeight: '40px',
                    borderRadius: '5px',
                    padding: 1,
                    fontSize: '15px',
                    fontWeight: 400,
                    color: '#575656',
                    boxShadow: 'none',
                    backgroundColor: '#fff',
                    '&:focus': {
                      borderColor: '#E1EDF8',
                      boxShadow: 'none',
                      color: '#575656',
                      background: '#fff',
                    },
                  }),
                  option: (provided: any, state: { isSelected: any; }) => ({
                    ...provided,
                    color: state.isSelected ? '#383838' : '#212121',
                    padding: 15,
                    textAlign: 'left',
                    background: state.isSelected ? '#FAFCFB' : '#fff',
                    '&:hover': {
                      background: '#FAFCFB',
                    },
                  }),
                }}
                isLoading={loaderPrinter}
                noOptionsMessage={() => 'No User Found'}
                placeholder="Select User Printer"
              />
            </Col>
          </Row>
        </Col>
        <Col lg="6">
          <div className="print-sadmin-kb-file-upload pt-4 mx-1 my-3">
            <div className="print-sadmin-file-upload-box">
              <Input
                accept=".zip"
                type="file"
                disabled={dateData === '' || stateData === '' || printUserData === ''}
                id="fileRefupload"
                className="print-sadmin-file-upload-input"
                onChange={handleFileChange}
                multiple
                key={selectedFiles.length}
              />
              <span>Drag and drop or <span className="dcord-file-link">Choose your files</span></span>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mx-1 align-center">
        {selectedFiles.length === 0 ? (
          <div className="pt-5 align-center">
            <span className="form-collapse-card-header">No Record Found</span>
          </div>
        )
          : (
            selectedFiles.map((data, index) => (
              <Col lg="5" className="pt-4" key={`file-${index.toString()}`}>
                <Card>
                  <div className="align-end my-1 mx-1">
                    {isS3Upload
                      ? data.status ? <FontAwesomeIcon icon={faCircleCheck} className="success-zip" /> : <span className="decline-zip">Failed</span> : null}
                  </div>
                  <div className="pt-2 ml-1">
                    <div className="pt-1">
                      <FontAwesomeIcon icon={faFileAlt} className="fa-file-zip" />
                      <span className="mx-2 print-sadmin-card-dash-header">{data.file.name}</span>
                    </div>
                    <div className="ml-2">
                      <span className="ml-2 text-lite-grey">size: {converttoKb(data.file.size)} kb,{' '}</span>
                      <span className="text-lite-grey">modified: {formatTimestamp(data.file.lastModified)}</span>
                    </div>
                  </div>
                  <div className="pt-2 align-end mx-2 my-2">
                    {!data.status
                      ? <Button type="button" className="yocard-button-plain" onClick={() => handleRemoveFile(data.file.name)}>Delete</Button>
                      : null }
                  </div>
                </Card>
              </Col>
            ))
          ) }
        <div className="align-center pt-5">
          <Button onClick={handleUpload} className="mx-2 yocard-button-oval" disabled={isUploadDisabled}>Upload</Button>
        </div>
      </Row>
      <div className="mb-3" />
      <Modal
        isOpen={fileSuccess}
        className="my-modal-container-likes"
      >
        <ModalHeader className="form-card-header">
          <div className="align-end">
            <Button className="button-icon" onClick={handleClearRequest}>
              <FontAwesomeIcon className="dashboard-close-button" icon={faXmarkSquare} />
              <span className="dashboard-close-text mx-1">Close</span>
            </Button>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="mx-2">
            {alertMsg && alertMsg.success.length > 0 && (
              <div className="">
                <span className="files-upload-text-success mb">Successfully uploaded({alertMsg && alertMsg.success.length} files)</span>
                <div className="pt-3 mx-2">
                  {alertMsg && alertMsg.success.map((filename) => (
                    <div className="file-atc-box" key={filename}>
                      <div className="file-image-success">
                        <FontAwesomeIcon icon={faFileAlt} className="fa-file-gen" />
                      </div>
                      <div className="file-detail">
                        <h6 className="input-header-text">{filename}</h6>
                        <p />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {alertMsg && alertMsg.failed.length > 0 && (
              <div className="">
                <span className="files-upload-text-error mb">Failed to upload files</span>
                <div className="pt-3 mx-2">
                  {alertMsg && alertMsg.failed.map((filename) => (
                    <div className="file-atc-box" key={filename}>
                      <div className="file-image-error">
                        <FontAwesomeIcon icon={faFileAlt} className="fa-file-gen" />
                      </div>
                      <div className="file-detail">
                        <h6 className="input-header-text">{filename}</h6>
                        <p />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UploadPrintZip;

import { EditBillingStatusResponse, EditBillingStatusResults } from './update-billing-status.service.types';

export const EditBillingStatusTransform: (response: EditBillingStatusResponse) => EditBillingStatusResults = (billingStsResults) => {
  const { data, is_error, message } = billingStsResults;
  const result: EditBillingStatusResults = {
    message: '',
    error: false,
    id: '',
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.message = 'executed';
    result.id = data.message;
  }
  return result;
};

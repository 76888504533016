import {
  batch, Fade, Animator, Move,
} from 'react-scroll-motion';
import {
  CardImg,
  Col, Row,
} from 'reactstrap';
import './home.scss';

export const ProcessYoCard: React.FC = () => {
  const FadeUp = batch(Fade(), Move());
  return (
    <Animator animation={batch(FadeUp)} className="yoCard-bg">
      <Row>
        <Col xs="12">
          <div className="ml-2 align-center">
            <span className="display-6 text-intro-header">
              Process Simplified
            </span>
          </div>
          <div className="pt-5">
            <Row className="align-center mx-2 pt-5">
              <Col lg={{ size: 4, order: 1 }} xs={{ order: 1 }}>
                <span className="text-about-cards pt-5 align-text-justify mx-2">
                  Secure the image ID needed for scanning and converting into a print-ready format, ensuring
                  streamlined and efficient image transformation for printing purposes.
                </span>
              </Col>
              <Col lg={{ size: 6, order: 2 }} xs={{ order: 2 }}>
                <CardImg
                  className="process-image"
                  src="images/home/raw.png"
                />
              </Col>
              {/* <Col lg="4" xs="12">
                <Card className="form-card-shadow process-card-align">
                  <CardImg
                    className="process-image"
                    src="images/home/raw.png"
                  />
                  <CardBody>
                    <span className="text-about-cards pt-5 align-text-justify mx-2">
                      Secure the image ID needed for scanning and converting into a print-ready format, ensuring
                      streamlined and efficient image transformation for printing purposes.
                    </span>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4" xs="12">
                <Card className="form-card-shadow process-card-align">
                  <CardImg
                    className="process-image"
                    src="images/home/convert-card.png"
                  />
                  <CardBody>
                    <span className="text-about-cards pt-5 align-text-justify mx-2">
                      The system adeptly manages large-scale record processing by scanning individual card components, readying them for printing.
                    </span>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4" xs="12">
                <Card className="form-card-shadow process-card-align">
                  <CardImg
                    className="process-image"
                    src="images/home/print.png"
                  />
                  <CardBody>
                    <span className="text-about-cards pt-5 align-text-justify mx-2">
                      Prepare these cards to be print-ready, ensuring they are set for optimal printing quality and efficiency.
                    </span>
                  </CardBody>
                </Card>
              </Col> */}
            </Row>
          </div>
        </Col>
      </Row>
      <Row className="align-center mx-2 pt-5">
        <Col lg={{ size: 4, order: 2 }} xs={{ order: 1 }}>
          <span className="text-about-cards pt-5 align-text-justify mx-2">
            The system adeptly manages large-scale record processing by scanning individual
            card components, readying them for printing.
          </span>
        </Col>
        <Col lg={{ size: 6, order: 1 }} xs={{ order: 2 }}>
          <CardImg
            className="process-image"
            src="images/home/convert-card.png"
          />
        </Col>
      </Row>
      <Row className="align-center mx-2 pt-5">
        <Col lg={{ size: 4, order: 1 }} xs={{ order: 1 }}>
          <span className="text-about-cards pt-5 align-text-justify mx-2">
            Prepare these cards to be print-ready, ensuring they are set for optimal printing quality and efficiency.
          </span>
        </Col>
        <Col lg={{ size: 6, order: 2 }} xs={{ order: 2 }}>
          <CardImg
            className="process-image"
            src="images/home/print.png"
          />
        </Col>
      </Row>
    </Animator>
  );
};

export default ProcessYoCard;

import { CreateAWSFilesResponse, CreateAWSFilesResults } from './create-awsfiles.service.types';

export const CreateAWSFilesTransform: (response: CreateAWSFilesResponse) => CreateAWSFilesResults = (createAWSFilesDBResults) => {
  const { success, failed, message } = createAWSFilesDBResults;
  const result: CreateAWSFilesResults = {
    success: [],
    failed: [],
    message: '',
  };
  result.success = success;
  result.failed = failed;
  result.message = message;
  return result;
};

import {
  faBoxesStacked, faUsersBetweenLines,
} from '@fortawesome/free-solid-svg-icons';
// faSuitcase
import {
  Menu,
  MenuItem,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './state-admin.scss';
import { Nav } from 'reactstrap';
import { SideDrawerProps } from './side-drawer.type';

export const STAdminSideDrawer: React.FC<SideDrawerProps> = ({
  menuCollapse, setClick,
}) => {
  const location = useLocation();
  const path = location.pathname.split('/');
  const [menuItems, setMenuItems] = useState({
    dashboard: !!path[2].match('dashboard'),
    agents: !!path[2].match('my-user'),
    uploads: !!path[2].match('uploads'),
  });
  const [defaultState, setDefaultState] = useState(true);

  React.useEffect(() => {
    if (defaultState) {
      if (path[3] === '') {
        setMenuItems({
          dashboard: true,
          agents: false,
          uploads: false,
        });
      }
      if (path[3] === 'dashboard') {
        setMenuItems({
          dashboard: true,
          agents: false,
          uploads: false,
        });
      }
      if (path[3] === 'my-user') {
        setMenuItems({
          dashboard: false,
          agents: true,
          uploads: false,
        });
      }
      if (path[3] === 'uploads') {
        setMenuItems({
          dashboard: false,
          agents: false,
          uploads: true,
        });
      }
      setDefaultState(true);
    }
  }, [defaultState, path[3]]);

  const handleOnClickMenuItem = (item: string) => {
    if (item === 'dashboard') {
      setClick(!menuCollapse);
      setMenuItems({
        dashboard: true,
        agents: false,
        uploads: false,
      });
    } else if (item === 'my-user') {
      setClick(!menuCollapse);
      setMenuItems({
        dashboard: false,
        agents: true,
        uploads: false,
      });
    } else if (item === 'uploads') {
      setClick(!menuCollapse);
      setMenuItems({
        dashboard: false,
        agents: false,
        uploads: true,
      });
    }
  };

  return (
    <div>
      <Nav
        collapsed={menuCollapse ? 'true' : 'false'}
        className={!menuCollapse ? 'pro-sidebar-sadmin' : 'pro-sidebar-sadmin-hide'}
      >
        <div className="pt-3 pro-sidebar-sadmin-bg">
          <Menu iconShape="square">
            <MenuItem active={menuItems.dashboard} className="menu-item-sadm" onClick={() => handleOnClickMenuItem('dashboard')}>
              <Link to="dashboard">
                <span className="menu-icon-sadm">
                  <FontAwesomeIcon icon={faBoxesStacked} />
                </span>
                Dashboard
              </Link>
            </MenuItem>
            <MenuItem active={menuItems.uploads} className="menu-item-sadm" onClick={() => handleOnClickMenuItem('uploads')}>
              <Link to="uploads">
                <span className="menu-icon-sadm">
                  <FontAwesomeIcon icon={faBoxesStacked} />
                </span>
                Upload Files
              </Link>
            </MenuItem>
            <MenuItem active={menuItems.agents} className="menu-item-sadm" onClick={() => handleOnClickMenuItem('my-user')}>
              <Link to="my-user">
                <span className="menu-icon-sadm">
                  <FontAwesomeIcon icon={faUsersBetweenLines} />
                </span>
                My Users
              </Link>
            </MenuItem>
            <div className="pt-5" />
          </Menu>
        </div>
      </Nav>
    </div>
  );
};

export default STAdminSideDrawer;

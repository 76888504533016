import AWS from 'aws-sdk';
import {
  CreateMultipartUploadCommand,
  UploadPartCommand,
  CompleteMultipartUploadCommand,
  AbortMultipartUploadCommand,
  S3Client,
} from '@aws-sdk/client-s3';
// import { ManagedUpload } from 'aws-sdk/lib/s3/managed_upload';
import JSZip from 'jszip';
import moment from 'moment-timezone';
import { getConfig } from '../config/config';
import { CreateAvatarTransportType } from '../../services/upload-avatar/create-avatar.types';
import { ArrayFileData } from '../../services/upload-AWS-files/create-awsfiles.types';
import { GetAWSSubFolderInput } from '../../services/get-aws-sub-folders/get-subfolders.types';

const {
  USERNAME, PASSWORD, AWS_ACCESS_KEY, AWS_SECRET, AWS_REGION,
} = getConfig();

const AWS_CREDENTIALS = {
  accessKeyId: AWS_ACCESS_KEY,
  secretAccessKey: AWS_SECRET,
};

const s3 = new AWS.S3({
  accessKeyId: AWS_ACCESS_KEY,
  secretAccessKey: AWS_SECRET,
  region: AWS_REGION,
});
const s3_client = new S3Client({
  credentials: AWS_CREDENTIALS,
  region: AWS_REGION,
  // Add other configuration options as needed
});
// const listSubfolders = async (
//   bucketName: string,
//   parentFolder: string,
//   dateFolder: string,
//   subfolderCounts = new Map<string, number>(),
//   continuationToken = '',
// ): Promise<GetAWSSubFolderInput[]> => {
//   try {
//     const suFoldList = `${parentFolder}/${dateFolder}`;
//     const params: { Bucket: string; Prefix: string; ContinuationToken?: string } = {
//       Bucket: bucketName,
//       Prefix: suFoldList,
//     };

//     // Conditionally add ContinuationToken to the params
//     if (continuationToken) {
//       params.ContinuationToken = continuationToken;
//     }

//     const data = await s3.listObjectsV2(params).promise();
//     if (data.Contents && data.Contents.length > 0) {
//       data.Contents.forEach((object) => {
//         const objectKey = object.Key || '';
//         const parts = objectKey.split('/');
//         if (parts.length >= 2) {
//           const subfolder = parts[1] as string;
//           // Skip empty subfolders
//           if (subfolder && subfolder !== 'YO-CARDPRINT') {
//             if (!subfolderCounts.has(subfolder)) {
//               subfolderCounts.set(subfolder, 0);
//             }
//             if (objectKey.toLowerCase().endsWith('.pdf')) {
//               subfolderCounts.set(subfolder, (subfolderCounts.get(subfolder) || 0) + 1);
//             }
//           }
//         }
//       });
//     }
//     if (data.IsTruncated && data.NextContinuationToken) {
//       // Recursively call the function with the new ContinuationToken
//       const nextPageSubfolderCounts = await listSubfolders(
//         bucketName,
//         parentFolder,
//         dateFolder,
//         subfolderCounts,
//         data.NextContinuationToken,
//       );

//       // Merge the results from the current and next page
//       nextPageSubfolderCounts.forEach(({ subFoldr, cnt }) => {
//         if (subFoldr !== undefined && subfolderCounts.has(subFoldr)) {
//           subfolderCounts.set(subFoldr, cnt);
//         }
//       });
//     }

//     const subfolderCountsArray = Array.from(subfolderCounts, ([subFoldr, cnt]) => ({
//       subFoldr,
//       cnt,
//     }));
//     return subfolderCountsArray;
//   } catch (error) {
//     console.error('Error:', error);
//     throw error; // Throw the error to propagate it to the caller
//   }
// };

const listSubfolders = async (
  bucketName: string,
  parentFolder: string,
  dateFolder: string,
  continuationToken = '',
): Promise<{ st: string; distDet: { dist: string; cnt: number }[] } | null> => {
  const distDet: { dist: string; cnt: number }[] = [];

  try {
    const suFoldList = `${parentFolder}/`;
    const params: { Bucket: string; Prefix: string; ContinuationToken?: string } = {
      Bucket: bucketName,
      Prefix: suFoldList,
    };

    if (continuationToken) {
      params.ContinuationToken = continuationToken;
    }

    const data = await s3.listObjectsV2(params).promise();

    if (data.Contents && data.Contents.length > 0) {
      data.Contents.forEach((object) => {
        const objectKey = object.Key || '';
        const parts = objectKey.split('/');

        if (parts.length >= 3) {
          const subfolder = parts[1] as string;
          const dist = parts[1] as string; // Assuming district is the first part of the key
          // Check for PDFs in the desired date folder and specific district
          if (subfolder && dist && subfolder !== 'YO-CARDPRINT' && parts[2] === dateFolder && objectKey.toLowerCase().endsWith('.pdf')) {
            const existingDist = distDet.find((d) => d.dist === dist);

            if (existingDist) {
              existingDist.cnt += 1;
            } else {
              distDet.push({ dist, cnt: 1 });
            }
          }
        }
      });

      if (distDet.length > 0) {
        return { st: parentFolder, distDet };
      }
    }

    if (data.IsTruncated && data.NextContinuationToken) {
      // Recursively call the function with the new ContinuationToken
      const nextPageResult = await listSubfolders(
        bucketName,
        parentFolder,
        dateFolder,
        data.NextContinuationToken,
      );

      if (nextPageResult) {
        // Propagate the ContinuationToken from the next page
        nextPageResult.distDet = distDet.concat(nextPageResult.distDet);
        return nextPageResult;
      }
    }

    return null;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error:', error);
    throw error; // Throw the error to propagate it to the caller
  }
};

export const getAWSFolderList = async (bucket: string, parentFolder: string, stateFolder: string): Promise<{ awsDir: GetAWSSubFolderInput, message: string }> => {
  try {
    const data = await listSubfolders(bucket, stateFolder, parentFolder) as unknown as GetAWSSubFolderInput;
    if (data !== null) {
      return { awsDir: data, message: 'executed' };
    }
    return { awsDir: { st: '', distDet: [] }, message: 'executed' };
  } catch (error) {
    return { awsDir: { st: '', distDet: [] }, message: 'failed' };
  }
};

// This logic is to get File counts inside folder
// export const getAWSFolderFileList = async (bucketName: string, parentFolder: string): Promise<GetAWSSubFolderResult> => {
//   try {
//     const params = {
//       Bucket: bucketName,
//       Prefix: parentFolder,
//     };
//     let pdfCount = 0;
//     const data = await s3.listObjectsV2(params).promise();
//     if (data.Contents && data.Contents.length > 0) {
//       data.Contents.forEach((object) => {
//         const objectKey = object.Key || '';
//         if (objectKey.toLowerCase().endsWith('.pdf')) {
//           pdfCount += 1;
//         }
//       });
//     }
//     return { awsDir: pdfCount, message: 'executed' };
//   } catch (error) {
//     return { awsDir: 0, message: 'failed' };
//   }
// };

export const checkIfFolderExists = async (bucket: string, folderPath: string): Promise<boolean> => {
  const params = {
    Bucket: bucket,
    Prefix: folderPath,
    Delimiter: '/',
  };

  try {
    const response = await s3.listObjectsV2(params).promise();
    return response.Contents !== undefined && response.Contents.length > 0;
  } catch (error) {
    return false;
  }
};

export const createFolder = async (bucket: string, folderPath: string) => {
  try {
    await s3.putObject({ Bucket: bucket, Key: `${folderPath}/` }).promise();
  } catch (error) {
    // console.error('Error creating folder:', error);
  }
};

const MAX_RETRIES = 3;
const RETRY_DELAY_MS = 1000; // 1 second

const retryWithDelay = async <T>(func: () => Promise<T>, retries: number): Promise<T> => {
  try {
    return await func();
  } catch (error) {
    if (retries > 0) {
      // console.log(`Retrying in ${RETRY_DELAY_MS / 1000} seconds...`);
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, RETRY_DELAY_MS);
      });
      return retryWithDelay(func, retries - 1);
    }
    throw error;
  }
};

export const createFolderWithRetries = async (bucket: string, folderPath: string): Promise<void> => {
  await retryWithDelay(async () => {
    await createFolder(bucket, folderPath);
  }, MAX_RETRIES);
};

// export const ensureFoldersExist = async (
//   bucket: string,
//   currentDate: string,
//   districtFolder: string,
// ): Promise<void> => {
//   await retryWithDelay(async () => {
//     const dateFolderExists = await checkIfFolderExists(bucket, currentDate);

//     if (!dateFolderExists) {
//       await createFolderWithRetries(bucket, currentDate);
//     }

//     const fullFolderPath = `${currentDate}/${districtFolder}`;
//     const folderExists = await checkIfFolderExists(bucket, fullFolderPath);

//     if (!folderExists) {
//       await createFolderWithRetries(bucket, fullFolderPath);
//     }
//   }, MAX_RETRIES);
// };

// const MAX_RETRIES = 3;
// const RETRY_DELAY_MS = 1000;

export const uploadAWSFiles = async (
  bucket: string,
  currentDate: string,
  selectedFiles: ArrayFileData[],
  stCode: string,
  isZip: boolean,
  distFolderId: string,
  cardTp: string,
): Promise<{ success: string[]; failed: string[]; message: string }> => retryWithDelay(async () => {
  const subfolderPath = isZip
    ? `PROCESSED-OP/${stCode}/${currentDate}`
    : `${stCode}/${distFolderId}/${currentDate}/${cardTp}`;

  await createFolder(bucket, subfolderPath);

  const uploadResults = await Promise.all(selectedFiles.map(async ({ file }) => {
    const filename = file.name || 'unknown-file';
    const fileKey = `${subfolderPath}/${filename}`;

    try {
      const uploadIdResponse = await s3_client.send(
        new CreateMultipartUploadCommand({
          Bucket: bucket,
          Key: fileKey,
        }),
      );

      if (!uploadIdResponse?.UploadId) {
        await s3_client.send(
          new AbortMultipartUploadCommand({
            Bucket: bucket,
            Key: fileKey,
            UploadId: uploadIdResponse.UploadId,
          }),
        );
        throw new Error('Unable to obtain a valid UploadId');
      }

      const partSize = 5 * 1024 * 1024; // 5 MB part size (adjust as needed)

      const results = await Promise.all(
        Array.from({ length: Math.ceil(file.size / partSize) }, async (_, i) => {
          const start = i * partSize;
          const end = Math.min(start + partSize, file.size);
          const part = file.slice(start, end);

          try {
            const uploadData = await s3_client.send(
              new UploadPartCommand({
                Bucket: bucket,
                Key: fileKey,
                UploadId: uploadIdResponse.UploadId,
                PartNumber: i + 1,
                Body: part,
              }),
            );

            // console.log(`Uploaded part ${i + 1}, ETag: ${JSON.stringify(uploadData)}`);

            const etag = uploadData.ETag || 'UNKNOWN_ETAG';

            return { ETag: etag };
          } catch (uploadError) {
            // eslint-disable-next-line no-console
            console.error(`Failed to upload part ${i + 1}:`, uploadError);
            throw uploadError;
          }
        }),
      );

      // console.log('Results:', results);
      const etags = results.map((result, i) => ({
        ETag: result.ETag || 'UNKNOWN_ETAG',
        PartNumber: i + 1,
      }));

      await s3_client.send(
        new CompleteMultipartUploadCommand({
          Bucket: bucket,
          Key: fileKey,
          UploadId: uploadIdResponse.UploadId,
          MultipartUpload: {
            Parts: etags,
          },
        }),
      );

      return { status: 'success', filename };
    } catch (error) {
      // console.error('Error:', error);
      return { status: 'failed', filename };
    }
  }));

  const successFiles = uploadResults
    .filter((result) => result.status === 'success')
    .map((result) => result.filename);

  const failedFiles = uploadResults
    .filter((result) => result.status === 'failed')
    .map((result) => result.filename);

  return { success: successFiles, failed: failedFiles, message: 'executed' };
}, MAX_RETRIES);

// export const uploadAWSFiles = async (
//   bucket: string,
//   currentDate: string,
//   selectedFiles: ArrayFileData[],
//   stCode: string,
//   isZip: boolean,
//   distFolderId: string,
// ): Promise<{ success: string[], failed: string[], message: string }> => retryWithDelay(async () => {
//   // const dateFolderExists = await checkIfFolderExists(bucket, currentDate);
//   // if (!dateFolderExists) {
//   //   await createFolder(bucket, currentDate);
//   // }

//   // const subfolderName = `${currentDate}_${subFolderId}`;
//   const subfolderPath = isZip ? `PROCESSED-OP/${stCode}/${currentDate}` : `${stCode}/${distFolderId}/${currentDate}`;
//   await createFolder(bucket, subfolderPath);

//   const uploadResults = await Promise.all(selectedFiles.map(async ({ file }) => {
//     const filename = file.name || 'unknown-file';
//     const fileKey = `${subfolderPath}/${filename}`;
//     try {
//       await s3.upload({
//         Bucket: bucket,
//         Key: fileKey,
//         Body: file,
//         ACL: 'public-read',
//       }).promise();
//       return { status: 'success', filename };
//     } catch (error) {
//       return { status: 'failed', filename };
//     }
//   }));

//   const successFiles = uploadResults.filter((result) => result.status === 'success').map((result) => result.filename);
//   const failedFiles = uploadResults.filter((result) => result.status === 'failed').map((result) => result.filename);

//   return { success: successFiles, failed: failedFiles, message: 'executed' };
// }, MAX_RETRIES);

export const uploadS3File = async (bucketName: string, filename: string, formData: any): Promise<CreateAvatarTransportType> => {
  const res = await s3.upload({
    Bucket: bucketName,
    Key: filename,
    Body: formData.get('file'),
    ACL: 'public-read',
  }).promise();
  return { data: res.Key };
};

export const downloadS3File = async (bucketName: string, stData: string, distFold:string, crdDt: string) => {
  try {
    const listObjectsResponseAPL = await s3.listObjectsV2({ Bucket: bucketName, Prefix: `${stData}/${distFold}/${crdDt}/APL/` }).promise();
    const listObjectsResponseBPL = await s3.listObjectsV2({ Bucket: bucketName, Prefix: `${stData}/${distFold}/${crdDt}/BPL/` }).promise();

    const objectsAPL = listObjectsResponseAPL.Contents || [];
    const objectsBPL = listObjectsResponseBPL.Contents || [];

    const zip = new JSZip();

    const addFileToZip = async (object: any, subFolder: string) => {
      const fileName = object.Key.split('/').pop();
      const folderStructure = `${distFold}/${crdDt}/${subFolder}`;
      const districtZip = zip.folder(folderStructure) || zip;

      const s3GetObjectResponse = await s3.getObject({ Bucket: bucketName, Key: object.Key }).promise();
      const bodyData = s3GetObjectResponse.Body as string | Blob;
      districtZip.file(fileName, bodyData);
    };

    await Promise.all([
      Promise.all(objectsAPL.map((object) => addFileToZip(object, 'APL'))),
      Promise.all(objectsBPL.map((object) => addFileToZip(object, 'BPL'))),
    ]);

    // Generate the ZIP file
    const blob = await zip.generateAsync({ type: 'blob' });
    const url = window.URL.createObjectURL(blob);
    return { data: url };
  } catch (error) {
    console.error('Error:', error);
    return { data: '' };
  }
};

// export const downloadS3File = async (bucketName: string, stateCode: string, targetDate: string) => {
//   const stateFolder = `${stateCode}/`;
//   const listObjectsResponse = await s3.listObjectsV2({ Bucket: bucketName, Prefix: stateFolder }).promise();
//   const stateObjects = listObjectsResponse.Contents || [];
//   const zip = new JSZip();

//   await Promise.all(
//     stateObjects.map(async (districtObject) => {
//       const districtKey = districtObject.Key || '';
//       const districtObjectsResponse = await s3.listObjectsV2({ Bucket: bucketName, Prefix: districtKey }).promise();

//       // Check if there are any objects in the district on the target date
//       const districtContents = districtObjectsResponse.Contents || [];

//       const targetDateObjects = districtContents.filter(
//         (obj) => obj.Key?.includes(`/${targetDate}/`) && !obj.Key?.endsWith('/'),
//       );

//       if (targetDateObjects.length > 0) {
//         // Include the entire district folder in the ZIP file
//         const districtFolder = districtKey.replace(`${stateCode}/`, ''); // Remove state code from the district key
//         const zipFolderPath = `${stateCode}/${targetDate}/${districtFolder.replace(/\/$/, '')}`;
//         const districtZip = zip.folder(zipFolderPath) || zip;

//         // Use Promise.all to parallelize the asynchronous file processing
//         await Promise.all(
//           targetDateObjects.map(async (obj) => {
//             const fileName = obj.Key?.split('/').pop();
//             console.log('Processing file:', fileName);
//             if (fileName) {
//               try {
//                 const getObjectResponse = await s3.getObject({ Bucket: bucketName, Key: obj.Key! }).promise();

//                 if (getObjectResponse?.Body) {
//                   // Convert Blob to ArrayBuffer
//                   const fileContent = getObjectResponse.Body.toString();
//                   districtZip.file(fileName, fileContent, { dir: false });
//                   console.log('File added to ZIP:', fileName);
//                 } else {
//                   console.error('Error: Response Body is undefined for file:', fileName);
//                 }
//               } catch (error) {
//                 console.error('Error adding file to ZIP:', fileName, 'Error:', error);
//               }
//             }
//           }),
//         );
//       }
//     }),
//   );

//   const blob = await zip.generateAsync({ type: 'blob' });

//   if (Object.keys(zip.files).length === 0) {
//     console.log('No data found for the given date and state.');
//     return { data: '' }; // or return an appropriate message
//   }

//   console.log('Generated ZIP file:', blob);

//   const url = window.URL.createObjectURL(blob);
//   return { data: url };
// };

// export const downloadS3File = async (bucketName: string, stData: string, crdDt: string) => {
//   const s3Folder = `${stData}/${crdDt}`;
//   const listObjectsResponse = await s3.listObjectsV2({ Bucket: bucketName, Prefix: s3Folder }).promise();
//   const objects = listObjectsResponse.Contents || [];
//   const zip = new JSZip();
//   const getObjectPromises = objects.map(async (object) => {
//     const s3Key = object.Key || '';
//     const s3GetObjectResponse = await s3
//       .getObject({ Bucket: bucketName, Key: s3Key })
//       .promise();
//     const bodyData = s3GetObjectResponse.Body as string | Blob;
//     zip.file(s3Key, bodyData);
//   });
//   await Promise.all(getObjectPromises);
//   const blob = await zip.generateAsync({ type: 'blob' });
//   const url = window.URL.createObjectURL(blob);
//   return { data: url };
// };

// export const deleteS3File = async (bucketName: string, filename: string): Promise<DelPAvatarTransportType> => {
//   await s3.deleteObject({
//     Bucket: bucketName,
//     Key: filename,
//   }).promise();
//   return { data: 'success' };
// };

export const getToken = async (url: RequestInfo, requestType: string) => {
  const headers = {
    'Request-Type': requestType,
    Authorization: `Basic ${btoa(`${USERNAME}:${PASSWORD}`)}`,
  };
  const response = await fetch(url, { headers });
  const data = await response.json();
  return data;
};
const requestOptions = (method: string, token: string, requestType: string, body?: BodyInit) => ({
  method,
  headers: {
    'token-data': token,
    'Request-Type': requestType,
  },
  body: JSON.stringify(body),
});

export const getJSON = async (url: RequestInfo, token: string, requestType: string) => {
  const response = await fetch(url, requestOptions('GET', token, requestType));
  const data = await response.json();
  return data;
};
export const postJSON = async (url: RequestInfo, token: string, requestType: string, body: BodyInit) => {
  const response = await fetch(url, requestOptions('POST', token, requestType, body));
  const data = await response.json();
  return data;
};
export const putJSON = async (url: RequestInfo, token: string, requestType: string, body: BodyInit) => {
  const response = await fetch(url, requestOptions('PUT', token, requestType, body));
  const data = await response.json();
  return data;
};
export const putNoneBodyJSON = async (url: RequestInfo, token: string, requestType: string) => {
  const response = await fetch(url, requestOptions('PUT', token, requestType));
  const data = await response.json();
  return data;
};

export const deleteJSON = async (url: RequestInfo, requestType: string, token: any) => {
  const headers = {
    'Request-Type': requestType,
    'token-data': token,
  };
  const response = await fetch(url, { method: 'DELETE', headers });
  const data = await response.json();
  return data;
};
export const getLogin = async (url: RequestInfo, requestType: string, userName: string, passKey: string) => {
  const headers = {
    'Request-Type': requestType,
    Authorization: `Basic ${btoa(`${userName}:${passKey}`)}`,
  };
  const response = await fetch(url, { headers });
  const data = await response.json();
  return data;
};

export const getPublicJSON = async (url: RequestInfo, requestType: string) => {
  const headers = {
    'Request-Type': requestType,
    Authorization: `Basic ${btoa(`${USERNAME}:${PASSWORD}`)}`,
  };
  const response = await fetch(url, { headers });
  const data = await response.json();
  return data;
};

const requestOptionsPrivateFiles = (method: string, requestType: string, requestFile: string, requestData: string, token: string, formdata: FormData) => ({
  method,
  headers: {
    'token-data': token,
    'Request-Type': requestType,
    'Request-Data': requestData,
    'Request-File': requestFile,
    // 'Content-Type': formdata.type,
  },
  body: formdata,
});

const requestOptionsPublicFile = (method: string, requestType: string, requestFile: string, requestData: string, formdata: any) => ({
  method,
  headers: {
    'Request-Type': requestType,
    'Request-Data': requestData,
    'Request-File': requestFile,
    Authorization: `Basic ${btoa(`${USERNAME}:${PASSWORD}`)}`,
  },
  body: formdata,
});

const requestPublicData = (method: string, requestType: string, bodyData: any) => ({
  method,
  headers: {
    'Request-Type': requestType,
    Authorization: `Basic ${btoa(`${USERNAME}:${PASSWORD}`)}`,
  },
  body: JSON.stringify(bodyData),
});

export const postPublicFileData = async (url: RequestInfo, requestType: string, fileUpload:string, body: BodyInit) => {
  const formData = new FormData();
  formData.append('data', JSON.stringify(body));
  formData.append('files', fileUpload);
  const response = await fetch(url, requestOptionsPublicFile('POST', requestType, '', '', formData));
  const data = await response.json();
  return data;
};

export const postPublicFormData = async (url: RequestInfo, requestType: string, requestFile: string, requestData: string, body: BodyInit) => {
  const formData = new FormData();
  formData.append('data', JSON.stringify(body));
  const response = await fetch(url, requestOptionsPublicFile('POST', requestType, requestFile, requestData, formData));
  const data = await response.json();
  return data;
};

export const postPublicData = async (url: RequestInfo, requestType: string, body: BodyInit) => {
  const response = await fetch(url, requestPublicData('POST', requestType, body));
  const data = await response.json();
  return data;
};

export const putPublicData = async (url: RequestInfo, requestType: string, body: BodyInit) => {
  const response = await fetch(url, requestPublicData('PUT', requestType, body));
  const data = await response.json();
  return data;
};

export const putCommonApi = async (url: RequestInfo, requestType: string) => {
  const headers = {
    'Request-Type': requestType,
  };
  const response = await fetch(url, { method: 'PUT', headers });
  const data = await response.json();
  return data;
};

export const getCommonApi = async (url: RequestInfo, requestType: string) => {
  const headers = {
    'Request-Type': requestType,
  };
  const response = await fetch(url, { method: 'GET', headers });
  const data = await response.json();
  return data;
};

export const getCommonApiToken = async (url: RequestInfo, requestType: string, token: any) => {
  const headers = {
    'Request-Type': requestType,
    'token-data': token,
  };
  const response = await fetch(url, { method: 'GET', headers });
  const data = await response.json();
  return data;
};

export const patchApiData = async (url: RequestInfo, requestType: string, token: any) => {
  const headers = {
    'Request-Type': requestType,
    'token-data': token,
  };
  const response = await fetch(url, { method: 'PATCH', headers });
  const data = await response.json();
  return data;
};

export const patchApiBodyData = async (url: RequestInfo, token: string, requestType: string, body: BodyInit) => {
  const response = await fetch(url, requestOptions('PATCH', token, requestType, body));
  const data = await response.json();
  return data;
};

export const putPrivateFileData = async (url: RequestInfo, token: string, requestType: string, requestFile: string, requestData: string, fileData: FormData) => {
  const response = await fetch(url, requestOptionsPrivateFiles('PUT', requestType, requestFile, requestData, token, fileData));
  const data = await response.json();
  return data;
};

export const postCertFileData = async (url: RequestInfo, token: string, requestType: string, requestData: string, fileUpload:string, body: BodyInit) => {
  const formData = new FormData();
  formData.append('certData', JSON.stringify(body));
  formData.append('certFiles', fileUpload);
  const response = await fetch(url, requestOptionsPrivateFiles('PUT', requestType, '', requestData, token, formData));
  const data = await response.json();
  return data;
};

export const formatDate = (postDate: any) => {
  const now = moment();
  const date = moment.tz(postDate, 'Asia/Kolkata').add(5, 'hours').add(30, 'minutes');
  if (now.isSame(date, 'day')) {
    return `Today ${date.format('hh:mm A')}`;
  }
  if (now.year() === date.year()) {
    return date.format('DD MMM hh:mm A');
  }
  return date.format('DD MMM YYYY hh:mm A');
};

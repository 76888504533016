import { SuperAdminDashboardResponse, SuperAdminDashboardResults } from './superadmin-dashboard.service.types';

export const SuperAdminDashboardTransform: (response: SuperAdminDashboardResponse) => SuperAdminDashboardResults = (superAdminDashboardResults) => {
  const { data, is_error, message } = superAdminDashboardResults;
  const result: SuperAdminDashboardResults = {
    data: {
      stData: [
        {
          stCd: '',
          nme: '',
          distCd: [{
            distCd: '',
            nme: '',
            tCnt: '',
          }],
        },
      ],
      crntSts: {
        progCnt: 0,
        compCnt: 0,
        errCnt: 0,
      },
      usrCnt: [
        {
          uCnt: 0,
          st: '',
        },
      ],
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data.stData = data.stData ?? [];
    result.data.crntSts.progCnt = data.crntSts.progCnt ?? 0;
    result.data.crntSts.compCnt = data.crntSts.compCnt ?? 0;
    result.data.crntSts.errCnt = data.crntSts.errCnt ?? 0;
    result.data.usrCnt = data.usrCnt ?? [];
    result.message = 'executed';
  }
  return result;
};

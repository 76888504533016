import React from 'react';
import RegisterAgent from '../../components/public/register/register';
import './public.scss';
import Navbar from '../../components/public/navbar/yoCard.navbar';

export const RegisterComponent: React.FC = () => (
  <div>
    <Navbar />
    <div className="yocard-register-agent">
      <RegisterAgent />
    </div>
  </div>
);
export default RegisterComponent;

import { BillingResponse, BillingResults } from './billing.service.types';

export const BillingTransform: (response: BillingResponse) => BillingResults = (billingResults) => {
  const { data, is_error, message } = billingResults;
  const result: BillingResults = {
    data: [
      {
        PrntId: '',
        stData: {
          distNme: '',
          stCd: '',
          stNme: '',
        },
        PrntTp: '',
        courId: '',
        costPrint: '',
        stsClient: '',
        stsVendor1: '',
        stsPrnt: '',
        gstVendor1: '',
        stsZameer: '',
        cSts: '',
        updtOn: '',
        prntUsrCd: {
          st: '',
          ph: '',
          UserCd: '',
          lNme: '',
          fNme: '',
          eId: '',
        },
        updtCnt: 0,
        costVendor1: '',
        tCnt: 0,
        costCrd: '',
        gstCrd: '',
        gstPrint: '',
        cardCost: '',
        gstCardCost: '',
        tCrdCost: '',
        tPrntCost: '',
        tVendor1Cost: '',
        costWDS: '',
        gstWDS: '',
        tWDSCost: '',
        costZameer: '',
        gstZameer: '',
        tZameerCost: '',
        tWDSProf: '',
        gstWDSProf: '',
        costWDSProf: '',
        courURL: '',
        sndrNme: '',
        aCmnt: '',
        balAmnt: '',
        miscCst: '',
        courTp: '',
        courTpVal: '',
      },
    ],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data ?? [];
    result.message = 'executed';
  }
  return result;
};

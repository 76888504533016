import { CardTrendStatusResponse, CardTrendStatusResults } from './card-trend.service.types';

export const CardTrendStatusTransform: (response: CardTrendStatusResponse) => CardTrendStatusResults = (cardTrendResults) => {
  const { data, is_error, message } = cardTrendResults;
  const result: CardTrendStatusResults = {
    data: [{
      dt: '',
      statusData: [
        {
          cSts: '',
          tCnt: 0,
          dist: '',
          stsVal: '',
        },
      ],
    }],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data ?? [];
    result.message = 'executed';
  }
  return result;
};

import {
  CardImg,
  Col, Row,
} from 'reactstrap';
import './home.scss';

export const AboutWDS: React.FC = () => (
  <div className="about-wds-bg pt-3 my-1">
    <div className="ml-2 align-center">
      <span className="display-6 text-intro-sub-header">
        About Us - Discover Our Story
      </span>
    </div>
    <Row className="mx-1 pt-5 align-center">
      <Col lg={{ size: 5, order: 1 }} md={{ size: 1, order: 2 }} xs={{ order: 1 }}>
        <div className="pt-4 align-text-justify">
          <span className="text-about-cards">
            We are engaged in diverse product development initiatives, extending our support
            to various startups throughout their technological journeys.
            Our driving passion lies in revolutionizing conventional processes
            through seamless technological integration. We are committed to aiding and
            empowering everyday individuals by simplifying their lives through innovative solutions.
            <p /><br />
            With an array of ongoing product development ventures, we extend our expertise to
            nurture startups at every step of their technical evolution. Our fervent dedication
            stems from our desire to reshape the way tasks are managed effortlessly with the
            infusion of technology. By lending our aid and guidance, we strive to enhance the quality of life for the general populace, making it more convenient and accessible through our inventive approaches.
          </span>
        </div>
      </Col>
      <Col lg={{ size: 6, order: 2 }} md={{ size: 5, order: 2 }} xs={{ order: 2 }}>
        <div className="align-center pt-4">
          <CardImg
            alt="..."
            // width="auto"
            // height="350px"
            src="images/home/know-us.png"
          />
        </div>
      </Col>
    </Row>
    <div className="pt-5" />
  </div>
);

export default AboutWDS;

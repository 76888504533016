import { createAction } from 'typesafe-actions';
import {
  AGNT_DATA,
  ALL_PRINTER_ID,
  API_ERROR, BILLING, CARD_COUNT_STATE_WISE, CARD_DASHBOARD, CARD_MASS_STATUS, CARD_STATUS, CARD_TREND_STATUS, CHK_USR_EXIST, CLEAR_AGNT_DATA, CLEAR_AGNT_LIST_DATA, CLEAR_ALL_PRINTER_ID, CLEAR_AWS_FILES_UPLOAD, CLEAR_AWS_FOLDER_LIST, CLEAR_BILLING, CLEAR_CARD_COUNT_STATE_WISE, CLEAR_CARD_DASHBOARD, CLEAR_CARD_MASS_STATUS, CLEAR_CARD_STATUS, CLEAR_CARD_TREND_STATUS, CLEAR_CHECK_REF_FILE, CLEAR_CONTACT_US_DATA, CLEAR_CREATE_PRINT_ID, CLEAR_DEL_FILE_URL, CLEAR_DIST_LIST, CLEAR_DOWNLOAD_FILE, CLEAR_EDIT_BILLING_STS, CLEAR_GEN_PRINT_URL, CLEAR_GET_ALL_TXN, CLEAR_GET_TXN_REPORT, CLEAR_MODIFY_TXN, CLEAR_PASS_KEY_DATA, CLEAR_POST_REFFILE_DATA, CLEAR_POST_TXN, CLEAR_PRE_PRINT_ZIP, CLEAR_PRINTER_DASHBOARD, CLEAR_PRINT_CARD_STATUS, CLEAR_PRINT_READY, CLEAR_PRINT_READY_DATA, CLEAR_PROFILE,
  CLEAR_REFFILE_LIST,
  CLEAR_REFSTS_LIST,
  CLEAR_REGISTER_DATA, CLEAR_STATE_LIST, CLEAR_SUB_FOL_CNT_LIST, CLEAR_SUPERADMIN_DASHBOARD, CLEAR_SUPERADMIN_TREND_STS, CLEAR_TALUKA_ADM_LIST, CLEAR_TALUKA_CNT_LIST, CLEAR_TALUKA_LIST, CLEAR_UPLOAD_IMAGE_DATA, CLEAR_USER_PROFILE_PUT_DATA,
  CLEAR_USR_EXIST,
  CONTACT_US_DATA, CREATE_PRINT_ID, DEL_FILE_URL, EDIT_BILLING_STS, GEN_PRINT_URL, GET_AGNT_LIST, GET_ALL_TXN, GET_AVATAR_PUBLISH, GET_AWS_FILES_UPLOAD, GET_AWS_FOLDER_LIST, GET_CHECK_REF_FILE, GET_CITY_LIST, GET_DIST_LIST, GET_DOWNLOAD_FILE, GET_FILE_URL, GET_FORGOT_PASSWORD,
  GET_LOGIN_TOKEN, GET_PRE_PRINT_ZIP, GET_PROFILE, GET_REFFILE_LIST, GET_REFSTS_LIST, GET_STATE_LIST, GET_SUB_FOL_CNT_LIST, GET_TALUKA_ADM_LIST, GET_TALUKA_CNT_LIST, GET_TALUKA_LIST, GET_TXN_REPORT, MODIFY_TXN, PASS_KEY_DATA, POST_REFFILE_DATA, POST_TXN, PRINTER_DASHBOARD, PRINT_CARD_STATUS, PRINT_READY, PUT_PRINT_READY_DATA, REGISTER_DATA, SHOW_DATA, SUPERADMIN_DASHBOARD, SUPERADMIN_TREND_STS, UPDATE_AGNT_DATA, UPDATE_AGNT_LIST, UPDATE_ALL_PRINTER_ID, UPDATE_AVATAR_PUBLISH,
  UPDATE_AWS_FILES_UPLOAD,
  UPDATE_AWS_FOLDER_LIST,
  UPDATE_BILLING,
  UPDATE_CARD_COUNT_STATE_WISE,
  UPDATE_CARD_DASHBOARD,
  UPDATE_CARD_MASS_STATUS,
  UPDATE_CARD_STATUS,
  UPDATE_CARD_TREND_STATUS,
  UPDATE_CHECK_REF_FILE,
  UPDATE_CITY_LIST, UPDATE_CONTACT_US_DATA, UPDATE_CREATE_PRINT_ID, UPDATE_DATA, UPDATE_DEL_FILE_URL, UPDATE_DIST_LIST, UPDATE_DOWNLOAD_FILE, UPDATE_EDIT_BILLING_STS, UPDATE_FORGOT_PASSWORD,
  UPDATE_GEN_PRINT_URL,
  UPDATE_GET_ALL_TXN,
  UPDATE_GET_FILE_URL, UPDATE_GET_TXN_REPORT, UPDATE_LOGIN_TOKEN, UPDATE_MODIFY_TXN, UPDATE_PASS_KEY_DATA, UPDATE_POST_REFFILE_DATA, UPDATE_POST_TXN, UPDATE_PRE_PRINT_ZIP, UPDATE_PRINTER_DASHBOARD, UPDATE_PRINT_CARD_STATUS, UPDATE_PRINT_READY, UPDATE_PRINT_READY_DATA, UPDATE_PROFILE, UPDATE_REFFILE_LIST, UPDATE_REFSTS_LIST, UPDATE_REGISTER_DATA,
  UPDATE_STATE_LIST, UPDATE_SUB_FOL_CNT_LIST, UPDATE_SUPERADMIN_DASHBOARD, UPDATE_SUPERADMIN_TREND_STS, UPDATE_TALUKA_ADM_LIST, UPDATE_TALUKA_CNT_LIST, UPDATE_TALUKA_LIST, UPDATE_UPLOAD_IMAGE_DATA, UPDATE_USER_PROFILE_PUT_DATA, UPDATE_USR_EXIST, UPLOAD_IMAGE_DATA,
  USER_PROFILE_PUT_DATA,
} from './constants';
import { ContactUsOptions, ContactUsResults } from '../../services/contact-us/contact-us.service.types';
import { ErrorAction } from '../../services/types';
import { GenTokenOptions, GenTokenResults } from '../../services/gen-token/gen-token.service.types';
import { ProfileOptions, ProfileResults } from '../../services/get-profile/get-profile.service.types';
import { ForgotPasswordOptions, ForgotPasswordResults } from '../../services/forgot-password/forgot-password.service.types';
import { FileURLOptions, FileURLResults } from '../../services/get-file-url/get-file-url.service.types';
import { ListCityOptions, ListCityResults } from '../../services/meta-data/list-city-loc/list-city.service.types';
import { UserProfilePutOptions, UserProfilePutResults } from '../../services/update-profile/update-profile.service.types';
import { PassKeyPutOptions, PassKeyPutResults } from '../../services/update-passkey/update-passkey.service.types';
import { UploadImageOptions, UploadImageResults } from '../../services/upload-image/upload-image.service.types';
import { RegisterOptions, RegisterResults } from '../../services/register/register.service.types';
import { ListStateOptions, ListStateResults } from '../../services/meta-data/list-state/list-state.service.types';
import { ListDistOptions, ListDistResults } from '../../services/meta-data/list-district/list-dist.service.types';
import { CreateAvatarOptions, CreateAvatarResults } from '../../services/upload-avatar/create-avatar.service.types';
import { ListAgntOptions, ListAgntResults } from '../../services/sadmin/agents/agent-list/list-agent.service.types';
import { AgntStatusPutOptions, AgntStatusPutResults } from '../../services/sadmin/agents/update-status/update-status.service.types';
import { CreateAWSFilesOptions, CreateAWSFilesResults } from '../../services/upload-AWS-files/create-awsfiles.service.types';
import { PostRefFileOptions, PostRefFileResults } from '../../services/add-new-ref-file/post-reffile.service.types';
import { ListRefFileOptions, ListRefFileResults } from '../../services/ref-file-list/list-reffile.service.types';
import { CheckRefFileOptions, CheckRefFileResults } from '../../services/check-ref-file/check-reffile.service.types';
import { PutPrintReadyOptions, PutPrintReadyResults } from '../../services/add-print-ready/print-ready.service.types';
import { RefStatusOptions, RefStatusResults } from '../../services/req-status-ref/ref-sts.service.types';
import { PrintLinkOptions, PrintLinkResults } from '../../services/sadmin/admin/generate-print-link/generate-print-link.service.types';
import { CardStatusOptions, CardStatusResults } from '../../services/sadmin/admin/update-card-status/update-card-status.service.types';
import { PrintReadyOptions, PrintReadyResults } from '../../services/sadmin/admin/update-print-ready/update-print-ready.service.types';
import { PrintStatusOptions, PrintStatusResults } from '../../services/update-print-status/update-print-status.service.types';
import { ListTalukaOptions, ListTalukaResults } from '../../services/meta-data/list-taluka/list-taluka.service.types';
import { ListTalukaAdmOptions, ListTalukaAdmResults } from '../../services/meta-data/list-taluka-adm/list-taluka-adm.service.types';
import { GetAWSSubFolderOptions, GetAWSSubFolderResults } from '../../services/get-aws-sub-folders/get-subfolders.service.types';
import { ListTalukaCountOptions, ListTalukaCountResults } from '../../services/get-s3-loc-count/list-taluka-count.service.types';
import { DelFileURLOptions, DelFileURLResults } from '../../services/del-file-url/del-file-url.service.types';
import { PrePrintZipOptions, PrePrintZipResults } from '../../services/get-pre-print-zip/get-pre-print.service.types';
import { ListSubFolCountOptions, ListSubFolCountResults } from '../../services/get-s3-subFol-count/list-subFol-count.service.types';
import { CheckUserOptions, CheckUserResults } from '../../services/check_user_exist/check-user.service.types';
import { DownloadFileOptions, DownloadFileResults } from '../../services/get-download-file-link/get-download-file.service.types';
import { CardMassStatusOptions, CardMassStatusResults } from '../../services/update-mass-card-status/update-mass-status.service.types';
import { CardsStateWiseOptions, CardsStateWiseResults } from '../../services/reports-all/get-cards-state-wise/card-state-wise.service.types';
import { CardDashboardOptions, CardDashboardResults } from '../../services/reports-all/get-cards-dashboard/card-dashboard.service.types';
import { CardTrendStatusOptions, CardTrendStatusResults } from '../../services/reports-all/get-trends-status/card-trend.service.types';
import { ListPrinterIdOptions, ListPrinterIdResults } from '../../services/meta-data/list-printer-id/list-printer-id.service.types';
import { PrinterDashboardOptions, PrinterDashboardResults } from '../../services/reports-all/get-printer-dashboard/printer-dashboard.service.types';
import { PrintIdOptions, PrintIdResults } from '../../services/update-printer-id/update-print-id.service.types';
import { SuperAdminDashboardOptions, SuperAdminDashboardResults } from '../../services/reports-all/superadmin-dashboard/superadmin-dashboard/superadmin-dashboard.service.types';
import { SuperAdminTrendStsOptions, SuperAdminTrendStsResults } from '../../services/reports-all/superadmin-dashboard/superadmin-trend-status/superadmin-trend-sts.service.types';
import { BillingOptions, BillingResults } from '../../services/reports-all/superadmin-dashboard/superadmin-billing/billing.service.types';
import { EditBillingStatusOptions, EditBillingStatusResults } from '../../services/reports-all/superadmin-dashboard/superadmin-edit-billing-status/update-billing-status.service.types';
import { PostTxnOptions, PostTxnResults } from '../../services/sadmin/accounts/add-transactions/post-txn.service.types';
import { GetAllTxnOptions, GetAllTxnResults } from '../../services/sadmin/accounts/get-all-transactions/get-all-txn.service.types';
import { UpdateTxnOptions, UpdateTxnResults } from '../../services/sadmin/accounts/update-transactions/update-txn.service.types';
import { GetTxnReportOptions, GetTxnReportResults } from '../../services/sadmin/accounts/txn-report/get-txn-report.service.types';

export const apiError = createAction(API_ERROR)<ErrorAction>();
export const showData = createAction(SHOW_DATA)<{}>();
export const updateData = createAction(UPDATE_DATA)<string>();
export const contactUsPostReq = createAction(CONTACT_US_DATA)<ContactUsOptions>();
export const updateContactUsPostReq = createAction(UPDATE_CONTACT_US_DATA)<ContactUsResults>();
export const clearContactUsPostReq = createAction(CLEAR_CONTACT_US_DATA)();
export const registerReq = createAction(REGISTER_DATA)<RegisterOptions>();
export const updateRegisterPostReq = createAction(UPDATE_REGISTER_DATA)<RegisterResults>();
export const clearRegisterPostReq = createAction(CLEAR_REGISTER_DATA)();
export const getLoginToken = createAction(GET_LOGIN_TOKEN)<GenTokenOptions>();
export const updateToken = createAction(UPDATE_LOGIN_TOKEN)<GenTokenResults>();
export const getProfile = createAction(GET_PROFILE)<ProfileOptions>();
export const updateProfile = createAction(UPDATE_PROFILE)<ProfileResults>();
export const clearProfileReq = createAction(CLEAR_PROFILE)();
export const getForgotPassword = createAction(GET_FORGOT_PASSWORD)<ForgotPasswordOptions>();
export const updateForgotPassword = createAction(UPDATE_FORGOT_PASSWORD)<ForgotPasswordResults>();
export const getFileURLReq = createAction(GET_FILE_URL)<FileURLOptions>();
export const updateFileURLReq = createAction(UPDATE_GET_FILE_URL)<FileURLResults>();
export const getCityListReq = createAction(GET_CITY_LIST)<ListCityOptions>();
export const updateCityListReq = createAction(UPDATE_CITY_LIST)<ListCityResults>();
export const userProfilePutReq = createAction(USER_PROFILE_PUT_DATA)<UserProfilePutOptions>();
export const updateUserProfilePutReq = createAction(UPDATE_USER_PROFILE_PUT_DATA)<UserProfilePutResults>();
export const clearUserProfilePutReq = createAction(CLEAR_USER_PROFILE_PUT_DATA)();
export const passKeyReq = createAction(PASS_KEY_DATA)<PassKeyPutOptions>();
export const updatePassKeyReq = createAction(UPDATE_PASS_KEY_DATA)<PassKeyPutResults>();
export const clearPassKeyReq = createAction(CLEAR_PASS_KEY_DATA)();
export const uploadImageReq = createAction(UPLOAD_IMAGE_DATA)<UploadImageOptions>();
export const updateUploadImageReq = createAction(UPDATE_UPLOAD_IMAGE_DATA)<UploadImageResults>();
export const clearUploadImageReq = createAction(CLEAR_UPLOAD_IMAGE_DATA)();
export const getStateListReq = createAction(GET_STATE_LIST)<ListStateOptions>();
export const updateStateListReq = createAction(UPDATE_STATE_LIST)<ListStateResults>();
export const clearStateListReq = createAction(CLEAR_STATE_LIST)();
export const getDistListReq = createAction(GET_DIST_LIST)<ListDistOptions>();
export const updateDistListReq = createAction(UPDATE_DIST_LIST)<ListDistResults>();
export const clearDistListReq = createAction(CLEAR_DIST_LIST)();
export const getAvatarPublish = createAction(GET_AVATAR_PUBLISH)<CreateAvatarOptions>();
export const updateAvatarPublish = createAction(UPDATE_AVATAR_PUBLISH)<CreateAvatarResults>();
export const agntUpdtReq = createAction(AGNT_DATA)<AgntStatusPutOptions>();
export const updateAgntReq = createAction(UPDATE_AGNT_DATA)<AgntStatusPutResults>();
export const clearAgntReq = createAction(CLEAR_AGNT_DATA)();
export const getAgntListReq = createAction(GET_AGNT_LIST)<ListAgntOptions>();
export const updateAgntListReq = createAction(UPDATE_AGNT_LIST)<ListAgntResults>();
export const clearAgntListReq = createAction(CLEAR_AGNT_LIST_DATA)();
export const getAWSFilesUpload = createAction(GET_AWS_FILES_UPLOAD)<CreateAWSFilesOptions>();
export const updateAWSFilesUpload = createAction(UPDATE_AWS_FILES_UPLOAD)<CreateAWSFilesResults>();
export const clearAWSFilesUpload = createAction(CLEAR_AWS_FILES_UPLOAD)();
export const postRefFileReq = createAction(POST_REFFILE_DATA)<PostRefFileOptions>();
export const updatePostRefFileReq = createAction(UPDATE_POST_REFFILE_DATA)<PostRefFileResults>();
export const clearPostRefFileReq = createAction(CLEAR_POST_REFFILE_DATA)();
export const getRefFileListReq = createAction(GET_REFFILE_LIST)<ListRefFileOptions>();
export const updateRefFileListReq = createAction(UPDATE_REFFILE_LIST)<ListRefFileResults>();
export const clearRefFileListReq = createAction(CLEAR_REFFILE_LIST)();
export const getCheckRefFileReq = createAction(GET_CHECK_REF_FILE)<CheckRefFileOptions>();
export const updateCheckRefFileReq = createAction(UPDATE_CHECK_REF_FILE)<CheckRefFileResults>();
export const clearCheckRefFileReq = createAction(CLEAR_CHECK_REF_FILE)();
export const putPrintReadyReq = createAction(PUT_PRINT_READY_DATA)<PutPrintReadyOptions>();
export const updatePrintReadyReq = createAction(UPDATE_PRINT_READY_DATA)<PutPrintReadyResults>();
export const clearPrintReadyReq = createAction(CLEAR_PRINT_READY_DATA)();
export const getRefStatusReq = createAction(GET_REFSTS_LIST)<RefStatusOptions>();
export const updateRefStatusReq = createAction(UPDATE_REFSTS_LIST)<RefStatusResults>();
export const clearRefStatusReq = createAction(CLEAR_REFSTS_LIST)();
export const genPrintUrlReq = createAction(GEN_PRINT_URL)<PrintLinkOptions>();
export const updateGenPrintUrlReq = createAction(UPDATE_GEN_PRINT_URL)<PrintLinkResults>();
export const clearGenPrintUrlReq = createAction(CLEAR_GEN_PRINT_URL)();
export const printReadyReq = createAction(PRINT_READY)<PrintReadyOptions>();
export const updateCardPrintReadyReq = createAction(UPDATE_PRINT_READY)<PrintReadyResults>();
export const clearCardPrintReadyReq = createAction(CLEAR_PRINT_READY)();
export const cardStatusReq = createAction(CARD_STATUS)<CardStatusOptions>();
export const updateCardStatusReq = createAction(UPDATE_CARD_STATUS)<CardStatusResults>();
export const clearCardStatusReq = createAction(CLEAR_CARD_STATUS)();
export const postPrintStatusReq = createAction(PRINT_CARD_STATUS)<PrintStatusOptions>();
export const updatePrintStatusReq = createAction(UPDATE_PRINT_CARD_STATUS)<PrintStatusResults>();
export const clearPrintStatusReq = createAction(CLEAR_PRINT_CARD_STATUS)();
export const getTalukaListReq = createAction(GET_TALUKA_LIST)<ListTalukaOptions>();
export const updateTalukaListReq = createAction(UPDATE_TALUKA_LIST)<ListTalukaResults>();
export const clearTalukaListReq = createAction(CLEAR_TALUKA_LIST)();
export const getTalukaAdmListReq = createAction(GET_TALUKA_ADM_LIST)<ListTalukaAdmOptions>();
export const updateTalukaAdmListReq = createAction(UPDATE_TALUKA_ADM_LIST)<ListTalukaAdmResults>();
export const clearTalukaAdmListReq = createAction(CLEAR_TALUKA_ADM_LIST)();
export const getAWSFolderList = createAction(GET_AWS_FOLDER_LIST)<GetAWSSubFolderOptions>();
export const updateAWSFolderList = createAction(UPDATE_AWS_FOLDER_LIST)<GetAWSSubFolderResults>();
export const clearAWSFolderList = createAction(CLEAR_AWS_FOLDER_LIST)();
export const getTalukaCountListReq = createAction(GET_TALUKA_CNT_LIST)<ListTalukaCountOptions>();
export const updateTalukaCountListReq = createAction(UPDATE_TALUKA_CNT_LIST)<ListTalukaCountResults>();
export const clearTalukaCountListReq = createAction(CLEAR_TALUKA_CNT_LIST)();
export const delFileURLReq = createAction(DEL_FILE_URL)<DelFileURLOptions>();
export const updateDelFileURLReq = createAction(UPDATE_DEL_FILE_URL)<DelFileURLResults>();
export const clearDelFileURLReq = createAction(CLEAR_DEL_FILE_URL)();
export const getSubFolCountListReq = createAction(GET_SUB_FOL_CNT_LIST)<ListSubFolCountOptions>();
export const updateSubFolCountListReq = createAction(UPDATE_SUB_FOL_CNT_LIST)<ListSubFolCountResults>();
export const clearSubFolCountListReq = createAction(CLEAR_SUB_FOL_CNT_LIST)();
export const getPrePrintZipReq = createAction(GET_PRE_PRINT_ZIP)<PrePrintZipOptions>();
export const updatePrePrintZipReq = createAction(UPDATE_PRE_PRINT_ZIP)<PrePrintZipResults>();
export const clearPrePrintZipReq = createAction(CLEAR_PRE_PRINT_ZIP)();
export const checkUserExistReq = createAction(CHK_USR_EXIST)<CheckUserOptions>();
export const updateUserExistReq = createAction(UPDATE_USR_EXIST)<CheckUserResults>();
export const clearUserExistReq = createAction(CLEAR_USR_EXIST)();
export const getDownloadFileReq = createAction(GET_DOWNLOAD_FILE)<DownloadFileOptions>();
export const updateDownloadFileReq = createAction(UPDATE_DOWNLOAD_FILE)<DownloadFileResults>();
export const clearDownloadFileReq = createAction(CLEAR_DOWNLOAD_FILE)();
export const getCardMassStatus = createAction(CARD_MASS_STATUS)<CardMassStatusOptions>();
export const updateCardMassStatus = createAction(UPDATE_CARD_MASS_STATUS)<CardMassStatusResults>();
export const clearCardMassStatus = createAction(CLEAR_CARD_MASS_STATUS)();
export const getCardCountStateWise = createAction(CARD_COUNT_STATE_WISE)<CardsStateWiseOptions>();
export const updateCardCountStateWise = createAction(UPDATE_CARD_COUNT_STATE_WISE)<CardsStateWiseResults>();
export const clearCardCountStateWise = createAction(CLEAR_CARD_COUNT_STATE_WISE)();
export const getCardDashboard = createAction(CARD_DASHBOARD)<CardDashboardOptions>();
export const updateCardDashboard = createAction(UPDATE_CARD_DASHBOARD)<CardDashboardResults>();
export const clearCardDashboard = createAction(CLEAR_CARD_DASHBOARD)();
export const getCardTrendStatus = createAction(CARD_TREND_STATUS)<CardTrendStatusOptions>();
export const updateCardTrendStatus = createAction(UPDATE_CARD_TREND_STATUS)<CardTrendStatusResults>();
export const clearCardTrendStatus = createAction(CLEAR_CARD_TREND_STATUS)();
export const getListPrinterId = createAction(ALL_PRINTER_ID)<ListPrinterIdOptions>();
export const updateListPrinterId = createAction(UPDATE_ALL_PRINTER_ID)<ListPrinterIdResults>();
export const clearListPrinterId = createAction(CLEAR_ALL_PRINTER_ID)();
export const getPrintId = createAction(CREATE_PRINT_ID)<PrintIdOptions>();
export const updatePrintId = createAction(UPDATE_CREATE_PRINT_ID)<PrintIdResults>();
export const clearPrintId = createAction(CLEAR_CREATE_PRINT_ID)();
export const getPrinterDashboard = createAction(PRINTER_DASHBOARD)<PrinterDashboardOptions>();
export const updatePrinterDashboard = createAction(UPDATE_PRINTER_DASHBOARD)<PrinterDashboardResults>();
export const clearPrinterDashboard = createAction(CLEAR_PRINTER_DASHBOARD)();
export const getSuperAdminDashboard = createAction(SUPERADMIN_DASHBOARD)<SuperAdminDashboardOptions>();
export const updateSuperAdminDashboard = createAction(UPDATE_SUPERADMIN_DASHBOARD)<SuperAdminDashboardResults>();
export const clearSuperAdminDashboard = createAction(CLEAR_SUPERADMIN_DASHBOARD)();
export const getSuperAdminTrendSts = createAction(SUPERADMIN_TREND_STS)<SuperAdminTrendStsOptions>();
export const updateSuperAdminTrendSts = createAction(UPDATE_SUPERADMIN_TREND_STS)<SuperAdminTrendStsResults>();
export const clearSuperAdminTrendSts = createAction(CLEAR_SUPERADMIN_TREND_STS)();
export const getBilling = createAction(BILLING)<BillingOptions>();
export const updateBilling = createAction(UPDATE_BILLING)<BillingResults>();
export const clearBilling = createAction(CLEAR_BILLING)();
export const getEditBillingStatus = createAction(EDIT_BILLING_STS)<EditBillingStatusOptions>();
export const updateEditBillingStatus = createAction(UPDATE_EDIT_BILLING_STS)<EditBillingStatusResults>();
export const clearEditBillingStatus = createAction(CLEAR_EDIT_BILLING_STS)();
export const postTxn = createAction(POST_TXN)<PostTxnOptions>();
export const updatePostTxn = createAction(UPDATE_POST_TXN)<PostTxnResults>();
export const clearPostTxn = createAction(CLEAR_POST_TXN)();
export const modifyTxn = createAction(MODIFY_TXN)<UpdateTxnOptions>();
export const updateModifyTxn = createAction(UPDATE_MODIFY_TXN)<UpdateTxnResults>();
export const clearModifyTxn = createAction(CLEAR_MODIFY_TXN)();
export const getAllTxn = createAction(GET_ALL_TXN)<GetAllTxnOptions>();
export const updateGetAllTxn = createAction(UPDATE_GET_ALL_TXN)<GetAllTxnResults>();
export const clearGetAllTxn = createAction(CLEAR_GET_ALL_TXN)();
export const getTxnReport = createAction(GET_TXN_REPORT)<GetTxnReportOptions>();
export const updateGetTxnReport = createAction(UPDATE_GET_TXN_REPORT)<GetTxnReportResults>();
export const clearGetTxnReport = createAction(CLEAR_GET_TXN_REPORT)();

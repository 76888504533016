import {
  faBoxesStacked, faFileInvoiceDollar, faPrint, faUsersBetweenLines,
} from '@fortawesome/free-solid-svg-icons';
// faSuitcase
import {
  Menu,
  MenuItem,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './super-admin.scss';
import { Nav } from 'reactstrap';
import { SideDrawerProps } from './side-drawer.type';

export const SAdminSideDrawer: React.FC<SideDrawerProps> = ({
  menuCollapse, setClick,
}) => {
  const location = useLocation();
  const path = location.pathname.split('/');
  const [menuItems, setMenuItems] = useState({
    dashboard: !!path[2].match('dashboard'),
    agents: !!path[2].match('agent-status'),
    print: !!path[2].match('print'),
    accounts: !!path[2].match('accounts'),
  });
  const [defaultState, setDefaultState] = useState(true);

  React.useEffect(() => {
    if (defaultState) {
      if (path[3] === '') {
        setMenuItems({
          dashboard: true,
          agents: false,
          print: false,
          accounts: false,
        });
      }
      if (path[3] === 'dashboard') {
        setMenuItems({
          dashboard: true,
          agents: false,
          print: false,
          accounts: false,
        });
      }
      if (path[3] === 'print') {
        setMenuItems({
          dashboard: true,
          agents: false,
          print: true,
          accounts: false,
        });
      }
      if (path[3] === 'agent-status') {
        setMenuItems({
          dashboard: false,
          agents: true,
          print: false,
          accounts: false,
        });
      }
      if (path[3] === 'accounts') {
        setMenuItems({
          dashboard: false,
          agents: false,
          print: false,
          accounts: true,
        });
      }
      setDefaultState(true);
    }
  }, [defaultState, path[3]]);

  const handleOnClickMenuItem = (item: string) => {
    if (item === 'dashboard') {
      setClick(!menuCollapse);
      setMenuItems({
        dashboard: true,
        agents: false,
        print: false,
        accounts: false,
      });
    } else if (item === 'print') {
      setClick(!menuCollapse);
      setMenuItems({
        dashboard: false,
        agents: false,
        print: true,
        accounts: false,
      });
    } else if (item === 'agent-status') {
      setClick(!menuCollapse);
      setMenuItems({
        dashboard: false,
        agents: true,
        print: false,
        accounts: false,
      });
    } else if (item === 'accounts') {
      setClick(!menuCollapse);
      setMenuItems({
        dashboard: false,
        agents: false,
        print: false,
        accounts: true,
      });
    }
  };

  return (
    <div>
      <Nav
        collapsed={menuCollapse ? 'true' : 'false'}
        className={!menuCollapse ? 'pro-sidebar-sadmin' : 'pro-sidebar-sadmin-hide'}
      >
        <div className="pt-3 pro-sidebar-sadmin-bg">
          <Menu iconShape="square">
            <MenuItem active={menuItems.dashboard} className="menu-item-sadm" onClick={() => handleOnClickMenuItem('dashboard')}>
              <Link to="dashboard">
                <span className="menu-icon-sadm">
                  <FontAwesomeIcon icon={faBoxesStacked} />
                </span>
                Dashboard
              </Link>
            </MenuItem>
            <MenuItem active={menuItems.print} className="menu-item-sadm" onClick={() => handleOnClickMenuItem('print')}>
              <Link to="print-ready">
                <span className="menu-icon-sadm">
                  <FontAwesomeIcon icon={faPrint} />
                </span>
                Print Ready
              </Link>
            </MenuItem>
            <MenuItem active={menuItems.agents} className="menu-item-sadm" onClick={() => handleOnClickMenuItem('agent-status')}>
              <Link to="agent-status">
                <span className="menu-icon-sadm">
                  <FontAwesomeIcon icon={faUsersBetweenLines} />
                </span>
                Users
              </Link>
            </MenuItem>
            <MenuItem active={menuItems.accounts} className="menu-item-sadm" onClick={() => handleOnClickMenuItem('accounts')}>
              <Link to="accounts">
                <span className="menu-icon-sadm">
                  <FontAwesomeIcon icon={faFileInvoiceDollar} />
                </span>
                Accounts
              </Link>
            </MenuItem>
            <div className="pt-5" />
          </Menu>
        </div>
      </Nav>
    </div>
  );
};

export default SAdminSideDrawer;

import React, { useState } from 'react';
import './stopr.scss';
import {
  Button,
  Col, Input, Row,
} from 'reactstrap';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { successAlert, errorAlert } from '../../../utils/alert';
import LoaderData from '../../../utils/loader';
import { PrintRefUploadPdf } from './upload-cards/print-upload-pdf';
import { getTokenFromLocalStorage, getUserTpSessionStorage } from '../../../utils/service/localstorage-service';
import { clearCardCountStateWise, getCardCountStateWise } from '../../../store/yoCard/actions';
import { APIHeader } from '../../../utils/constants';
import { CardStResult } from '../../../services/reports-all/get-cards-state-wise/card-state-wise.types';

export const StateOperatorDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const userTp = getUserTpSessionStorage();
  const tokenData = getTokenFromLocalStorage();
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg] = useState('');
  const [loaderProfile] = useState(false);
  const today = new Date().toISOString().split('T')[0];
  const getCardsStateWiseResponse = useSelector((state: RootState) => state.yoCard.getCardCountStateWise);
  const [inputDate, setInputDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [cardStatus, setCardStatus] = useState<CardStResult>({
    tCnt: 0,
    data: [
      {
        cnt: 0,
        st: '',
        currentSts: '',
      },
    ],
  });
  const handleFieldChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputDate(e.target.value);
  };

  const handleSearchCardsStsByDate = () => {
    dispatch(getCardCountStateWise({
      userType: userTp,
      requestType: APIHeader.REQ_REPORT_DAY_WISE_CARDS,
      crdDt: inputDate,
      token: tokenData,
    }));
  };

  React.useEffect(() => {
    if (userTp === 'CRDOPT') {
      Promise.resolve(
        dispatch(getCardCountStateWise({
          userType: userTp,
          requestType: APIHeader.REQ_REPORT_DAY_WISE_CARDS,
          crdDt: inputDate,
          token: tokenData,
        })),
      ).then(() => {
        dispatch(clearCardCountStateWise());
      });
    }
  }, [userTp]);

  React.useEffect(() => {
    if (getCardsStateWiseResponse.isError && getCardsStateWiseResponse.message !== '') {
      setCardStatus({
        tCnt: 0,
        data: [
          {
            cnt: 0,
            st: '',
            currentSts: '',
          },
        ],
      });
      dispatch(clearCardCountStateWise());
    }
  }, [getCardsStateWiseResponse.isError, getCardsStateWiseResponse.message]);

  React.useEffect(() => {
    if (!getCardsStateWiseResponse.isError && getCardsStateWiseResponse.message === 'executed') {
      setCardStatus(getCardsStateWiseResponse.data);
      dispatch(clearCardCountStateWise());
    }
  }, [getCardsStateWiseResponse.isError, getCardsStateWiseResponse.message]);

  return (
    <div className="border-tabs mr">
      { loaderProfile ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="mb-2">
        <span className="yocard-header-with-bg">
          Quick View - Dashboard
        </span>
      </div>
      <Row className="pt-3">
        <Col lg="9" xs="12">
          <PrintRefUploadPdf />
        </Col>
        <Col lg="3" xs="12" className="pt-1">
          <div className="align-center">
            <span className="yocard-label-bg">My Card Status</span>
          </div>
          <div className="print-sadmin-graph-card-box my-2">
            <div className="pt-1 align-start">
              <Input
                max={today}
                value={inputDate}
                type="date"
                name="refDt"
                onChange={handleFieldChangeDate}
                className="yocard-input-ref"
              />
              <Button onClick={handleSearchCardsStsByDate} disabled={inputDate === ''} className="yocard-button-oval mx-2 my-1">Search</Button>
            </div>
            <div className="pt-3">
              <div className="align-start">
                <span className="text-lite-grey">Status: {cardStatus.data && cardStatus.data.length > 0 ? cardStatus.data[0].currentSts : 'NA'}</span>
              </div>
              <div className="pt-4">
                <div className="align-center pt-2">
                  <span className="stopr-card-status-box">Card Uploads -  {inputDate === new Date().toISOString().split('T')[0] ? 'Today' : inputDate}</span>
                </div>
                <div className="align-center pt-2">
                  <div className="stopr-circle_container">
                    <div className="stopr-circle_main">
                      <div className="stopr-circle_text_container">
                        <div className="stopr-circle_text">
                          {cardStatus.data && cardStatus.data.length > 0 ? cardStatus.data[0].cnt : 0}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-5">
                <div className="align-center pt-2">
                  <span className="stopr-card-status-box">Total Card Uploads</span>
                </div>
                <div className="align-center pt-2">
                  <div className="stopr-circle_container">
                    <div className="stopr-circle_total">
                      <div className="stopr-circle_text_container">
                        <div className="stopr-circle_text">
                          {cardStatus.tCnt}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="mb-3" />
    </div>
  );
};

export default StateOperatorDashboard;

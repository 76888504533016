import { FileURLResponse, FileURLResults } from './get-file-url.service.types';

export const FileURLTransform: (response: FileURLResponse) => FileURLResults = (fileURLResults) => {
  const { data, is_error, message } = fileURLResults;
  const result :FileURLResults = {
    url: '',
    isError: false,
    message: '',
  };
  if (is_error) {
    result.message = message;
    result.isError = is_error;
  } else {
    result.url = data ?? '';
    result.isError = is_error;
    result.message = '';
  }
  return result;
};

import React from 'react';
import { Line } from 'react-chartjs-2';
import { ChartOptions, ChartType } from 'chart.js';

interface StatusEntry {
  cSts: string;
  tCnt: number;
  dist: string;
  stsVal: string;
}

interface StatusData {
  dt: string;
  statusData: StatusEntry[];
}

interface ChartComponentProps {
  data: StatusData[];
}

const LineChartComponent: React.FC<ChartComponentProps> = ({ data }) => {
  const allDates: string[] = Array.from(
    new Set(data.flatMap((status) => status.statusData.map((entry) => entry.dist))),
  );

  const filteredData: StatusData[] = data.filter((status) => status.statusData.length > 0);

  //   const getRandomColor = (): string => {
  //     const letters = '0123456789ABCDEF';
  //     let color = '#';
  //     for (let i = 0; i < 6; i += 1) {
  //       color += letters[Math.floor(Math.random() * 16)];
  //     }
  //     return color;
  //   };

  const getRandomDarkColor = (): string => {
    const getRandomHex = () => Math.floor(Math.random() * 128).toString(16).padStart(2, '0');
    // Generate dark colors in shades of red, green, and purple
    const red = getRandomHex();
    const green = getRandomHex();
    const purple = getRandomHex();

    return `#${red}${green}${purple}`;
  };

  const chartData = {
    labels: allDates,
    datasets: filteredData.map((status) => ({
      label: status.dt,
      data: allDates.map((stDist) => status.statusData.find((entry) => entry.dist === stDist)?.tCnt || 0),
      fill: false,
      borderColor: getRandomDarkColor(),
      borderWidth: 2,
    })),
  };

  // Chart options
  const chartOptions: ChartOptions<ChartType> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        // type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'YYYY-MM-DD',
          },
        },
        title: {
          display: true,
          text: 'District',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Count',
        },
      },
    },
  };

  return (
    <div>
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};
export default LineChartComponent;

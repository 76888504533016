import { ListRefFileResponse, ListRefFileResults } from './list-reffile.service.types';

export const ListRefFileTransform: (response: ListRefFileResponse) => ListRefFileResults = (listRefFileResults) => {
  const { data, is_error, message } = listRefFileResults;
  const result: ListRefFileResults = {
    tCnt: 0,
    fCnt: 0,
    refData: [],
    lastEvalKey: {
      CardId: '',
      CardTp: '',
      cSts: '',
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.tCnt = data.tCnt;
    result.fCnt = data.fCnt;
    result.lastEvalKey = data.lastEvalKey;
    result.refData = data.refData;
    result.message = 'executed';
  }
  return result;
};

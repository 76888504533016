import { GetAllTxnResponse, GetAllTxnResults } from './get-all-txn.service.types';

export const GetAllTxnTransform: (response: GetAllTxnResponse) => GetAllTxnResults = (getAllTxnResults) => {
  const { data, is_error, message } = getAllTxnResults;
  const result: GetAllTxnResults = {
    data: [{
      AccId: '',
      AccTp: '',
      amnt: '',
      mPayVal: '',
      accTpVal: '',
      dbtTpVal: '',
      accTp: '',
      txnUser: '',
      mPay: '',
      comments: '',
      dbtTp: '',
      prntCd: '',
      prntDt: '',
      cardDet: {
        tCnt: '',
        stData: {
          distNme: '',
          stCd: '',
          stNme: '',
        },
      },
    }],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data ?? [];
    result.message = 'executed';
  }
  return result;
};

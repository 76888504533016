import { Spinner } from 'reactstrap';
import '../styles/scss/index.scss';

export const LoaderData: React.FC = () => (
  <div className="loader-campusxcel">
    <Spinner style={{ backgroundColor: 'transparent' }} />
    <span className="mx-2">Please wait....</span>
  </div>
);

export default LoaderData;

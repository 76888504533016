import React from 'react';
import { RootState } from 'typesafe-actions';
import {
  Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner, Tooltip, UncontrolledTooltip,
} from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import '../sadmin.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import {
  faBuildingColumns, faCircleInfo, faFileInvoice, faHandHolding, faHandHoldingDollar, faIndianRupee, faMoneyBill1Wave, faMoneyCheck, faSearch, faWallet,
} from '@fortawesome/free-solid-svg-icons';
import {
  clearBilling,
  clearGetAllTxn, getAllTxn, getBilling, modifyTxn, postTxn,
} from '../../../../store/yoCard/actions';
import { getTokenFromLocalStorage, getUserTpSessionStorage } from '../../../../utils/service/localstorage-service';
import LoaderData from '../../../../utils/loader';
import { errorAlert, successAlert } from '../../../../utils/alert';
import { APIHeader } from '../../../../utils/constants';
import { TxnsResult } from '../../../../services/sadmin/accounts/get-all-transactions/get-all-txn.types';
import { UpdateTxnInput } from '../../../../services/sadmin/accounts/update-transactions/update-txn.types';
import { PostTxnInput } from '../../../../services/sadmin/accounts/add-transactions/post-txn.types';
import { BillingDetails } from '../../../../services/reports-all/superadmin-dashboard/superadmin-billing/billing.types';

export const TransactionTable: React.FC = () => {
  const dispatch = useDispatch();
  const userTp = getUserTpSessionStorage();
  const tokenData = getTokenFromLocalStorage();
  const [loaderAdmin, setLoaderAdmin] = React.useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [alertAPIMsg, setAlertAPIMsg] = React.useState('');
  const [txnLoader, setTxnLoader] = React.useState(false);
  const [crdCostOpen, setCrdCostOpen] = React.useState<Record<string, boolean>>({});
  const [openModalTxn, setOpenModalTxn] = React.useState<Record<string, boolean>>({});
  const [addTxnModal, setAddTxnModal] = React.useState(false);
  const addNewTxnStatusResponse = useSelector((state: RootState) => state.yoCard.createTxn);
  const updateTxnStatusResponse = useSelector((state: RootState) => state.yoCard.updateTxn);
  const getTxnAllResponse = useSelector((state: RootState) => state.yoCard.getAllTxn);
  const [txnData, setTxnData] = React.useState<TxnsResult[]>([]);
  const today = new Date().toISOString().split('T')[0];
  const currentDate = new Date();
  const billingResponse = useSelector((state: RootState) => state.yoCard.billingStatus);
  currentDate.setDate(currentDate.getDate() - 10);
  const sevenDaysAgo = currentDate.toISOString().split('T')[0];
  const [startDate, setStartDate] = React.useState<string>(sevenDaysAgo);
  const [endDate, setEndDate] = React.useState<string>(new Date().toISOString().split('T')[0]);
  const [txnAddData, setTxnAddData] = React.useState<PostTxnInput>({
    amnt: '',
    txnDate: '',
    accTp: '',
    txnUser: '',
    mPay: '',
    comments: '',
    prntDt: '',
    prntCd: '',
    dbtTp: 'CLNT',
  });
  const [txnUpdateData, setTxnUpdateData] = React.useState<UpdateTxnInput>({
    amnt: '',
    txnDate: '',
    accTp: '',
    txnUser: '',
    mPay: '',
    comments: '',
    prntDt: '',
    prntCd: '',
    dbtTp: 'CLNT',
  });
  const location = useLocation();
  const path = location.pathname.split('/');
  const [menuItems, setMenuItems] = React.useState('');
  const [billingStatus, setBillingStatus] = React.useState<BillingDetails[]>([{
    PrntId: '',
    stData: {
      distNme: '',
      stCd: '',
      stNme: '',
    },
    PrntTp: '',
    courId: '',
    costPrint: '',
    stsClient: '',
    stsVendor1: '',
    stsPrnt: '',
    gstVendor1: '0',
    stsZameer: '',
    cSts: '',
    updtOn: '',
    prntUsrCd: {
      st: '',
      ph: '',
      UserCd: '',
      lNme: '',
      fNme: '',
      eId: '',
    },
    updtCnt: 0,
    costVendor1: '',
    tCnt: 0,
    costCrd: '0',
    gstCrd: '0',
    gstPrint: '0',
    cardCost: '0',
    gstCardCost: '0',
    tCrdCost: '',
    tPrntCost: '',
    tVendor1Cost: '',
    costWDS: '0',
    gstWDS: '0',
    tWDSCost: '',
    costZameer: '0',
    gstZameer: '0',
    tZameerCost: '',
    tWDSProf: '',
    gstWDSProf: '',
    costWDSProf: '',
    courURL: '',
    sndrNme: '',
    aCmnt: '',
    balAmnt: '',
    miscCst: '',
    courTp: '',
    courTpVal: '',
  }]);

  React.useEffect(() => {
    if (path[2] === 'accounts') {
      setMenuItems('accounts');
    }
  }, [path]);

  const toggleCardCost = (target: string) => {
    setCrdCostOpen((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  const handlePrintDetails = (printDate: string, printCode: string) => {
    setTxnAddData({ ...txnAddData, prntDt: printDate, prntCd: printCode });
  };

  const handleDbtType = (dbt_tp: string) => {
    setTxnAddData({ ...txnAddData, dbtTp: dbt_tp });
  };

  const handleTxnType = (txn_tp: string) => {
    setTxnAddData({ ...txnAddData, accTp: txn_tp });
  };

  const handlePayMode = (pay_mode: string) => {
    setTxnAddData({ ...txnAddData, mPay: pay_mode });
  };

  const handleFieldChangeTxn = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTxnAddData({ ...txnAddData, [e.target.name]: e.target.value });
  };

  const handleTxnUpdateType = (txn_tp: string) => {
    setTxnUpdateData({ ...txnUpdateData, accTp: txn_tp });
    if (txn_tp === 'CRDT') {
      setTxnUpdateData({ ...txnUpdateData, accTp: txn_tp, dbtTp: 'CLNT' });
    }
  };

  const handlePayUpdateMode = (pay_mode: string) => {
    setTxnUpdateData({ ...txnUpdateData, mPay: pay_mode });
  };

  const handleUpdateDbtType = (dbt_tp: string) => {
    setTxnUpdateData({ ...txnUpdateData, dbtTp: dbt_tp });
  };

  const handleFieldUpdateChangeTxn = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTxnUpdateData({ ...txnUpdateData, [e.target.name]: e.target.value });
  };

  const handleStartDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  };

  const txnToggleModal = (target: string, rowData: any) => {
    setOpenModalTxn((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
    setTxnUpdateData({
      ...txnUpdateData,
      amnt: rowData.amnt !== '' ? rowData.amnt : '',
      txnDate: rowData.AccTp !== '' ? rowData.AccTp : '',
      accTp: rowData.accTp !== '' ? rowData.accTp : '',
      dbtTp: rowData.dbtTp !== '' ? rowData.dbtTp : '',
      txnUser: rowData.txnUser !== '' ? rowData.txnUser : '',
      mPay: rowData.mPay !== '' ? rowData.mPay : '',
      comments: rowData.comments !== '' ? rowData.comments : '',
    });
  };

  const updateTxnData = (txnId: string) => {
    setTxnLoader(true);
    dispatch(modifyTxn({
      userType: userTp,
      requestType: APIHeader.REQ_UPDATE_BILLING_ACCOUNTS,
      txnId,
      inputBody: txnUpdateData,
      token: tokenData,
    }));
  };

  React.useEffect(() => {
    if (updateTxnStatusResponse.error && updateTxnStatusResponse.message !== '') {
      setTxnLoader(false);
      setAlertAPIMsg(updateTxnStatusResponse.message);
      setErrorShowAlert(true);
    }
  }, [updateTxnStatusResponse.error, updateTxnStatusResponse.message]);

  React.useEffect(() => {
    if (!updateTxnStatusResponse.error && updateTxnStatusResponse.message !== '') {
      setTxnLoader(false);
      setAlertAPIMsg(updateTxnStatusResponse.message);
      setSuccessShowAlert(true);
    }
  }, [updateTxnStatusResponse.error, updateTxnStatusResponse.message]);

  React.useEffect(() => {
    if (menuItems === 'accounts') {
      setTxnLoader(true);
      dispatch(getAllTxn({
        userType: userTp,
        requestType: APIHeader.REQ_GET_ALL_BILLING_ACCOUNTS,
        sDt: startDate,
        eDt: endDate,
        token: tokenData,
      }));
    }
  }, [menuItems]);

  const handleTxnSearch = () => {
    setTxnLoader(true);
    dispatch(getAllTxn({
      userType: userTp,
      requestType: APIHeader.REQ_GET_ALL_BILLING_ACCOUNTS,
      sDt: startDate,
      eDt: endDate,
      token: tokenData,
    }));
  };

  React.useEffect(() => {
    if (getTxnAllResponse.isError && getTxnAllResponse.message !== '') {
      setTxnLoader(false);
      setErrorShowAlert(true);
      setAlertAPIMsg(getTxnAllResponse.message);
      dispatch(clearGetAllTxn());
    }
  }, [getTxnAllResponse.isError, getTxnAllResponse.message]);

  React.useEffect(() => {
    if (!getTxnAllResponse.isError && getTxnAllResponse.message === 'executed') {
      setTxnLoader(false);
      setTxnData(getTxnAllResponse.data);
      dispatch(clearGetAllTxn());
    }
  }, [getTxnAllResponse.isError, getTxnAllResponse.message]);

  const txnToggleAddTxnModal = () => {
    setAddTxnModal(!addTxnModal);
  };

  const handleAddNewTxn = () => {
    setTxnLoader(true);
    dispatch(postTxn({
      userType: userTp,
      requestType: APIHeader.REQ_ADD_BILLING_ACCOUNTS,
      inputBody: txnAddData,
      token: tokenData,
    }));
  };

  React.useEffect(() => {
    if (addNewTxnStatusResponse.error && addNewTxnStatusResponse.message !== '') {
      setTxnLoader(false);
      setAlertAPIMsg(addNewTxnStatusResponse.message);
      setErrorShowAlert(true);
    }
  }, [addNewTxnStatusResponse.error, addNewTxnStatusResponse.message]);

  React.useEffect(() => {
    if (!addNewTxnStatusResponse.error && addNewTxnStatusResponse.message !== '') {
      setTxnLoader(false);
      setAlertAPIMsg(addNewTxnStatusResponse.message);
      setSuccessShowAlert(true);
    }
  }, [addNewTxnStatusResponse.error, addNewTxnStatusResponse.message]);

  const handleBillingSearch = () => {
    setLoaderAdmin(true);
    Promise.resolve(
      dispatch(getBilling({
        userType: userTp,
        requestType: APIHeader.REQ_GET_CARD_BILLS,
        sDt: startDate,
        eDt: endDate,
        filtrTp: 'STATUS',
        filtrSts: '',
        token: tokenData,
      })),
    ).then(() => {
      dispatch(clearBilling());
    });
  };

  React.useEffect(() => {
    if (billingResponse.isError && billingResponse.message !== '') {
      setLoaderAdmin(false);
      setErrorShowAlert(true);
      setAlertAPIMsg(billingResponse.message);
      dispatch(clearBilling());
    }
  }, [billingResponse.isError, billingResponse.message]);

  React.useEffect(() => {
    if (!billingResponse.isError && billingResponse.message === 'executed') {
      setLoaderAdmin(false);
      setBillingStatus(billingResponse.data);
      dispatch(clearBilling());
    }
  }, [billingResponse.isError, billingResponse.message]);

  return (
    <div>
      {txnLoader ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertAPIMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertAPIMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="print-sadmin-card-box my-2">
        <div className="align-end pt-1">
          <Button id="add-txn" className="button-icon" onClick={() => txnToggleAddTxnModal()}>
            <FontAwesomeIcon className="add-txn-icon" icon={faFileInvoice} />
          </Button>
          <UncontrolledTooltip delay={0} target="add-txn">
            Add New Transactions
          </UncontrolledTooltip>
          <Modal
            isOpen={addTxnModal}
            className="pt-5"
          >
            <ModalHeader toggle={() => txnToggleAddTxnModal()} className="form-card-border-none mx-3 my-2" />
            <div className="mx-2 pr-1 pt-2">
              <h6 className="decline-zip">Add New Transaction</h6>
            </div>
            <ModalBody className="padding-zero mx-3">
              <Row className="pt-3 align-start">
                <Col lg="7">
                  <div className="pt-1">
                    <h6 className="input-header-text">Transaction Amount(Rs /-)<span className="mandate-star">*</span></h6>
                    <Input
                      type="number"
                      name="amnt"
                      required
                      onChange={handleFieldChangeTxn}
                      className="yocard-input-ref"
                    />
                  </div>
                </Col>
                <Col lg="5">
                  <div className="pt-1">
                    <h6 className="input-header-text">Transaction Date<span className="mandate-star">*</span></h6>
                    <Input
                      type="date"
                      name="txnDate"
                      required
                      onChange={handleFieldChangeTxn}
                      className="yocard-input-ref"
                    />
                  </div>
                </Col>
                <Col lg="12">
                  <Row className="align-start pt-4">
                    <h6 className="input-header-text my-1">Transaction Type<span className="mandate-star">*</span></h6>
                    <Col xs="6" lg="4" className="pt-1">
                      <Input
                        type="radio"
                        name="accTp"
                        checked={txnAddData.accTp === 'DBT'}
                        className="yocard-radio-box cursor-pointer my-1"
                        onChange={() => handleTxnType('DBT')}
                      />
                      <Label check className="credit-tp-text mx-1">
                        DEBIT <FontAwesomeIcon className="payment-mode-icon mx-1" icon={faHandHolding} />
                      </Label>
                    </Col>
                    <Col xs="6" lg="4" className="pt-1">
                      <Input
                        type="radio"
                        name="accTp"
                        checked={txnAddData.accTp === 'CRDT'}
                        className="yocard-radio-box cursor-pointer my-1"
                        onChange={() => handleTxnType('CRDT')}
                      />
                      <Label check className="credit-tp-text mx-1">
                        CREDIT <FontAwesomeIcon className="payment-mode-icon mx-1" icon={faHandHoldingDollar} />
                      </Label>
                    </Col>
                    {txnAddData.accTp === 'DBT'
                      ? (
                        <Col xs="12">
                          <h6 className="print-status-pending my-1 pt-2">Transaction To<span className="mandate-star">*</span></h6>
                          <Row className="pt-1 mx-1">
                            <Col xs="4" lg="4">
                              <Input
                                type="radio"
                                name="dbtTp"
                                checked={txnAddData.dbtTp === 'PRNT'}
                                className="yocard-radio-box cursor-pointer my-1"
                                onChange={() => handleDbtType('PRNT')}
                              />
                              <Label check className="credit-tp-text mx-1">
                                Printer
                              </Label>
                            </Col>
                            <Col xs="4" lg="4">
                              <Input
                                type="radio"
                                name="dbtTp"
                                checked={txnAddData.dbtTp === 'VNDR1'}
                                className="yocard-radio-box cursor-pointer my-1"
                                onChange={() => handleDbtType('VNDR1')}
                              />
                              <Label check className="credit-tp-text mx-1">
                                Vendor#1
                              </Label>
                            </Col>
                            <Col xs="4" lg="4">
                              <Input
                                type="radio"
                                name="dbtTp"
                                checked={txnAddData.dbtTp === 'RZENT'}
                                className="yocard-radio-box cursor-pointer my-1"
                                onChange={() => handleDbtType('RZENT')}
                              />
                              <Label check className="credit-tp-text mx-1">
                                RZ Ent.
                              </Label>
                            </Col>
                          </Row>
                        </Col>
                      ) : null }
                  </Row>
                </Col>
                <Col lg="12">
                  <Row className="align-start pt-4">
                    <h6 className="input-header-text my-1">Payment Mode<span className="mandate-star">*</span></h6>
                    <Col xs="12" lg="6" className="pt-2">
                      <Input
                        type="radio"
                        name="mPay"
                        checked={txnAddData.mPay === 'CHQ'}
                        className="yocard-radio-box cursor-pointer my-1"
                        onChange={() => handlePayMode('CHQ')}
                      />
                      <Label check className="credit-tp-text mx-1">
                        Cheque <FontAwesomeIcon className="payment-mode-icon mx-1" icon={faMoneyCheck} />
                      </Label>
                    </Col>
                    <Col xs="12" lg="6" className="pt-2">
                      <Input
                        type="radio"
                        name="mPay"
                        checked={txnAddData.mPay === 'UPI'}
                        className="yocard-radio-box cursor-pointer my-1"
                        onChange={() => handlePayMode('UPI')}
                      />
                      <Label check className="credit-tp-text mx-1">
                        UPI <FontAwesomeIcon className="payment-mode-icon mx-1" icon={faWallet} />
                      </Label>
                    </Col>
                    <Col xs="12" lg="6" className="pt-2">
                      <Input
                        type="radio"
                        name="mPay"
                        checked={txnAddData.mPay === 'BNK'}
                        className="yocard-radio-box cursor-pointer my-1"
                        onChange={() => handlePayMode('BNK')}
                      />
                      <Label check className="credit-tp-text mx-1">
                        Net Banking <FontAwesomeIcon className="payment-mode-icon mx-1" icon={faBuildingColumns} />
                      </Label>
                    </Col>
                    <Col xs="12" lg="6" className="pt-2">
                      <Input
                        type="radio"
                        name="mPay"
                        checked={txnAddData.mPay === 'CSH'}
                        className="yocard-radio-box cursor-pointer my-1"
                        onChange={() => handlePayMode('CSH')}
                      />
                      <Label check className="credit-tp-text mx-1">
                        Cash <FontAwesomeIcon className="payment-mode-icon mx-1" icon={faMoneyBill1Wave} />
                      </Label>
                    </Col>
                  </Row>
                </Col>
                <Col lg="12">
                  <Row className="align-start pt-4">
                    <h6 className="input-header-text my-1">Tag Transactions(non reversible)<span className="mandate-star">*</span></h6>
                    <div className="print-sadmin-card-box my-2">
                      <Row>
                        <Col lg="5" xs="10">
                          <div className="pt-1">
                            <h6 className="input-header-text">Start Date</h6>
                            <Input
                              value={startDate}
                              type="date"
                              name="refDt"
                              onChange={handleStartDate}
                              className="yocard-input-ref"
                            />
                          </div>
                        </Col>
                        <Col lg="5" xs="10">
                          <div className="pt-1">
                            <h6 className="input-header-text">End Date</h6>
                            <Input
                              max={today}
                              value={endDate}
                              type="date"
                              name="refDt"
                              onChange={handleEndDate}
                              className="yocard-input-ref"
                            />
                          </div>
                        </Col>
                        <Col lg="2">
                          <div className="align-end pt-4 mx-2">
                            <Button className="button-icon" onClick={() => handleBillingSearch()}>
                              <FontAwesomeIcon className="accounts-header my-1" icon={faSearch} />
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      {billingStatus.length === 0 ? (
                        <div className="pt-5 align-center">
                          <span className="form-collapse-card-header">No Record Found</span>
                        </div>
                      )
                        : (
                          <table className="styled-table">
                            <thead>
                              <tr>
                                <th>Action</th>
                                <th>Card Info</th>
                                <th>Total Cards</th>
                                <th>Amount(rs)</th>
                                <th>Ownership</th>
                              </tr>
                            </thead>
                            <tbody>
                              {billingStatus && billingStatus.map((row, rowIndex) => (
                                <tr key={`row-${row.PrntId}-${row.PrntTp}`}>
                                  <td>
                                    <Input
                                      type="radio"
                                      checked={txnAddData.prntDt === row.PrntId && txnAddData.prntCd === row.PrntTp}
                                      className="yocard-radio-box cursor-pointer my-1"
                                      onChange={() => handlePrintDetails(row.PrntId, row.PrntTp)}
                                    />
                                  </td>
                                  <td>
                                    <div>
                                      <span className="td-text">{row.PrntId}</span>
                                    </div>
                                    <div>
                                      <span className="td-text">{row.stData.distNme}({row.stData.stCd})</span>
                                    </div>
                                  </td>
                                  <td><span className="td-text">{row.tCnt}</span></td>
                                  <td><span className="td-text">{row.tCrdCost}</span>
                                    <Button className="button-icon" id={`cardCost-${rowIndex}`}>
                                      <FontAwesomeIcon className="info-stadm-icon my-1" icon={faCircleInfo} />
                                    </Button>
                                    <Tooltip
                                      placement="top"
                                      isOpen={crdCostOpen[`cardCost-${rowIndex}`] || false}
                                      target={`cardCost-${rowIndex}`}
                                      toggle={() => toggleCardCost(`cardCost-${rowIndex}`)}
                                    >
                                      <span className="td-text-highlight">Cost Breakup</span><br />
                                      Cost: {row.cardCost} Rs/- <br />
                                      GST: {row.gstCardCost} Rs/-
                                    </Tooltip>
                                  </td>
                                  <td><span className="td-text">{row.sndrNme}</span></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      {loaderAdmin ? (
                        <Spinner
                          style={{ color: '#FF7F50' }}
                          type="grow"
                        >
                          Loading...
                        </Spinner>
                      ) : null}
                    </div>
                  </Row>
                </Col>
                <Col lg="12">
                  <div className="pt-4">
                    <h6 className="input-header-text">Transaction done by<span className="mandate-star">*</span></h6>
                    <Input
                      type="text"
                      name="txnUser"
                      required
                      onChange={handleFieldChangeTxn}
                      className="yocard-input-ref"
                    />
                  </div>
                </Col>
                <Col lg="12">
                  <div className="pt-4">
                    <h6 className="input-header-text">Comments</h6>
                    <Input
                      type="textarea"
                      name="comments"
                      onChange={handleFieldChangeTxn}
                      className="yocard-text-area"
                    />
                  </div>
                </Col>
              </Row>
              <div className="align-center my-1 pt-3">
                <Button
                  disabled={txnAddData.prntCd === '' || txnAddData.amnt === '' || txnAddData.accTp === '' || txnAddData.mPay === ''}
                  className="yocard-button-oval"
                  onClick={() => handleAddNewTxn()}
                >Save
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </div>
        <Row className="align-center">
          <Col lg="5" xs="6">
            <div className="pt-1">
              <h6 className="input-header-text">Start Date</h6>
              <Input
                value={startDate}
                type="date"
                name="refDt"
                onChange={handleStartDate}
                className="yocard-input-ref"
              />
            </div>
          </Col>
          <Col lg="5" xs="6">
            <div className="pt-1">
              <h6 className="input-header-text">End Date</h6>
              <Input
                max={today}
                value={endDate}
                type="date"
                name="refDt"
                onChange={handleEndDate}
                className="yocard-input-ref"
              />
            </div>
          </Col>
          <Col lg="10">
            <div className="my-2 align-end">
              <div className="pt-1">
                <Button className="yocard-button-oval mx-1" onClick={() => handleTxnSearch()}>
                  Search
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {txnData.length === 0 ? (
        <div className="pt-5 align-center">
          <span className="form-collapse-card-header">No Transactions Found</span>
        </div>
      ) : (
        <table className="styled-table">
          <thead>
            <tr>
              <th>Txn Date</th>
              <th>Txn Amount<FontAwesomeIcon className="accounts-card-icon-lite mx-1" icon={faIndianRupee} /></th>
              <th>Txn Type</th>
              <th>Txn(to/from)</th>
              <th>Txn User</th>
              <th>Payment Mode</th>
              <th>Any Comments</th>
              <th>Print Details</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {txnData.length && txnData.map((row, rowIndex) => (
              <tr key={`txn-${rowIndex.toString()}`}>
                <td>
                  <span className="td-text">{row.AccTp}</span>
                </td>
                <td>
                  <span className="td-text">{row.amnt.toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')}</span>
                </td>
                <td>
                  <span className="td-text">{row.accTpVal}</span>
                </td>
                <td>
                  <span className="td-text">{row.dbtTpVal}</span>
                </td>
                <td>
                  <span className="td-text">{row.txnUser}</span>
                </td>
                <td><span className="td-text">{row.mPayVal}</span></td>
                <td><span className="td-text">{row.comments}</span></td>
                <td>
                  <div>
                    <span className="text-lite-grey">Cards: </span>
                    <span className="td-text">{row.cardDet.tCnt}</span>
                  </div>
                  <div>
                    <span className="text-lite-grey">Date: </span>
                    <span className="td-text">{row.prntDt}</span>
                  </div>
                  {/* <div>
                    <span className="td-text">{row.prntCd}</span>
                  </div> */}
                  <div>
                    <span className="td-text">{row.cardDet.stData.distNme}({row.cardDet.stData.stCd})</span>
                  </div>
                </td>
                <td>
                  <span className="align-center">
                    <Button id={`txnDet-${(rowIndex + 1).toString()}`} className="button-icon mx-1" onClick={() => txnToggleModal(`txnDet-${rowIndex}`, row)}>
                      <FontAwesomeIcon className="collapse-icon mx-1" icon={faEdit} />
                    </Button>
                    <UncontrolledTooltip delay={0} target={`txnDet-${(rowIndex + 1).toString()}`}>
                      Edit Costing, Courier & Count
                    </UncontrolledTooltip>
                  </span>
                  <Modal
                    isOpen={openModalTxn[`txnDet-${rowIndex}`] || false}
                    target={`txnDet-${rowIndex}`}
                    className="pt-5"
                  >
                    <ModalHeader toggle={() => txnToggleModal(`txnDet-${rowIndex}`, row)} className="form-card-border-none mx-3 my-2" />
                    <div className="ml-1">
                      <span className="text-lite-grey">Date: {row.AccTp}</span>
                    </div>
                    <ModalBody className="padding-zero mx-3">
                      <Row className="pt-3 align-start">
                        <Col lg="7">
                          <div className="pt-1">
                            <h6 className="input-header-text">Transaction Amount(Rs /-)<span className="mandate-star">*</span></h6>
                            <Input
                              type="number"
                              name="amnt"
                              value={txnUpdateData.amnt}
                              required
                              onChange={handleFieldUpdateChangeTxn}
                              className="yocard-input-ref"
                            />
                          </div>
                        </Col>
                        <Col lg="5">
                          <div className="pt-1">
                            <h6 className="input-header-text">Transaction Date<span className="mandate-star">*</span></h6>
                            <Input
                              type="date"
                              name="txnDate"
                              value={txnUpdateData.txnDate}
                              required
                              onChange={handleFieldUpdateChangeTxn}
                              className="yocard-input-ref"
                            />
                          </div>
                        </Col>
                        <Col lg="12">
                          <Row className="align-start pt-4">
                            <h6 className="input-header-text my-1">Transaction Type<span className="mandate-star">*</span></h6>
                            <Col xs="6" lg="4" className="pt-1">
                              <Input
                                type="radio"
                                name="accTp"
                                checked={txnUpdateData.accTp === 'DBT'}
                                className="yocard-radio-box cursor-pointer my-1"
                                onChange={() => handleTxnUpdateType('DBT')}
                              />
                              <Label check className="credit-tp-text mx-1">
                                DEBIT <FontAwesomeIcon className="payment-mode-icon mx-1" icon={faHandHolding} />
                              </Label>
                            </Col>
                            <Col xs="6" lg="4" className="pt-1">
                              <Input
                                type="radio"
                                name="accTp"
                                checked={txnUpdateData.accTp === 'CRDT'}
                                className="yocard-radio-box cursor-pointer my-1"
                                onChange={() => handleTxnUpdateType('CRDT')}
                              />
                              <Label check className="credit-tp-text mx-1">
                                CREDIT <FontAwesomeIcon className="payment-mode-icon mx-1" icon={faHandHoldingDollar} />
                              </Label>
                            </Col>
                            {txnUpdateData.accTp === 'DBT'
                              ? (
                                <Col xs="12">
                                  <h6 className="print-status-pending my-1 pt-2">Transaction To<span className="mandate-star">*</span></h6>
                                  <Row className="pt-1 mx-1">
                                    <Col xs="4" lg="4">
                                      <Input
                                        type="radio"
                                        name="dbtTp"
                                        checked={txnUpdateData.dbtTp === 'PRNT'}
                                        className="acps-radio-box cursor-pointer my-1"
                                        onChange={() => handleUpdateDbtType('PRNT')}
                                      />
                                      <Label check className="credit-tp-text mx-1">
                                        Printer
                                      </Label>
                                    </Col>
                                    <Col xs="4" lg="4">
                                      <Input
                                        type="radio"
                                        name="dbtTp"
                                        checked={txnUpdateData.dbtTp === 'VNDR1'}
                                        className="acps-radio-box cursor-pointer my-1"
                                        onChange={() => handleUpdateDbtType('VNDR1')}
                                      />
                                      <Label check className="credit-tp-text mx-1">
                                        Vendor#1
                                      </Label>
                                    </Col>
                                    <Col xs="4" lg="4">
                                      <Input
                                        type="radio"
                                        name="dbtTp"
                                        checked={txnUpdateData.dbtTp === 'RZENT'}
                                        className="yocard-radio-box cursor-pointer my-1"
                                        onChange={() => handleUpdateDbtType('RZENT')}
                                      />
                                      <Label check className="credit-tp-text mx-1">
                                        RZ Ent.
                                      </Label>
                                    </Col>
                                  </Row>
                                </Col>
                              ) : null }
                          </Row>
                        </Col>
                        <Col lg="12">
                          <Row className="align-start pt-4">
                            <h6 className="input-header-text my-1">Payment Mode<span className="mandate-star">*</span></h6>
                            <Col xs="12" lg="6" className="pt-2">
                              <Input
                                type="radio"
                                name="mPay"
                                checked={txnUpdateData.mPay === 'CHQ'}
                                className="yocard-radio-box cursor-pointer my-1"
                                onChange={() => handlePayUpdateMode('CHQ')}
                              />
                              <Label check className="credit-tp-text mx-1">
                                Cheque <FontAwesomeIcon className="payment-mode-icon mx-1" icon={faMoneyCheck} />
                              </Label>
                            </Col>
                            <Col xs="12" lg="6" className="pt-2">
                              <Input
                                type="radio"
                                name="mPay"
                                checked={txnUpdateData.mPay === 'UPI'}
                                className="yocard-radio-box cursor-pointer my-1"
                                onChange={() => handlePayUpdateMode('UPI')}
                              />
                              <Label check className="credit-tp-text mx-1">
                                UPI <FontAwesomeIcon className="payment-mode-icon mx-1" icon={faWallet} />
                              </Label>
                            </Col>
                            <Col xs="12" lg="6" className="pt-2">
                              <Input
                                type="radio"
                                name="mPay"
                                checked={txnUpdateData.mPay === 'BNK'}
                                className="yocard-radio-box cursor-pointer my-1"
                                onChange={() => handlePayUpdateMode('BNK')}
                              />
                              <Label check className="credit-tp-text mx-1">
                                Net Banking <FontAwesomeIcon className="payment-mode-icon mx-1" icon={faBuildingColumns} />
                              </Label>
                            </Col>
                            <Col xs="12" lg="6" className="pt-2">
                              <Input
                                type="radio"
                                name="mPay"
                                checked={txnUpdateData.mPay === 'CSH'}
                                className="yocard-radio-box cursor-pointer my-1"
                                onChange={() => handlePayUpdateMode('CSH')}
                              />
                              <Label check className="credit-tp-text mx-1">
                                Cash <FontAwesomeIcon className="payment-mode-icon mx-1" icon={faMoneyBill1Wave} />
                              </Label>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg="12">
                          <div className="pt-4">
                            <h6 className="input-header-text">Transaction done by<span className="mandate-star">*</span></h6>
                            <Input
                              type="text"
                              name="txnUser"
                              value={txnUpdateData.txnUser}
                              required
                              onChange={handleFieldUpdateChangeTxn}
                              className="yocard-input-ref"
                            />
                          </div>
                        </Col>
                        <Col lg="12">
                          <div className="pt-4">
                            <h6 className="input-header-text">Comments</h6>
                            <Input
                              type="textarea"
                              name="comments"
                              value={txnUpdateData.comments}
                              onChange={handleFieldUpdateChangeTxn}
                              className="yocard-text-area"
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className="align-center my-1 pt-3">
                        <Button className="yocard-button-oval" onClick={() => updateTxnData(row.AccId)}>Update</Button>
                      </div>
                    </ModalBody>
                  </Modal>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TransactionTable;

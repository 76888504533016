import { ListTalukaResponse, ListTalukaResults } from './list-taluka.service.types';

export const ListTalukaTransform: (response: ListTalukaResponse) => ListTalukaResults = (listTalukaResults) => {
  const { data, is_error } = listTalukaResults;
  const result: ListTalukaResults = {
    tCnt: 0,
    fCnt: 0,
    talukaData: [],
    isError: false,
  };
  if (is_error) {
    result.isError = is_error;
  } else {
    result.tCnt = data.tCnt;
    result.fCnt = data.fCnt;
    result.talukaData = data.talukaData;
  }
  return result;
};

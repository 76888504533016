import { UploadImageResponse, UploadImageResults } from './upload-image.service.types';

export const UploadImageTransform: (response: UploadImageResponse) => UploadImageResults = (profileResults) => {
  const { data, is_error, message } = profileResults;
  const result :UploadImageResults = {
    message: '',
    error: false,
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
    // result.errorMessage = 'Backend Issue';
  } else {
    result.message = data.message;
  }
  return result;
};

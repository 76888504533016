import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import {
  Col, Row,
} from 'reactstrap';
import './accounts.scss';
import { Doughnut } from 'react-chartjs-2';
import { getTxnReport, clearGetTxnReport } from '../../../../store/yoCard/actions';
import { APIHeader } from '../../../../utils/constants';
import { getTokenFromLocalStorage, getUserTpSessionStorage } from '../../../../utils/service/localstorage-service';
import { successAlert, errorAlert } from '../../../../utils/alert';
import LoaderData from '../../../../utils/loader';
import { TransactionTable } from './txn-table';
import { TxnsReportResult } from '../../../../services/sadmin/accounts/txn-report/get-txn-report.types';

export const AccountsReport: React.FC = () => {
  const dispatch = useDispatch();
  const userTp = getUserTpSessionStorage();
  const tokenData = getTokenFromLocalStorage();
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [loaderAdmin, setLoaderAdmin] = useState(false);
  const txnReportResponse = useSelector((state: RootState) => state.yoCard.getTxnReport);
  const [dataArrayDough, setDataArrayDough] = useState<number[]>([]);
  const order: (keyof TxnsReportResult)[] = ['client', 'printer', 'rzEnt', 'vendor1'];
  const dataDough = {
    labels: ['Credited', 'Printer', 'Vendor#1', 'RZ Ent.'],
    datasets: [
      {
        data: dataArrayDough,
        backgroundColor: ['#0A8242', '#472B6E', '#1D8CF8', '#FF8D72'],
        hoverBackgroundColor: ['#0A8242', '#472B6E', '#1D8CF8', '#FF8D72'],
      },
    ],
  };
  const optionsDough = {
    plugins: {
      color: '#FAFAFA',
      tooltip: {
        mode: 'index' as any,
        intersect: true,
        backgroundColor: '#383838 ',
        borderColor: '#959191',
        borderWidth: 1,
        titleFont: {
          size: 13,
          weight: 'bold',
        },
        bodyFont: {
          size: 13,
        },
        callbacks: {
          title: (context: any[]) => {
            if (context.length > 0) {
              const { dataIndex } = context[0];
              return dataDough.labels[dataIndex];
            }
            return '';
          },
          label: (context: any) => {
            const label = context.dataset.label || '';
            const value = context.parsed || 0;
            return `${label}: ${value.toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')} (Rs/-)`;
          },
        },
      },
    },
  };
  React.useEffect(() => {
    if (userTp === 'SADM') {
      setLoaderAdmin(true);
      Promise.resolve(
        dispatch(getTxnReport({
          userType: userTp,
          requestType: APIHeader.REQ_GET_ALL_TXN_REPORT,
          token: tokenData,
        })),
      ).then(() => {
        dispatch(clearGetTxnReport());
      });
    }
  }, [userTp]);

  React.useEffect(() => {
    if (txnReportResponse.isError && txnReportResponse.message !== '') {
      setLoaderAdmin(false);
      setErrorShowAlert(true);
      setAlertMsg(txnReportResponse.message);
      dispatch(clearGetTxnReport());
    }
  }, [txnReportResponse.isError, txnReportResponse.message]);

  React.useEffect(() => {
    if (!txnReportResponse.isError && txnReportResponse.message === 'executed') {
      setLoaderAdmin(false);
      setDataArrayDough(order.map((property) => txnReportResponse.data[property]));
      dispatch(clearGetTxnReport());
    }
  }, [txnReportResponse.isError, txnReportResponse.message]);

  return (
    <div>
      { loaderAdmin ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Row className="pt-2">
        <Col xs="12" lg="9">
          <TransactionTable />
        </Col>
        <Col xs="12" lg="3">
          <div className="form-card-border pt-2">
            <div className="my-4 align-center ">
              <Doughnut data={dataDough} options={optionsDough} />
            </div>
            <div className="my-4 align-center ">
              <span>Account Summary</span>
            </div>
          </div>
        </Col>
        <Col lg="2" />
      </Row>
    </div>
  );
};

export default AccountsReport;

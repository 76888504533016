export enum ApiLIMIT{
    LIMIT = 30
}
export enum UserType {
    AGNT='AGNT',
    PRNT='PRNT',
    SADM='SADM',
    DADM='DADM',
    CRDOPT = 'CRDOPT',
    STADM='STADM',
    DSTCRD='DSTCRD'
}
export enum APIHeader{
    REQ_FORM_DATA = 'form_data',
    REQ_IMG_DATA = 'image_data',
    USER_LOGIN = 'user_login',
    REQ_GENERATE_OTP = 'generate_otp',
    REQ_VERIFY_OTP = 'verify_otp',
    REQ_USER_PROFILE_INFO = 'user_profile_info',
    REQ_PUB_FORGOT_PASSWORD = 'pub_forgot_password',
    REQ_FILE_URL = 'get_presigned_url',
    REQ_ADD_AGENT = 'add_agent',
    REQ_UPDATE_PASSWORD = 'update_password',
    USER_EXIST_STATUS = 'user_exist_status',
    REQ_UPLOAD_FILES = 'upload_files',
    REQ_GET_ALL_ST = 'get_all_state_meta',
    REQ_GET_ALL_DIST = 'get_all_dist_meta',
    REQ_GET_ALL_TALUKA = 'get_all_taluka_meta',
    REQ_GET_ALL_TALUKA_ADM = 'get_all_taluka_by_dst_adm',
    REQ_GET_ALL_AGNT_STS = 'get_all_agent_by_status',
    REQ_UPDATE_AGNT_STS = 'update_agent_status',
    REQ_GEN_CARD_REF = 'generate_card_ref',
    REQ_ALL_REF_DATE = 'get_all_ref_by_date',
    REQ_CHECK_FILE_NAMES = 'check_file_names',
    REQ_UPDATE_CARD_ACTIVITY = 'update_card_activity',
    REQ_GET_PRINT_FILE_ZIP = 'get_print_ready',
    REQ_STS_PRINT_READY = 'status_print_ready',
    REQ_GEN_PRINT_URL = 'get_url_print_ready',
    REQ_DEL_PRINT_URL = 'del_url_print_ready',
    REQ_PRINT_STATUS_DATA = 'update_print_status_data',
    REQ_GET_ALL_TALUKA_LOC_CNT = 'get_all_taluka_meta_cnt_by_loc',
    REQ_GET_SUB_FOL_CNT = 'get_all_sub_fol_cnt',
    REQ_GEN_PRE_PRINT_ZIP = 'get_url_pre_print_ready',
    REQ_DOWNLOAD_PDF = 'download_pdf_to_process',
    REQ_MASS_STS_UPDATE = 'mass_status_updates',
    REQ_REPORT_DAY_WISE_CARDS = 'report_cards_day_wise',
    REQ_REPORT_DASHBOARD = 'report_dashboard',
    REQ_REPORT_DASHBOARD_TREND_STS = 'report_dashboard_trend_sts',
    REQ_REPORT_PRINT_DASHBOARD = 'report_print_dashboard',
    REQ_ALL_PRINTER_ID = 'get_all_printer_id',
    REQ_CREATE_PRINTER_ID = 'create_printer_id',
    REQ_SUPERADMIN_DASHBOARD = 'superadmin_dashboard',
    REQ_SUPERADMIN_TREND_STS = 'superadmin_trend_sts',
    REQ_UPDATE_CARD_BILL = 'update_card_bill',
    REQ_GET_CARD_BILLS = 'get_card_bills',
    REQ_UPDATE_BILLING_STS = 'update_billing_status',
    REQ_ADD_BILLING_ACCOUNTS = 'add_billing_accounts',
    REQ_UPDATE_BILLING_ACCOUNTS = 'update_billing_accounts',
    REQ_GET_ALL_BILLING_ACCOUNTS = 'get_all_billing_accounts',
    REQ_GET_ALL_TXN_REPORT = 'get_all_txn_report',
}
export enum APIParam{
    DIALING_META = 'dialing_code_meta',
    DIFF_ABLED_META = 'diff_abled_meta',
    EXT_ORG_META = 'ext_org_meta',
    JOB_TITLE_META = 'job_title_meta',
    SKILLS_META = 'skills_meta',
    COLLEGE_META = 'college_meta',
    COURSE_META = 'course_meta',
    CITY_META = 'city_meta',
    AVATAR_IMAGE = 'AVATAR',
    VIDEO = 'VIDEO'
}
export const AdminCurrentStatusList = [
//   { label: 'All', value: 'ALL' },
  { label: 'E-Kyc completed', value: 'INIT' },
  { label: 'PDF Download Error', value: 'REFER' },
  { label: 'PDF Download Success', value: 'REFSS' },
  { label: 'PDF Card Generated', value: 'GNTD' },
  { label: 'Create Print Ready', value: 'PRNTR' },
  { label: 'Printing In Progress', value: 'PRNTP' },
  { label: 'Printing Completed', value: 'PRNTC' },
  { label: 'Card Couriered', value: 'COUR' },
  { label: 'Center Recieved Cards', value: 'RCVDC' },
];

export const DistrictCurrentStatusList = [
  //   { label: 'All', value: 'ALL' },
  { label: 'E-Kyc completed', value: 'INIT' },
  { label: 'PDF Download Error', value: 'REFER' },
  { label: 'PDF Download Success', value: 'REFSS' },
  { label: 'PDF Card Generated', value: 'GNTD' },
  { label: 'Printing In Progress', value: 'PRNTP' },
  { label: 'Printing Completed', value: 'PRNTC' },
  { label: 'Card Couriered', value: 'COUR' },
  { label: 'Center Recieved Cards', value: 'RCVDC' },
];

export const CardErrorReasonList = [
  { label: 'Card Not Found', value: 'CNF' },
  { label: 'Kyc Not Completed', value: 'KNC' },
  { label: 'Invalid Reference No or User Id', value: 'INVRU' },
  { label: 'Rejected by ISA, Pending at SHA', value: 'RJISAPSHA' },
  { label: 'NOT DOWNLOADED', value: 'NDWN' },
];

export const VendorList = [
  { label: 'Billing Pending from Ozyk/Client', value: 'BPFO' },
  { label: 'Payment Pending for Printer', value: 'PPFP' },
  { label: 'Payment Pending for RZ Ent.', value: 'PPFZ' },
  { label: 'Payment Pending for Vendor1', value: 'PPFV1' },
  { label: 'Billing Completed from Ozyk/Client', value: 'BCFO' },
  { label: 'Payment Completed for Printer', value: 'PCFP' },
  { label: 'Payment Completed for RZ Ent.', value: 'PCFZ' },
  { label: 'Payment Completed for Vendor1', value: 'PCFV1' },
];

export const BillingCLientList = [
  { label: 'Billing Pending from Ozyk/Client', value: 'BPFO' },
  { label: 'Billing Completed from Ozyk/Client', value: 'BCFO' },
];

export const BillingPrinterList = [
  { label: 'Payment Pending for Printer', value: 'PPFP' },
  { label: 'Payment Completed for Printer', value: 'PCFP' },
];

export const BillingVendor1List = [
  { label: 'Payment Pending for Vendor1', value: 'PPFV1' },
  { label: 'Payment Completed for Vendor1', value: 'PCFV1' },
];

export const BillingZameerList = [
  { label: 'Payment Pending for RZ Ent.', value: 'PPFZ' },
  { label: 'Payment Completed for RZ Ent.', value: 'PCFZ' },
];

export const CardTypeList = [
  { label: 'Below Poverty Line (BPL)', value: 'BPL' },
  { label: 'Above Poverty Line (APL)', value: 'APL' },
];

export const ConsgTpList = [
  { label: 'Blue Dart', value: 'BDRT' },
  { label: 'Professional Couriers', value: 'PROF' },
];

import React, { useState } from 'react';
import {
  Button,
  Col, Input, Modal, ModalBody, ModalHeader, Row, Tooltip, UncontrolledTooltip,
} from 'reactstrap';
import Select from 'react-select';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import './print.scss';
import {
  faCircleInfo,
  faDownload, faEdit, faFileArrowDown,
  // faPrint, faRotateLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-regular-svg-icons';
import {
  clearAWSFolderList,
  clearCardPrintReadyReq,
  clearCardStatusReq,
  clearGenPrintUrlReq,
  clearPrePrintZipReq,
  clearSubFolCountListReq,
  genPrintUrlReq,
  getDownloadFileReq, getStateListReq, getSubFolCountListReq, postPrintStatusReq,
} from '../../../../store/yoCard/actions';
import LoaderData from '../../../../utils/loader';
import { ActiveTabProps } from './print.type';
import { getConfig } from '../../../../utils/config/config';
import { APIHeader, ConsgTpList, UserType } from '../../../../utils/constants';
import { getUserTpSessionStorage, getTokenFromLocalStorage } from '../../../../utils/service/localstorage-service';
import { errorAlert, successAlert } from '../../../../utils/alert';
// import { formatDate } from '../../../../utils/service/service.utils';
import { ListSubFolCountResult, SubFolCountResultRes } from '../../../../services/get-s3-subFol-count/list-subFol-count.types';
import { StateResult } from '../../../../services/meta-data/list-state/list-state.types';
import { CardBillingInput } from '../../../../services/update-print-status/update-print-status.types';
// import { CardStResult } from '../../../../services/reports-all/get-cards-state-wise/card-state-wise.types';

const { AWS_CARD_ACTIVITY_BUCKET } = getConfig();

export const GeneratePrintFile: React.FC<ActiveTabProps> = ({ activeTab }) => {
  const dispatch = useDispatch();
  const userTp = getUserTpSessionStorage();
  const tokenData = getTokenFromLocalStorage();
  const [stateData, setStateData] = useState('');
  const [loaderState, setLoaderState] = useState(false);
  const [stateListData, setStateListData] = useState<StateResult[]>([]);
  const stateResData = useSelector((state: RootState) => state.yoCard.getStateMetaList);
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [alertAPIMsg, setAlertAPIMsg] = useState('');
  const [tooltipOpen, setTooltipOpen] = React.useState<Record<string, boolean>>({});
  // const getCardsStateWiseResponse = useSelector((state: RootState) => state.yoCard.getCardCountStateWise);
  const [dbSubFolder, setDBSubFolder] = useState<ListSubFolCountResult>({
    printDet: [{
      PrntId: '',
      PrntTp: '',
      updtCnt: 0,
      tCnt: '0',
      courId: '',
      cSts: '',
      updtOn: '',
      costCrd: '',
      cStsV: '',
      gstCrd: '',
      gstPrint: '',
      costVendor1: '',
      costPrint: '',
      gstVendor1: '',
      sndrNme: '',
      aCmnt: '',
      miscCst: '',
      courTp: '',
      distDet: {
        distNme: '',
        stCd: '',
        stNme: '',
      },
      prntUsrCd: {
        ph: '',
        st: '',
        UserCd: '',
        lNme: '',
        fNme: '',
        eId: '',
      },
    }],
    isError: false,
    message: '',
  });
  const [billingInput, setBillingInput] = useState<CardBillingInput>({
    costCrd: '0',
    gstCrd: '0',
    costPrint: '0',
    gstPrint: '0',
    costVendor1: '0',
    gstVendor1: '0',
    stsClient: 'BPFO',
    stsPrnt: 'PPFP',
    stsVendor1: 'PPFV1',
    stsZameer: 'PPFZ',
    sndrNme: '',
    miscCst: '',
    aCmnt: '',
    courTp: '',
    courId: 'NA',
    tCnt: '0',
  });

  // const [cardStatus, setCardStatus] = useState<CardStResult>({
  //   tCnt: 0,
  //   data: [
  //     {
  //       cnt: 0,
  //       st: '',
  //       currentSts: '',
  //     },
  //   ],
  // });
  const today = new Date().toISOString().split('T')[0];
  const init_today = new Date();
  init_today.setDate(init_today.getDate() - 7);
  const lastSeventhDay = init_today.toISOString().split('T')[0];
  const [uploadLoader, setUploadLoader] = useState(false);
  const [inputDate, setInputDate] = useState<string>(lastSeventhDay);
  const [toDate, setToDate] = useState<string>(new Date().toISOString().split('T')[0]);
  // const getAWSFoldersResponse = useSelector((state: RootState) => state.yoCard.awsFoldersList);
  const generatePrintZipResponse = useSelector((state: RootState) => state.yoCard.cardStatus);
  // const updatePrintingStatusResponse = useSelector((state: RootState) => state.yoCard.printReady);
  const downloadFilesResponse = useSelector((state: RootState) => state.yoCard.downloadFiles);
  const getSubFolCountResponse = useSelector((state: RootState) => state.yoCard.getSubFolderList);
  const genPrintUrlResponse = useSelector((state: RootState) => state.yoCard.genPrintUrl);
  const genPrePrintRes = useSelector((state: RootState) => state.yoCard.prePrintZipReady);
  const [openModalPrint, setOpenModalPrint] = React.useState<Record<string, boolean>>({});
  const updatePrintStatusResponse = useSelector((state: RootState) => state.yoCard.postPrintStatus);
  const handleFieldChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputDate(e.target.value);
  };
  const handleToChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToDate(e.target.value);
  };

  const handleFieldChangeBilling = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const isInteger = /^[0-9]*$/.test(inputValue);
    if (isInteger) {
      setBillingInput({ ...billingInput, [e.target.name]: inputValue });
    } else {
      setBillingInput({ ...billingInput, [e.target.name]: e.target.value });
    }
  };

  // const printToggleModal = (target: string) => {
  //   setOpenModalPrint((prevState) => ({
  //     ...prevState,
  //     [target]: !prevState[target],
  //   }));

  //   // setCntData(count);
  //   // setCourData(courierId);
  // };

  const toggle = (target: string) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  const printToggleModal = (target: string, rowData: SubFolCountResultRes) => {
    setOpenModalPrint((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
    setBillingInput({
      ...billingInput,
      tCnt: rowData.tCnt ? rowData.tCnt.toString() : '0',
      courId: rowData.courId ? rowData.courId : '',
      costCrd: rowData.costCrd ? rowData.costCrd : '0',
      gstCrd: rowData.gstCrd ? rowData.gstCrd : '0',
      costPrint: rowData.costPrint ? rowData.costPrint : '0',
      gstPrint: rowData.gstPrint ? rowData.gstPrint : '0',
      costVendor1: rowData.costVendor1 ? rowData.costVendor1 : '0',
      gstVendor1: rowData.gstVendor1 ? rowData.gstVendor1 : '0',
      sndrNme: rowData.sndrNme ? rowData.sndrNme : '',
      aCmnt: rowData.aCmnt ? rowData.aCmnt : '',
      miscCst: rowData.miscCst ? rowData.miscCst : '0',
      courTp: rowData.courTp ? rowData.courTp : 'UNK',
    });
  };

  // const isAnyMsgDetEmpty = dbSubFolder.subFolData.some((data) => data.msgDet.length === 0);
  // const getCurrentStatusData = (loadMsg: any) => {
  //   const buttonText = currentSts.some((msg) => msg.pMsgId === null)
  //     ? 'Initiated'
  //     : 'Default Button Name';
  //   const buttonElement = document.getElementById('yourButtonId') as HTMLInputElement | null;
  //   if (buttonElement) {
  //     buttonElement.value = buttonText;
  //   }
  //   setCurrentSts(loadMsg);
  //   setShowPopup(true);
  //   // setSelectedMessage(currentSts[0]); // Update with your logic to select the appropriate message
  // };

  // const closePopup = () => {
  //   setShowPopup(false);
  //   // setSelectedMessage(null);
  // };

  const totalTCnt = dbSubFolder.printDet.reduce((accumulator, entry) => accumulator + Number(entry.tCnt), 0);

  const stateoptions = stateListData && stateListData.map((state) => ({
    label: state.nme,
    value: state.MetaId,
  }));

  const selectedStateOptions = stateoptions && stateoptions.find(
    (option) => option.value === stateData,
  );

  React.useEffect(() => {
    setLoaderState(true);
    dispatch(getStateListReq({
      requestType: APIHeader.REQ_GET_ALL_ST,
    }));
  }, []);

  React.useEffect(() => {
    if (!stateResData.isError && stateResData.message === 'executed') {
      setLoaderState(false);
      setStateListData(stateResData.stData);
    }
  }, [stateResData.stData, stateResData.message]);

  const handleSelectState = (e:any) => {
    setStateData(e.value);
    // dispatch(getDistListReq({
    //   requestType: APIHeader.REQ_GET_ALL_DIST,
    //   state: e.value,
    // }));
  };

  const getAllPrintableFile = () => {
    setUploadLoader(true);
    dispatch(getSubFolCountListReq({
      userType: userTp,
      requestType: APIHeader.REQ_GET_SUB_FOL_CNT,
      stDt: inputDate,
      endDt: toDate,
      stCd: stateData,
      // subFolData: { st: getAWSFoldersResponse.awsDir.st, distDet: getAWSFoldersResponse.awsDir.distDet },
      token: tokenData,
    }));
    // dispatch(getAWSFolderList({
    //   bucketNme: AWS_CARD_ACTIVITY_BUCKET,
    //   stData: stateData,
    //   crdDate: inputDate,
    //   requestType: '',
    // }));
  };

  // React.useEffect(() => {
  //   if (getAWSFoldersResponse.message === 'failed' && activeTab === 'printReady') {
  //     setUploadLoader(false);
  //     setAlertAPIMsg('Failed Retrievieng Data from Cloud');
  //     setErrorShowAlert(true);
  //     dispatch(clearAWSFolderList());
  //     dispatch(clearSubFolCountListReq());
  //   }
  // }, [getAWSFoldersResponse.message === 'failed', activeTab]);

  // React.useEffect(() => {
  //   if (getAWSFoldersResponse.message === 'executed' && getAWSFoldersResponse.awsDir && activeTab === 'printReady') {
  //     setUploadLoader(true);
  //     dispatch(getSubFolCountListReq({
  //       userType: userTp,
  //       requestType: APIHeader.REQ_GET_SUB_FOL_CNT,
  //       crdDt: inputDate,
  //       // stateCd: stateData,
  //       subFolData: { st: getAWSFoldersResponse.awsDir.st, distDet: getAWSFoldersResponse.awsDir.distDet },
  //       token: tokenData,
  //     }));
  //     dispatch(clearAWSFolderList());
  //   } else {
  //     // setUploadLoader(false);
  //     setDBSubFolder({ ...dbSubFolder, distDet: [] });
  //   }
  // }, [getAWSFoldersResponse.message, getAWSFoldersResponse.awsDir, activeTab]);

  React.useEffect(() => {
    if (getSubFolCountResponse.isError && getSubFolCountResponse.message !== '' && activeTab === 'printReady') {
      setUploadLoader(false);
      setAlertAPIMsg(getSubFolCountResponse.message);
      setErrorShowAlert(true);
      dispatch(clearAWSFolderList());
      dispatch(clearSubFolCountListReq());
    }
  }, [getSubFolCountResponse.isError, getSubFolCountResponse.message, activeTab]);

  React.useEffect(() => {
    if (!getSubFolCountResponse.isError && getSubFolCountResponse.message === 'executed' && activeTab === 'printReady') {
      setUploadLoader(false);
      setDBSubFolder(getSubFolCountResponse);
      dispatch(clearAWSFolderList());
      dispatch(clearSubFolCountListReq());
    }
  }, [getSubFolCountResponse.isError, getSubFolCountResponse.message, activeTab]);

  // const generateExcel = (subFolder: string) => {
  //   setUploadLoader(true);
  //   dispatch(cardStatusReq({
  //     requestType: APIHeader.REQ_UPDATE_CARD_ACTIVITY,
  //     userType: UserType.SADM,
  //     subFol: subFolder,
  //     token: tokenData,
  //     crdDt: inputDate,
  //   }));
  // };

  React.useEffect(() => {
    if (generatePrintZipResponse.error && generatePrintZipResponse.message !== '') {
      setUploadLoader(false);
      setAlertAPIMsg(generatePrintZipResponse.message);
      setErrorShowAlert(true);
    }
  }, [generatePrintZipResponse.error, generatePrintZipResponse.message]);

  React.useEffect(() => {
    if (!generatePrintZipResponse.error && generatePrintZipResponse.id !== '') {
      setUploadLoader(false);
      setAlertAPIMsg(`Generation of File is in progress. Ref Id Created - ${generatePrintZipResponse.id}`);
      setSuccessShowAlert(true);
      dispatch(clearCardStatusReq());
      getAllPrintableFile();
    }
  }, [generatePrintZipResponse.error, generatePrintZipResponse.id]);

  const handleCreatePrintReady = (crdDate: string, distFold: string) => {
    // setFoldName(subFolderData);
    setUploadLoader(true);
    // console.log("Calling AOI", stateData, inputDate)
    dispatch(getDownloadFileReq({
      requestType: APIHeader.REQ_DOWNLOAD_PDF,
      bucketNme: AWS_CARD_ACTIVITY_BUCKET,
      crdDt: crdDate,
      distFold,
      stData: stateData,
    }));
    // setSubFolder(subFolderData);
    // setModalPrintReady(true);
  };

  // const handleCreateReGenPrintReady = () => {
  //   setModalReGenPrint(true);
  // };

  // const handlePrintReady = (status: string) => {
  //   if (status === 'approve') {
  //     setIsPrintReadyConfirmed(true);
  //   } else {
  //     setIsPrintReadyConfirmed(false);
  //   }
  //   setModalPrintReady(false);
  // };

  // const handleReGenPrintReady = (status: string) => {
  //   if (status === 'approve') {
  //     setIsReGenPrintConfirmed(true);
  //   } else {
  //     setIsReGenPrintConfirmed(false);
  //   }
  //   setModalReGenPrint(false);
  // };

  // React.useEffect(() => {
  //   if (isPrintReadyConfirmed) {
  //     setUploadLoader(true);
  //     dispatch(cardStatusReq({
  //       requestType: APIHeader.REQ_UPDATE_CARD_ACTIVITY,
  //       userType: UserType.SADM,
  //       crdDt: subFolder,
  //       token: tokenData,
  //       stData: stateData,
  //     }));
  //   }
  // }, [isPrintReadyConfirmed]);

  // React.useEffect(() => {
  //   if (isReGenPrintConfirmed) {
  //     setUploadLoader(true);
  //     dispatch(printReadyReq({
  //       requestType: APIHeader.REQ_STS_PRINT_READY,
  //       userType: UserType.SADM,
  //       token: tokenData,
  //       crdDt: inputDate,
  //     }));
  //   }
  // }, [isReGenPrintConfirmed]);

  // const updatePrintingStatus = () => {
  //   setUploadLoader(true);
  //   dispatch(printReadyReq({
  //     requestType: APIHeader.REQ_STS_PRINT_READY,
  //     userType: UserType.SADM,
  //     token: tokenData,
  //     crdDt: inputDate,
  //   }));
  // };

  const handleDownload = (url:string) => {
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'downloaded-file.zip';
    anchor.target = '_blank';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  // React.useEffect(() => {
  //   if (downloadFilesResponse.isError && downloadFilesResponse.message !== '') {
  //     setUploadLoader(false);
  //     setAlertAPIMsg(generatePrintZipResponse.message);
  //     setErrorShowAlert(true);
  //   }
  // }, [downloadFilesResponse.isError, downloadFilesResponse.message]);

  React.useEffect(() => {
    if (downloadFilesResponse.message === 'executed') {
      // dispatch(getCardMassStatus({
      //   userType: userTp,
      //   requestType: APIHeader.REQ_MASS_STS_UPDATE,
      //   stData: stateData,
      //   crdDt: inputDate,
      //   crdSts: 'CPIP',
      //   token: tokenData,
      // }));
      setUploadLoader(false);
      handleDownload(downloadFilesResponse.url);
      dispatch(clearCardPrintReadyReq());
      getAllPrintableFile();
    }
  }, [downloadFilesResponse.message, downloadFilesResponse.url]);

  const generateDownloadUrl = (fileDate: string, districtCd: string) => {
    setUploadLoader(true);
    dispatch(genPrintUrlReq({
      requestType: APIHeader.REQ_GEN_PRINT_URL,
      userType: UserType.SADM,
      token: tokenData,
      crdDt: fileDate,
      distCd: districtCd,
      stateCd: stateData,
      // fileNme: `${fileNme}.zip`,
    }));
  };

  React.useEffect(() => {
    if (genPrintUrlResponse.isError && genPrintUrlResponse.message !== '') {
      setUploadLoader(false);
      setAlertAPIMsg(genPrintUrlResponse.message);
      setErrorShowAlert(true);
    }
  }, [genPrintUrlResponse.isError, genPrintUrlResponse.message]);

  React.useEffect(() => {
    if (!genPrintUrlResponse.isError && genPrintUrlResponse.message === 'executed') {
      setUploadLoader(false);
      if (genPrintUrlResponse.url !== '') {
        Promise.resolve(
          handleDownload(genPrintUrlResponse.url),
        ).then(() => {
          dispatch(clearGenPrintUrlReq());
        });
      } else {
        setAlertAPIMsg('Print ready Zip URL is not created yet. Create using Download link');
        setErrorShowAlert(true);
        dispatch(clearGenPrintUrlReq());
      }
    }
  }, [genPrintUrlResponse.isError, genPrintUrlResponse.message]);

  // const generatePrePrintZip = () => {
  //   setUploadLoader(true);
  //   dispatch(getPrePrintZipReq({
  //     requestType: APIHeader.REQ_GEN_PRE_PRINT_ZIP,
  //     userType: UserType.SADM,
  //     token: tokenData,
  //     cardDt: inputDate,
  //   }));
  // };

  React.useEffect(() => {
    if (genPrePrintRes.error && genPrePrintRes.message !== '' && activeTab === 'printReady') {
      setUploadLoader(false);
      setAlertAPIMsg(genPrePrintRes.message);
      setErrorShowAlert(true);
      dispatch(clearPrePrintZipReq());
    }
  }, [genPrePrintRes.message, genPrePrintRes.error, activeTab]);

  React.useEffect(() => {
    if (!genPrePrintRes.error && genPrePrintRes.message === 'executed' && activeTab === 'printReady') {
      setUploadLoader(false);
      setSuccessShowAlert(true);
      setAlertAPIMsg(genPrePrintRes.message);
      dispatch(clearPrePrintZipReq());
    }
  }, [genPrePrintRes.message, genPrePrintRes.error, activeTab]);

  const updatePrintData = (target: string, printType: string, printId: string) => {
    setUploadLoader(true);
    dispatch(postPrintStatusReq({
      requestType: APIHeader.REQ_UPDATE_CARD_BILL,
      userType: UserType.SADM,
      dist: printType,
      crdDt: printId,
      billingInfo: billingInput,
      token: tokenData,
    }));
    toggle(target);
  };

  React.useEffect(() => {
    if (updatePrintStatusResponse.error && updatePrintStatusResponse.errorMessage !== '') {
      setUploadLoader(false);
      if (updatePrintStatusResponse.errorMessage === 'Failed') {
        setAlertAPIMsg('You have already updated printing details for the provided date and district. For additional assistance, please contact the administrator.');
      } else {
        setAlertAPIMsg(updatePrintStatusResponse.errorMessage);
      }
      setErrorShowAlert(true);
    }
  }, [updatePrintStatusResponse.error, updatePrintStatusResponse.errorMessage]);

  React.useEffect(() => {
    if (!updatePrintStatusResponse.error && updatePrintStatusResponse.message !== '') {
      setUploadLoader(false);
      setAlertAPIMsg(updatePrintStatusResponse.message);
      setSuccessShowAlert(true);
    }
  }, [updatePrintStatusResponse.error, updatePrintStatusResponse.message]);

  // Start Billing Card Type List

  const consgTpOptions = ConsgTpList.map((consg) => ({
    label: consg.label,
    value: consg.value,
  }));

  const handleSelectCourTypeData = (e: any) => {
    setBillingInput({ ...billingInput, courTp: e.value });
  };

  const selectedCourTypeOptions = consgTpOptions.find(
    (option) => option.value === billingInput.courTp,
  );
    // End Billing Card Type List

  // React.useEffect(() => {
  //   if (userTp === 'SADM') {
  //     Promise.resolve(
  //       dispatch(getCardCountStateWise({
  //         userType: userTp,
  //         requestType: APIHeader.REQ_REPORT_DAY_WISE_CARDS,
  //         crdDt: inputDate,
  //         token: tokenData,
  //       })),
  //     ).then(() => {
  //       dispatch(clearCardCountStateWise());
  //     });
  //   }
  // }, [userTp]);

  // React.useEffect(() => {
  //   if (getCardsStateWiseResponse.isError && getCardsStateWiseResponse.message !== '') {
  //     setUploadLoader(false);
  //     setCardStatus({
  //       tCnt: 0,
  //       data: [
  //         {
  //           cnt: 0,
  //           st: '',
  //           currentSts: '',
  //         },
  //       ],
  //     });
  //     dispatch(clearCardCountStateWise());
  //   }
  // }, [getCardsStateWiseResponse.isError, getCardsStateWiseResponse.message]);

  // React.useEffect(() => {
  //   if (!getCardsStateWiseResponse.isError && getCardsStateWiseResponse.message === 'executed') {
  //     setCardStatus(getCardsStateWiseResponse.data);
  //     setUploadLoader(false);
  //     dispatch(clearCardCountStateWise());
  //   }
  // }, [getCardsStateWiseResponse.isError, getCardsStateWiseResponse.message]);

  // const getCardStatusDetails = () => {
  //   setUploadLoader(true);
  //   dispatch(getCardCountStateWise({
  //     userType: userTp,
  //     requestType: APIHeader.REQ_REPORT_DAY_WISE_CARDS,
  //     crdDt: inputDate,
  //     token: tokenData,
  //   }));
  // };

  return (
    <div className="align-data-print-mobile">
      {uploadLoader ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertAPIMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertAPIMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="mx-1 align-view-to-row align-center my-3">
        <h6 className="input-header-text">Download Printable File</h6>
      </div>
      <Row className="align-center mx-2">
        <Col lg={{ size: 12, order: 1 }} xs={{ order: 1 }}>
          <div className="print-sadmin-card-box my-2">
            <Row className="pt-1">
              <Col lg="6">
                <div className="pt-1">
                  <h6 className="input-header-text">State<span className="mandate-star">*</span></h6>
                  <Select
                    value={stateData === '' ? null : selectedStateOptions}
                    options={stateoptions}
                    onChange={handleSelectState}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      menu: (base) => ({
                        ...base,
                      // position: 'relative',
                      }),
                      control: (base: any) => ({
                        ...base,
                        '&:hover': { borderColor: '#880000' },
                        border: '1px solid #DDE0E4',
                        minHeight: '40px',
                        borderRadius: '5px',
                        padding: 1,
                        fontSize: '15px',
                        fontWeight: 400,
                        color: '#575656',
                        boxShadow: 'none',
                        backgroundColor: '#fff',
                        '&:focus': {
                          borderColor: '#E1EDF8',
                          boxShadow: 'none',
                          color: '#575656',
                          background: '#fff',
                        },
                      }),
                      option: (provided: any, state: { isSelected: any; }) => ({
                        ...provided,
                        color: state.isSelected ? '#383838' : '#212121',
                        padding: 15,
                        textAlign: 'left',
                        background: state.isSelected ? '#FAFCFB' : '#fff',
                        '&:hover': {
                          background: '#FAFCFB',
                        },
                      }),
                    }}
                    isLoading={loaderState}
                    noOptionsMessage={() => 'No State Found'}
                    placeholder="Select State"
                  />
                </div>
              </Col>
              <Col lg="3">
                <div className="pt-1">
                  <h6 className="input-header-text">From Date<span className="mandate-star">*</span></h6>
                  <Input
                    max={today}
                    value={inputDate}
                    type="date"
                    onChange={handleFieldChangeDate}
                    className="acps-input-ref"
                  />
                  <h6 className="print-sadmin-text-lite-white mx-1 my-1">Date</h6>
                </div>
              </Col>
              <Col lg="3">
                <div className="pt-1">
                  <h6 className="input-header-text">To Date<span className="mandate-star">*</span></h6>
                  <Input
                    max={today}
                    value={toDate}
                    type="date"
                    onChange={handleToChangeDate}
                    className="acps-input-ref"
                  />
                  <h6 className="print-sadmin-text-lite-white mx-1 my-1">Date</h6>
                </div>
              </Col>
              <Col lg="12">
                <div className="align-end my-2">
                  <Button onClick={getAllPrintableFile} disabled={inputDate === '' || stateData === ''} className="yocard-button-oval">Search</Button>
                </div>
              </Col>
            </Row>
          </div>
          <div className="pt-3 table-responsive">
            {totalTCnt === 0 ? (
              <div className="pt-5 align-center">
                <span className="form-collapse-card-header">No Record Found</span>
              </div>
            )
              : (
                <div>
                  <Row>
                    <Col lg="7">
                      <span className="print-sadmin-text-lite-radio">Total Files: {totalTCnt}</span>
                    </Col>
                  </Row>
                  <table className="styled-table">
                    <thead>
                      <tr>
                        <th>Folder</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Card Count</th>
                        <th>Printer</th>
                        <th>Update Count</th>
                        <th>Last Updated</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dbSubFolder.printDet.length && dbSubFolder.printDet.map((data, rowIndex) => (
                        <tr key={`file-${rowIndex.toString()}`}>
                          <td>
                            <div>
                              <FontAwesomeIcon icon={faFolder} className="fa-file-alt mx-1" />
                              {data.PrntTp}
                            </div>
                            <div className="ml-1">
                              <span className="text-lite-grey">{data.distDet.distNme}({data.distDet.stCd})</span>
                            </div>
                          </td>
                          <td>
                            <span className="td-text">{data.PrntId}</span>
                          </td>
                          <td>
                            <span className="td-text">{data.cStsV}</span>
                          </td>
                          <td><span className="td-text">{data.tCnt}</span></td>
                          <td>
                            <span className="td-text">
                              {data.prntUsrCd.fNme} {' '}{data.prntUsrCd.lNme}
                              <Button className="button-icon" id={`tooltip-${rowIndex}`}>
                                <FontAwesomeIcon className="info-stadm-icon my-1" icon={faCircleInfo} />
                              </Button>
                              <Tooltip
                                placement="top"
                                isOpen={tooltipOpen[`tooltip-${rowIndex}`] || false}
                                target={`tooltip-${rowIndex}`}
                                toggle={() => toggle(`tooltip-${rowIndex}`)}
                              >
                                <div><h6>{data.prntUsrCd.fNme} {' '}{data.prntUsrCd.lNme}</h6></div>
                                <div><span>{data.prntUsrCd.eId}</span></div>
                                <div><span>{data.prntUsrCd.ph}</span></div>
                              </Tooltip>
                            </span>
                          </td>
                          <td><span className="collapse-header">{data.updtCnt}</span></td>
                          <td><span className="collapse-header">{data.updtOn}</span></td>
                          <td>
                            <div className="align-center">
                              <Button id={`makePrintReady-${rowIndex}`} className="button-icon" onClick={() => handleCreatePrintReady(data.PrntId, data.PrntTp)}>
                                <FontAwesomeIcon icon={faFileArrowDown} className="mx-1 button-icon-small-pdf" />
                              </Button>
                              <UncontrolledTooltip delay={0} target={`makePrintReady-${rowIndex}`}>
                                Download PDF
                              </UncontrolledTooltip>
                              <Button id={`printZip-${rowIndex}`} className="button-icon mx-1" onClick={() => generateDownloadUrl(data.PrntId, data.PrntTp)}>
                                <FontAwesomeIcon className="collapse-icon" icon={faDownload} />
                              </Button>
                              <UncontrolledTooltip delay={0} target={`printZip-${rowIndex}`}>
                                Download Output Zip
                              </UncontrolledTooltip>
                              <Button className="button-icon mx-1" onClick={() => printToggleModal(`printDet-${rowIndex}`, data)}>
                                <FontAwesomeIcon className="collapse-icon mx-1" icon={faEdit} />
                              </Button>
                              <Modal
                                isOpen={openModalPrint[`printDet-${rowIndex}`] || false}
                                target={`printDet-${rowIndex}`}
                                className="pt-5"
                              >
                                <ModalHeader toggle={() => printToggleModal(`printDet-${rowIndex}`, data)} className="form-card-border-none mx-3 my-2" />
                                <div className="ml-1">
                                  <span className="text-lite-grey">Date: {data.PrntId}, District:{data.PrntTp}</span>
                                </div>
                                <ModalBody className="padding-zero my-3 mx-3">
                                  <Row>
                                    <Col lg="12">
                                      <div className="pt-1">
                                        <h6 className="decline-zip">Card Total Costing</h6>
                                      </div>
                                    </Col>
                                    <Col lg="7">
                                      <div className="pt-1">
                                        <h6 className="input-header-text">Card Cost(Paise / 1Rs=100Paise)</h6>
                                        <Input
                                          type="number"
                                          name="costCrd"
                                          value={billingInput.costCrd}
                                          onChange={handleFieldChangeBilling}
                                          className="yocard-input-ref"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="5">
                                      <div className="pt-1">
                                        <h6 className="input-header-text">GST(%)</h6>
                                        <Input
                                          type="number"
                                          name="gstCrd"
                                          value={billingInput.gstCrd}
                                          onChange={handleFieldChangeBilling}
                                          className="yocard-input-ref"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="12">
                                      <div className="pt-4">
                                        <h6 className="decline-zip">Printing Costing</h6>
                                      </div>
                                    </Col>
                                    <Col lg="7">
                                      <div className="pt-1">
                                        <h6 className="input-header-text">Cost(Paise / 1Rs=100Paise)</h6>
                                        <Input
                                          type="number"
                                          name="costPrint"
                                          value={billingInput.costPrint}
                                          onChange={handleFieldChangeBilling}
                                          className="yocard-input-ref"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="5">
                                      <div className="pt-1">
                                        <h6 className="input-header-text">GST(%)</h6>
                                        <Input
                                          type="number"
                                          name="gstPrint"
                                          value={billingInput.gstPrint}
                                          onChange={handleFieldChangeBilling}
                                          className="yocard-input-ref"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="12">
                                      <div className="pt-4">
                                        <h6 className="decline-zip">Vendor 1 Costing</h6>
                                      </div>
                                    </Col>
                                    <Col lg="7">
                                      <div className="pt-1">
                                        <h6 className="input-header-text">Cost(Paise / 1Rs=100Paise)</h6>
                                        <Input
                                          type="number"
                                          name="costVendor1"
                                          value={billingInput.costVendor1}
                                          onChange={handleFieldChangeBilling}
                                          className="yocard-input-ref"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="5">
                                      <div className="pt-1">
                                        <h6 className="input-header-text">GST(Vendor 1 Cost)</h6>
                                        <Input
                                          type="number"
                                          name="gstVendor1"
                                          value={billingInput.gstVendor1}
                                          onChange={handleFieldChangeBilling}
                                          className="yocard-input-ref"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="12">
                                      <div className="pt-4">
                                        <h6 className="decline-zip">Courier and Card Count</h6>
                                      </div>
                                    </Col>
                                    <Col lg="6">
                                      <div className="pt-2">
                                        <h6 className="input-header-text">Consignment</h6>
                                        <Select
                                          value={selectedCourTypeOptions}
                                          options={consgTpOptions}
                                          isClearable
                                          onChange={handleSelectCourTypeData}
                                          menuPortalTarget={document.body}
                                          styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            menu: (base) => ({
                                              ...base,
                                              // position: 'absolute',
                                              // overflow: 'visible',
                                            }),
                                            control: (base: any) => ({
                                              ...base,
                                              '&:hover': { borderColor: '#880000' },
                                              border: '1px solid #DDE0E4',
                                              width: '100%',
                                              borderRadius: '5px',
                                              fontSize: '15px',
                                              fontWeight: 400,
                                              color: '#575656',
                                              boxShadow: 'none',
                                              backgroundColor: '#fff',
                                              '&:focus': {
                                                borderColor: '#E1EDF8',
                                                boxShadow: 'none',
                                                color: '#575656',
                                                background: '#fff',
                                              },
                                            }),
                                            option: (provided: any, state: { isSelected: any; }) => ({
                                              ...provided,
                                              color: state.isSelected ? '#383838' : '#212121',
                                              padding: 10,
                                              textAlign: 'left',
                                              background: state.isSelected ? '#FAFCFB' : '#fff',
                                              '&:hover': {
                                                background: '#FAFCFB',
                                              },
                                            }),
                                          }}
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="6">
                                      <div className="pt-2">
                                        <h6 className="input-header-text">Consignment No.#</h6>
                                        <Input
                                          type="text"
                                          name="courId"
                                          value={billingInput.courId}
                                          onChange={handleFieldChangeBilling}
                                          className="yocard-input-ref"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="6">
                                      <div className="pt-2">
                                        <h6 className="input-header-text">Misc Cost(Rs/-)</h6>
                                        <Input
                                          type="number"
                                          name="miscCst"
                                          value={billingInput.miscCst}
                                          onChange={handleFieldChangeBilling}
                                          className="yocard-input-ref"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="6">
                                      <div className="pt-2">
                                        <h6 className="input-header-text">Card Count</h6>
                                        <Input
                                          type="number"
                                          name="tCnt"
                                          value={billingInput.tCnt}
                                          onChange={handleFieldChangeBilling}
                                          className="yocard-input-ref"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="12">
                                      <div className="pt-4">
                                        <h6 className="decline-zip">Sender Details</h6>
                                      </div>
                                    </Col>
                                    <Col lg="12">
                                      <div className="pt-1">
                                        <h6 className="input-header-text">Sender Name</h6>
                                        <Input
                                          type="text"
                                          name="sndrNme"
                                          value={billingInput.sndrNme}
                                          onChange={handleFieldChangeBilling}
                                          className="yocard-input-ref"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="12">
                                      <div className="pt-3">
                                        <h6 className="input-header-text">Any Comment</h6>
                                        <Input
                                          type="text"
                                          name="aCmnt"
                                          value={billingInput.aCmnt}
                                          onChange={handleFieldChangeBilling}
                                          className="yocard-text-area"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <div className="align-center my-1 pt-3">
                                    <Button className="yocard-button-oval" onClick={() => updatePrintData(`printDet-${rowIndex}`, data.PrntTp, data.PrntId)}>Submit</Button>
                                  </div>
                                </ModalBody>
                              </Modal>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) }
          </div>
        </Col>
        {/* <Col lg={{ size: 4, order: 2 }} xs={{ order: 1 }}>
          <div className="print-sadmin-graph-card-box my-2">
            <div className="pt-1 align-start">
              <Input
                max={today}
                value={inputDate}
                type="date"
                name="refDt"
                onChange={handleFieldChangeDate}
                className="yocard-input-ref"
              />
              <Button onClick={getCardStatusDetails} disabled={inputDate === ''} className="yocard-button-oval mx-2 my-1">Search</Button>
            </div>
            {cardStatus && cardStatus.data.length === 0
              ? (
                <div className="pt-2 align-center my-5">
                  <span className="form-collapse-card-header">No Record Found</span>
                </div>
              )
              : (
                <div className="pt-3">
                  <div className="align-end mx-2 my-1">
                    <span className="print-sadmin-text-lite-radio">Total Files: {cardStatus.tCnt}</span>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th style={{ backgroundColor: '#f6eeeb' }}><span className="dash-all-header-text">State</span></th>
                        <th style={{ backgroundColor: '#f6eeeb' }}><span className="dash-all-header-text">Total Cards</span></th>
                        <th style={{ backgroundColor: '#f6eeeb' }}><span className="dash-all-header-text">Current Status</span></th>
                      </tr>
                    </thead>
                    <tbody>
                      {cardStatus && cardStatus.data.map((cards, index) => (
                        <tr key={`file-${index.toString()}`} style={{ backgroundColor: '#FAFCFB' }}>
                          <td data-name="st" style={{ border: '1px dashed #D4F9F7' }} className="filename">
                            <span className="sadmincard-dashboard-header">{cards.st}</span>
                          </td>
                          <td style={{ border: '1px dashed #D4F9F7' }} className="filename">
                            <span className="sadmincard-dashboard-header">{cards.cnt}</span>
                          </td>
                          <td style={{ border: '1px dashed #D4F9F7' }} className="action">
                            <span className="sadmincard-dashboard-header">{cards.currentSts}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
          </div>
        </Col> */}
      </Row>
    </div>
  );
};

export default GeneratePrintFile;

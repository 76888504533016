import { CardsStateWiseResponse, CardsStateWiseResults } from './card-state-wise.service.types';

export const CardsStateWiseTransform: (response: CardsStateWiseResponse) => CardsStateWiseResults = (cardsStateWiseResults) => {
  const { data, is_error, message } = cardsStateWiseResults;
  const result: CardsStateWiseResults = {
    data: {
      tCnt: 0,
      data: [
        {
          cnt: 0,
          st: '',
          currentSts: '',
        },
      ],
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data.tCnt = data.tCnt ?? 0;
    result.data.data = data.data ?? [];
    result.message = 'executed';
  }
  return result;
};

import { ListCityResponse, ListCityResults } from './list-city.service.types';

export const ListCityTransform: (response: ListCityResponse) => ListCityResults = (listCityResults) => {
  const { data, is_error } = listCityResults;
  const result: ListCityResults = {
    data: [{
      cty: '',
      st: '',
      cntCd: '',
      cnt: '',
    }],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
  } else {
    result.data = data;
    result.message = 'executed';
  }
  return result;
};

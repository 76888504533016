import React, { useState } from 'react';
import Chart, {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import './stadmin.scss';
import {
  Card,
  Col, Row,
} from 'reactstrap';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { errorAlert } from '../../../utils/alert';
import LoaderData from '../../../utils/loader';
import { getTokenFromLocalStorage, getUserTpSessionStorage } from '../../../utils/service/localstorage-service';
import {
  clearCardDashboard, clearCardTrendStatus, getCardDashboard, getCardTrendStatus,
} from '../../../store/yoCard/actions';
import { APIHeader } from '../../../utils/constants';
import { OperatorStatus, StatusCountData } from '../../../services/reports-all/get-cards-dashboard/card-dashboard.types';
import { CardTrendResult } from '../../../services/reports-all/get-trends-status/card-trend.types';
import LineChartComponent from './line-chart';
import STAdminTable from './stadmin-card-table';
// import { CardTrendResult } from '../../../services/reports-all/get-trends-status/card-trend.types';
// import LineChartComponent from './line-chart';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  // chartTrendline,
);

export const StateAdminDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const userTp = getUserTpSessionStorage();
  const tokenData = getTokenFromLocalStorage();
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const today = new Date().toISOString().split('T')[0];
  const init_today = new Date();
  init_today.setDate(init_today.getDate() - 7);
  const lastSeventhDay = init_today.toISOString().split('T')[0];
  const [loaderDashboard, setLoaderDashboard] = useState(false);
  const getCardsDashboardResponse = useSelector((state: RootState) => state.yoCard.getCardDashboard);
  const getCardsTrendStatusResponse = useSelector((state: RootState) => state.yoCard.getCardTrendStatus);
  const chartRef = React.useRef<HTMLCanvasElement | null>(null);
  const chartCardRef = React.useRef<HTMLCanvasElement | null>(null);
  const chartInstance = React.useRef<Chart | null>(null);
  const [dashboardStatus, setDashboardStatus] = useState<OperatorStatus>({
    tCnt: 0,
    fCnt: 0,
    agntData: [
      {
        ph: '',
        UserCd: '',
        lNme: '',
        fNme: '',
        eId: '',
        tCnt: 0,
        dTCnt: 0,
      },
    ],
  });
  // const dataArrayDough = [300000, 450000, 15000];
  const order: (keyof StatusCountData)[] = ['progCnt', 'compCnt'];
  const [dataArrayDough, setDataArrayDough] = useState<number[]>([]);
  const [labels, setUserLabel] = useState<string[]>([]);
  const [data, setData] = useState<number[]>([]);
  const [trendStatus, setTrendStatus] = useState<CardTrendResult[]>([]);
  // const [lineLabelDet, setLineLabelDet] = useState<string[]>([]);
  // const [dataLineDet, setDataLineDet] = useState<number[]>([]);
  // const [statusDet, setStatusDet] = useState<CardTrendResult>();
  // const [stsInfo, setStsInfo] = useState<string[]>([]);
  // const stsInfo = [{ dt: '2023-11-10', sts: 'Card Uploaded to Server' }, { dt: '2023-11-09', sts: 'Card Courired' }, { dt: '2023-11-08', sts: 'Card Uploaded to Server' }, { dt: '2023-11-07', sts: 'Card Processing' }, { dt: '2023-11-06', sts: 'Card in Printing' }, { dt: '2023-11-05', sts: 'Card Uploaded to Server' }, { dt: '2023-11-04', sts: 'Card Uploaded to Server' }];

  React.useEffect(() => {
    if (userTp === 'STADM') {
      setLoaderDashboard(true);
      dispatch(getCardDashboard({
        userType: userTp,
        requestType: APIHeader.REQ_REPORT_DASHBOARD,
        crdDt: today,
        token: tokenData,
      }));
      dispatch(getCardTrendStatus({
        userType: userTp,
        requestType: APIHeader.REQ_REPORT_DASHBOARD_TREND_STS,
        sDt: lastSeventhDay,
        eDt: today,
        stsTrnd: 'TREND',
        token: tokenData,
      }));
      // dispatch(getCardTrendStatus({
      //   userType: userTp,
      //   requestType: APIHeader.REQ_REPORT_DASHBOARD_TREND_STS,
      //   sDt: lastSeventhDay,
      //   eDt: today,
      //   stsTrnd: 'STATUS',
      //   token: tokenData,
      // }));
    }
  }, [userTp]);

  React.useEffect(() => {
    if (getCardsDashboardResponse.isError && getCardsDashboardResponse.message !== '') {
      setLoaderDashboard(false);
      setErrorShowAlert(true);
      setAlertMsg(getCardsDashboardResponse.message);
      dispatch(clearCardDashboard());
    }
  }, [getCardsDashboardResponse.isError, getCardsDashboardResponse.message]);

  React.useEffect(() => {
    if (!getCardsDashboardResponse.isError && getCardsDashboardResponse.message === 'executed') {
      setLoaderDashboard(false);
      setUserLabel(getCardsDashboardResponse.data.oprSts.agntData.map((agent) => `${agent.fNme} ${agent.lNme}`));
      setData(getCardsDashboardResponse.data.oprSts.agntData.map((agent) => agent.dTCnt));
      setDataArrayDough(order.map((property) => getCardsDashboardResponse.data.stsCnt[property]));
      setDashboardStatus(getCardsDashboardResponse.data.oprSts);
      dispatch(clearCardDashboard());
    }
  }, [getCardsDashboardResponse.isError, getCardsDashboardResponse.message]);

  React.useEffect(() => {
    if (getCardsTrendStatusResponse.isError && getCardsTrendStatusResponse.message !== '') {
      setLoaderDashboard(false);
      setErrorShowAlert(true);
      setAlertMsg(getCardsTrendStatusResponse.message);
      dispatch(clearCardTrendStatus());
    }
  }, [getCardsTrendStatusResponse.isError, getCardsTrendStatusResponse.message]);

  React.useEffect(() => {
    if (!getCardsTrendStatusResponse.isError && getCardsTrendStatusResponse.message === 'executed') {
      setLoaderDashboard(false);
      setTrendStatus(getCardsTrendStatusResponse.data);
      dispatch(clearCardTrendStatus());
    }
  }, [getCardsTrendStatusResponse.isError, getCardsTrendStatusResponse.message]);

  // React.useEffect(() => {
  //   // Initial fetch
  //   const fetchData = async () => {
  //     if (!getCardsTrendStatusResponse.isError && getCardsTrendStatusResponse.message === 'executed') {
  //       const newData = getCardsTrendStatusResponse.data;

  //       if (newData && newData[0] && Object.hasOwnProperty.call(newData[0], 'tCnt')) {
  //         const labelsTCnt = newData.map((item) => item.dt);
  //         const dataTCnt = newData.map((item) => item.tCnt);
  //         const filteredDataTCnt = dataTCnt.filter((value): value is number => value !== undefined);
  //         setLineLabelDet(labelsTCnt);
  //         setDataLineDet(filteredDataTCnt);
  //       }

  //       if (newData && newData[0] && Object.hasOwnProperty.call(newData[0], 'cSTS')) {
  //         setStatusDet(newData);
  //       }

  //       setLoaderDashboard(false);
  //       dispatch(clearCardTrendStatus());
  //     }
  //   };

  //   fetchData();
  // }, [getCardsTrendStatusResponse.isError, getCardsTrendStatusResponse.message, statusDet, lineLabelDet, dataLineDet]);

  // React.useEffect(() => {
  //   if (!getCardsTrendStatusResponse.isError && getCardsTrendStatusResponse.message === 'executed') {
  //     const newData = getCardsTrendStatusResponse.data;
  //     // Check if the response has 'tCnt' key
  //     if (newData && newData[0] && Object.hasOwnProperty.call(newData[0], 'tCnt')) {
  //       const labelsTCnt = newData.map((item) => item.dt);
  //       const dataTCnt = newData.map((item) => item.tCnt);
  //       const filteredDataTCnt = dataTCnt.filter((value): value is number => value !== undefined);
  //       setLineLabelDet(labelsTCnt);
  //       setDataLineDet(filteredDataTCnt);
  //     }
  //     if (newData && newData[0] && Object.hasOwnProperty.call(newData[0], 'cSTS')) {
  //       setStatusDet(newData);
  //     }
  //     setLoaderDashboard(false);
  //     dispatch(clearCardTrendStatus());
  //   }
  // }, [getCardsTrendStatusResponse.isError, getCardsTrendStatusResponse.message, statusDet]);

  const dataDough = {
    labels: ['In-Progress', 'Completed'],
    datasets: [
      {
        data: dataArrayDough,
        backgroundColor: ['#9366d2', '#11404D'],
        hoverBackgroundColor: ['#9366d2', '#11404D'],
      },
    ],
  };
  const optionsDough = {
    plugins: {
      color: '#FAFAFA',
      tooltip: {
        mode: 'index' as any,
        intersect: true,
        backgroundColor: '#383838 ',
        borderColor: '#959191',
        borderWidth: 1,
        titleFont: {
          size: 13,
          weight: 'bold',
        },
        bodyFont: {
          size: 13,
        },
        callbacks: {
          title: (context: any[]) => {
            if (context.length > 0) {
              const { dataIndex } = context[0];
              return dataDough.labels[dataIndex];
            }
            return '';
          },
          label: (context: any) => {
            const label = context.dataset.label || '';
            const value = context.parsed || 0;
            return `${label}: ${value}`;
          },
        },
      },
    },
  };

  React.useEffect(() => {
    if (chartRef.current) {
      if (!chartInstance.current) {
        const ctx = chartRef.current.getContext('2d');
        if (ctx) {
          chartInstance.current = new Chart(ctx, {
            type: 'bar',
            data: {
              labels,
              datasets: [
                {
                  label: 'Cards Generated Today',
                  data,
                  backgroundColor: '#11407e',
                  borderColor: '#ffffff',
                  borderWidth: 0,
                },
              ],
            },
            options: {
              indexAxis: 'y',
              scales: {
                x: {
                  beginAtZero: true,
                },
              },
            },
          });
        }
      } else {
        // Update chart data if it already exists
        chartInstance.current.data.labels = labels;
        chartInstance.current.data.datasets[0].data = data;
        chartInstance.current.update();
      }
    }
  }, [data, labels]);

  React.useEffect(() => {
    if (chartCardRef.current) {
      if (!chartInstance.current) {
        const ctx = chartCardRef.current.getContext('2d');
        if (ctx) {
          chartInstance.current = new Chart(ctx, {
            type: 'bar',
            data: {
              labels,
              datasets: [
                {
                  label: 'Cards Generated Today',
                  data,
                  backgroundColor: '#11407e',
                  borderColor: '#ffffff',
                  borderWidth: 0,
                },
              ],
            },
            options: {
              indexAxis: 'y',
              scales: {
                x: {
                  beginAtZero: true,
                },
              },
            },
          });
        }
      } else {
        // Update chart data if it already exists
        chartInstance.current.data.labels = labels;
        chartInstance.current.data.datasets[0].data = data;
        chartInstance.current.update();
      }
    }
  }, [data, labels]);

  // const dataLine = {
  //   labels: lineLabelDet, // ['2013-11-05', '2013-11-06', '2013-11-07', '2013-11-08', '2013-11-09', '2013-11-10', '2013-11-11'],
  //   datasets: [
  //     {
  //       label: 'Activity Trend',
  //       data: dataLineDet, // [2000, 5000, 1500, 6000, 8000, 8692, 1309],
  //       backgroundColor: 'white',
  //       borderWidth: 2,
  //       borderColor: '#344675',
  //       trendlineLinear: {
  //         colorMin: 'gray',
  //         colorMax: 'gray',
  //         lineStyle: 'solid',
  //         width: 2,
  //         projection: false,
  //       },
  //     },
  //   ],
  // };
  // const optionsCard = {
  //   plugins: {
  //     color: '#7c43cb',
  //     tooltip: {
  //       mode: 'index' as any,
  //       intersect: false,
  //       backgroundColor: '#344675',
  //       borderColor: '#344675',
  //       borderWidth: 1,
  //       titleFont: {
  //         size: 13,
  //         weight: 'normal',
  //       },
  //       bodyFont: {
  //         size: 13,
  //       },
  //       callbacks: {
  //         title: (context: any[]) => {
  //           if (context.length > 0) {
  //             const { dataIndex } = context[0];
  //             return dataLine.labels[dataIndex];
  //           }
  //           return '';
  //         },
  //         label: (context: any) => {
  //           const label = context.dataset.label || '';
  //           const value = context.parsed.y || 0;
  //           return `${label}: ${value}`;
  //         },
  //       },
  //     },
  //   },
  // };

  return (
    <div className="border-tabs mr">
      { loaderDashboard ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="mb-2">
        <span className="yocard-header-with-bg">
          Quick View - Dashboard
        </span>
      </div>
      <Row className="pt-5">
        <Col lg="6" xs="12">
          <div className="align-center1">
            <canvas ref={chartRef} />
          </div>
          <div className="pt-2 align-center">
            <span className="yocard-label-bg">Operator wise Card data</span>
          </div>
        </Col>
        <Col lg="6" xs="12">
          <div className="pt-2 stadmin-margin-top-bottom stadmin-adjust-doughnut align-center">
            <Doughnut data={dataDough} options={optionsDough} />
          </div>
          <div className="pt-3 align-center">
            <span className="yocard-label-bg">Card Status</span>
          </div>
        </Col>
      </Row>
      <Row className="align-center pt-5">
        <Col lg="11">
          {trendStatus && trendStatus.length > 0
            ? <LineChartComponent data={trendStatus} />
            : (
              <div className="pt-2 align-center my-5">
                <span className="form-collapse-card-header">No Record Found</span>
              </div>
            )}
        </Col>
        <div className="pt-3 align-center">
          <span className="yocard-label-bg">Progress count District wise</span>
        </div>
      </Row>
      {/* <Row className="mx-2 pt-4">
        <Col lg="6">
          <Card className="form-card">
            <div className="mx-2 my-2">
              <Line data={dataLine} options={optionsCard} />
            </div>
          </Card>
          <div className="pt-2 align-center">
            <span className="yocard-label-bg">Last 7 Days Trend</span>
          </div>
        </Col>
        <Col lg="12">
          <Row className="align-center form-card1">
            {statusDet && statusDet.statusData.length > 0
              ? <LineChartComponent data={statusDet} />
              : (
                <div className="pt-2 align-center my-5">
                  <span className="form-collapse-card-header">No Record Found</span>
                </div>
              )}
            {/* {statusDet && statusDet.map((stsData, index) => (
              <Col lg="6" key={`file-${index.toString()}`} className="pt-3">
                <ul className="ul-status">
                  <li className="accent-color1 li-status">
                    <div className="date">{stsData.dt}</div>
                    <div className="title-status">{stsData.cSTS}</div>
                  </li>
                </ul>
              </Col>
            ))}
          </Row>
          <div className="pt-2 align-center">
            <span className="yocard-label-bg">Last 7 Days Card Status</span>
          </div>
        </Col>
      </Row> */}
      <Row className="pt-5 mx-2">
        <div
          style={{
            backgroundColor: '#f6eeeb', width: '100%', padding: '3px', marginTop: '10px',
          }}
          className="my-3"
        >
          <span className="mx-2">District and Date wise Count</span>
        </div>
        <Col lg="12">
          <STAdminTable data={trendStatus} />
        </Col>
      </Row>
      <Row className="pt-5 mx-2">
        <div
          style={{
            backgroundColor: '#f6eeeb', width: '100%', padding: '3px', marginTop: '10px',
          }}
          className="my-3"
        >
          <span className="mx-2">Card Information user-wise</span>
        </div>
        <div className="pt-3">
          <span className="text-lite-grey">Total Operators: {dashboardStatus.tCnt}</span>
        </div>
        {dashboardStatus && dashboardStatus.agntData.map((userData, index) => (
          <Col lg="11" key={`file-${index.toString()}`}>
            <div className="pt-1">
              <Card className="form-card my-2">
                <Row className="my-2">
                  <Col lg="6">
                    <div className="align-start">
                      <div className="ml-1 pt-1">
                        <div className="align-start">
                          <span className="super-user-name">{userData.fNme}{' '}{userData.lNme}</span>
                          <span className="text-lite-grey mx-2 pt-1">|</span>
                          <span className="input-checkbox-text-grey">
                            +91 {userData.ph}
                          </span>
                        </div>
                        <div className="align-start">
                          <span className="input-checkbox-text-grey">
                            <FontAwesomeIcon icon={faEnvelope} className="text-lite-grey mr" />
                            {userData.eId}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg="3" xs="6">
                    <div className="pt-1">
                      <div className="align-center">
                        <span className="dash-all-header-text">{userData.dTCnt}</span>
                      </div>
                      <div className="align-center">
                        <span className="text-lite-grey">Today Uploads</span>
                      </div>
                    </div>
                  </Col>
                  <Col lg="3" xs="6">
                    <div className="pt-1">
                      <div className="align-center">
                        <span className="dash-all-header-text">{userData.tCnt}</span>
                      </div>
                      <div className="align-center">
                        <span className="text-lite-grey">Total Uploads</span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </div>
          </Col>
        ))}
      </Row>
      <div className="mb-3" />
    </div>
  );
};

export default StateAdminDashboard;

import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row, Col, CardBody, CardFooter, Card, Input, Button, InputGroup, InputGroupText, NavLink, Form,
} from 'reactstrap';
import './login.scss';
import { RootState } from 'typesafe-actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import React, { useState } from 'react';
import { APIHeader, UserType } from '../../../utils/constants';
import { errorAlert, successAlert } from '../../../utils/alert';
import { LoaderData } from '../../../utils/loader';
import { setTokenToLocalStorage, setUserProfileSessionStorage, setUserTpSessionStorage } from '../../../utils/service/localstorage-service';
import { getLoginToken, getForgotPassword, getProfile } from '../../../store/yoCard/actions';
// import { LoaderData } from '../../../utils/loader';

export const LoginSAdmin: React.FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isPassword, setIsPassword] = React.useState(true);
  const [isLoader, setIsLoader] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [loginPassKey, setLoginPassKey] = useState('');
  const [loginUserName, setLoginUserName] = useState('');
  const [forgotUserName, setForgotUserName] = useState('');
  const loginTokenData = useSelector((state: RootState) => state.yoCard.genToken);
  const loginProfileData = useSelector((state: RootState) => state.yoCard.profileData);
  const forgotPasswordData = useSelector((state: RootState) => state.yoCard.forgotPassKeyData);

  const handleForgotPassword = () => {
    setIsForgotPassword(true);
    setIsPassword(false);
  };
  const handleSwitchToPassword = () => {
    setIsForgotPassword(false);
    setIsPassword(true);
  };
  const handlePassKeyChange = (e: any) => {
    setLoginPassKey(e.target.value);
  };
  const handleUserNameChange = (e: any) => {
    setLoginUserName(e.target.value);
  };
  const handleClickShowPassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleForgotUserNameChange = (e: any) => {
    setForgotUserName(e.target.value);
  };

  const handlePasswordSubmit = async (e:any) => {
    e.preventDefault();
    if (loginUserName !== '' && loginPassKey !== '') {
      setIsLoader(true);
      dispatch(getLoginToken({
        userName: loginUserName,
        passKey: loginPassKey,
        isPublic: false,
        userType: UserType.SADM,
        requestType: APIHeader.USER_LOGIN,
      }));
    }
  };
  React.useEffect(() => {
    if (loginTokenData.isError && loginTokenData.message !== '') {
      setIsLoader(false);
      setErrorShowAlert(true);
      setAlertMsg(loginTokenData.message);
      setLoginUserName('');
      setLoginPassKey('');
    }
  }, [loginTokenData.message, loginTokenData.isError]);

  const handleForgotPasskeySubmit = (e:any) => {
    e.preventDefault();
    setIsLoader(!isLoader);
    dispatch(getForgotPassword({
      userName: forgotUserName,
      userType: UserType.SADM,
      requestType: APIHeader.REQ_PUB_FORGOT_PASSWORD,
    }));
  };

  React.useEffect(() => {
    if (!loginTokenData.isError && loginTokenData.token !== '') {
      setIsLoader(false);
      setTokenToLocalStorage(loginTokenData.token);
      setUserTpSessionStorage('SADM');
      dispatch(getProfile({
        userReqFor: UserType.SADM,
        requestType: APIHeader.REQ_USER_PROFILE_INFO,
        token: loginTokenData.token,
      }));
    }
  }, [loginTokenData.token, loginTokenData.isError]);

  React.useEffect(() => {
    if (loginProfileData.isError && loginProfileData.message !== '') {
      setIsLoader(false);
      setErrorShowAlert(true);
      setAlertMsg(loginProfileData.message);
      setLoginUserName('');
      setLoginPassKey('');
    }
  }, [loginProfileData.message, loginProfileData.isError]);

  React.useEffect(() => {
    if (!loginProfileData.isError && loginProfileData.message !== '' && loginProfileData.profileData.UserTp === 'SADM') {
      setIsLoader(false);
      setUserProfileSessionStorage(JSON.stringify(loginProfileData));
      history('/sadmin/dashboard');
    }
  }, [loginProfileData.message, loginTokenData.isError]);

  React.useEffect(() => {
    if (forgotPasswordData.isError && forgotPasswordData.message !== '') {
      setIsLoader(false);
      setErrorShowAlert(true);
      setAlertMsg(forgotPasswordData.message);
    }
  }, [forgotPasswordData.message, forgotPasswordData.isError]);

  React.useEffect(() => {
    if (!forgotPasswordData.isError && forgotPasswordData.forgotPasswordData !== '') {
      setIsLoader(false);
      setSuccessShowAlert(true);
      setAlertMsg(forgotPasswordData.forgotPasswordData);
    }
  }, [forgotPasswordData.isError, forgotPasswordData.forgotPasswordData]);

  return (
    <div className="login-align-vertical1 mx-1 pt-2">
      { isLoader ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(true, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      <div className="image-top-left" />
      <div className="image-bottom-right" />
      <Card className="login-card">
        <CardBody>
          <Row className="pt-2">
            <Col lg={{ size: 5, order: 2 }} md={{ size: 5, order: 2 }} xs={{ order: 1 }} className="vertical-align">
              <div className="align-center pt-5 mx-3">
                <span className="sign-up-header">
                  {isForgotPassword ? 'YoCard Admin - Reset Password' : 'YoCard Admin Login'}
                </span>
              </div>
              {isPassword
                ? (
                  <div className="pt-5">
                    <Form onSubmit={handlePasswordSubmit}>
                      <div className="text-right-login-yocard mx-1">
                        <h6 className="input-header-text">UserInfo<span className="mandate-star">*</span></h6>
                        <Input
                          placeholder="Email Id / Username"
                          type="text"
                          required
                          className="yocard-input mx-1"
                          value={loginUserName}
                          onChange={handleUserNameChange}
                        />
                      </div>
                      <div className="text-right-login-yocard mx-1 pt-4">
                        <h6 className="input-header-text">Password<span className="mandate-star">*</span></h6>
                        <InputGroup>
                          <Input
                            placeholder="Password"
                            type={passwordShown ? 'text' : 'password'}
                            required
                            value={loginPassKey}
                            onChange={handlePassKeyChange}
                            className="yocard-input mx-1"
                          />
                          <InputGroupText className="yocard-input cursor-pointer" onClick={handleClickShowPassword}>
                            {passwordShown
                              ? (<FontAwesomeIcon icon={faEye} className="pwd-eye-icon" />)
                              : (<FontAwesomeIcon icon={faEyeSlash} className="pwd-eye-close-icon" />
                              )}
                          </InputGroupText>
                        </InputGroup>
                      </div>
                      <div className="align-end pt-1">
                        <NavLink className="forgot-password-text cursor-pointer mx-3" to="#" onClick={handleForgotPassword}>Forgot password ?</NavLink>
                      </div>
                      <div className="text-right-login-yocard align-end pt-4">
                        <Button className="yocard-button-oval mx-1">
                          Login
                        </Button>
                      </div>
                    </Form>
                  </div>
                ) : null}
              {isForgotPassword
                ? (
                  <div className="pt-5">
                    <Form onSubmit={handleForgotPasskeySubmit}>
                      <div className="text-right-login-yocard mx-3">
                        <h6 className="input-header-text">UserInfo<span className="mandate-star">*</span></h6>
                        <Input
                          placeholder="Email Id / Username"
                          type="text"
                          required
                          value={forgotUserName}
                          onChange={handleForgotUserNameChange}
                          className="yocard-input mx-1"
                        />
                      </div>
                      <div className="align-end pt-2 mx-2">
                        <NavLink className="forgot-password-text cursor-pointer mx-1" to="#" onClick={handleSwitchToPassword}> Back to login ?</NavLink>
                      </div>
                      <div className="text-right-login-yocard align-end pt-3">
                        <Button className="yocard-button-oval mx-1" disabled={!(forgotUserName !== '')}>
                          Submit
                        </Button>
                      </div>
                    </Form>
                  </div>
                ) : null}
            </Col>
            <Col lg={{ size: 7, order: 1 }} md={{ size: 7, order: 1 }} xs={{ order: 2 }}>
              <div className="mx-4 align-center">
                <img style={{ height: 'auto', width: '330px' }} src="/images/common/login-admin.png" alt="yocardlLogo" />
              </div>
              <div className="align-center">
                <span className="text-about align-text-center">Seamless Access: Admin Login</span>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="login-card-header-footer">
          <div className="align-end">
            <span className="mx-1 already-text">Terms &amp; Use</span>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
export default LoginSAdmin;

import { PostRefFileResponse, PostRefFileResults } from './post-reffile.service.types';

export const PostRefFileTransform: (response: PostRefFileResponse) => PostRefFileResults = (createPostResults) => {
  const { data, is_error, message } = createPostResults;
  const result: PostRefFileResults = {
    message: '',
    error: false,
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.message = data.message;
  }
  return result;
};

import { ListAgntResponse, ListAgntResults } from './list-agent.service.types';

export const ListAgntTransform: (response: ListAgntResponse) => ListAgntResults = (listAgntResults) => {
  const { data, is_error } = listAgntResults;
  const result: ListAgntResults = {
    tCnt: 0,
    fCnt: 0,
    agntData: [],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = data.message;
  } else {
    result.tCnt = data.tCnt;
    result.fCnt = data.fCnt;
    result.agntData = data.agntData;
    result.message = 'success';
  }
  return result;
};

import React from 'react';
import { faFileZipper, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col, Nav, NavItem, NavLink, Row, TabContent, TabPane,
} from 'reactstrap';
import './print.scss';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import GeneratePrintFile from './generate-print-file';
import { PrintRefUploadPdf } from './print-upload-pdf';
import UploadPrintZip from './upload-zip';

export const PrintReadyExclusive: React.FC = () => {
  const [activeTab, setActiveTab] = React.useState('printReady');

  return (
    <div>
      <div className="mb-2 pt-3">
        <span className="yocard-header-with-bg">
          Quick View - Print Ready
        </span>
      </div>
      <Row className="pt-2">
        <Col xs="12">
          <Row className="m">
            <Col xs="12">
              <Nav tabs className="print-sadmin-tab">
                <div className="print-sadmin-row-tab">
                  <div className="print-sadmin-column-tab-left">
                    <NavItem className="cursor-pointer">
                      <NavLink className="print-sadmin-nav-tabs" active={activeTab === 'printReady'} onClick={() => setActiveTab('printReady')}>
                        <FontAwesomeIcon icon={faPrint} className="print-sadmin-tab-icon" />
                        <span className="print-sadmin-tab-text">Print Ready</span>
                      </NavLink>
                    </NavItem>
                  </div>
                  <div className="print-sadmin-column-tab-right">
                    <NavItem className="cursor-pointer">
                      <NavLink className="print-sadmin-nav-tabs" active={activeTab === 'zipUpload'} onClick={() => setActiveTab('zipUpload')}>
                        <FontAwesomeIcon icon={faFileZipper} className="print-sadmin-tab-icon" />
                        <span className="print-sadmin-tab-text">Upload Zip</span>
                      </NavLink>
                    </NavItem>
                  </div>
                  <div className="print-sadmin-column-tab-right">
                    <NavItem className="cursor-pointer">
                      <NavLink className="print-sadmin-nav-tabs" active={activeTab === 'uploadPdf'} onClick={() => setActiveTab('uploadPdf')}>
                        <FontAwesomeIcon icon={faFilePdf} className="print-sadmin-tab-icon" />
                        <span className="print-sadmin-tab-text">Upload Pdf</span>
                      </NavLink>
                    </NavItem>
                  </div>
                  {/* <div className="print-sadmin-column-tab-right">
                    <NavItem className="cursor-pointer">
                      <NavLink className="print-sadmin-nav-tabs" active={activeTab === 'refNo'} onClick={() => setActiveTab('refNo')}>
                        <FontAwesomeIcon icon={faIdCard} className="print-sadmin-tab-icon" />
                        <span className="print-sadmin-tab-text">Ref Generated</span>
                      </NavLink>
                    </NavItem>
                  </div> */}
                </div>
              </Nav>
            </Col>
            <Col xs="12">
              <TabContent activeTab={activeTab}>
                {/* <TabPane tabId="refNo">
                  <PrintRefGenerated activeTab={activeTab} />
                </TabPane> */}
                <TabPane tabId="uploadPdf">
                  <PrintRefUploadPdf activeTab={activeTab} />
                </TabPane>
                <TabPane tabId="printReady">
                  <GeneratePrintFile activeTab={activeTab} />
                </TabPane>
                <TabPane tabId="zipUpload">
                  <UploadPrintZip activeTab={activeTab} />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default PrintReadyExclusive;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, Input, Row,
} from 'reactstrap';
import { PassKeyPutDataInput } from '../../../../services/update-passkey/update-passkey.types';
import { getTokenFromLocalStorage, setUserProfileSessionStorage } from '../../../../utils/service/localstorage-service';
import { successAlert, errorAlert } from '../../../../utils/alert';
import LoaderData from '../../../../utils/loader';
import { APIHeader } from '../../../../utils/constants';
import { ResetPwdProps } from './reset-passkey.type';
import {
  passKeyReq, clearPassKeyReq, clearProfileReq, getProfile,
} from '../../../../store/yoCard/actions';

export const ModalPasskeyUpdate: React.FC<ResetPwdProps> = ({ setIsResetPasskey, userType }) => {
  const dispatch = useDispatch();
  const generatedToken = getTokenFromLocalStorage();
  const [matchPassKey, setMatchPassKey] = useState(false);
  const [disableText, setDisableText] = useState(true);
  const [loaderProfile, setLoaderProfile] = useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const loginProfileData = useSelector((state: RootState) => state.yoCard.profileData);
  const passKeyResponse = useSelector((state: RootState) => state.yoCard.updatePassKey);
  const [myPassKey, setMyPassKey] = useState<PassKeyPutDataInput>({
    psKey: '',
    prePsKey: '',
  });
  const [confirmPassKey, setConfirmPassKey] = useState(
    {
      confirmKey: '',
    },
  );

  const handleMyPassKey = (e:any) => {
    setMyPassKey({ ...myPassKey, [e.target.name]: e.target.value });
  };

  const handleConfirmPassKey = (e:any) => {
    setConfirmPassKey({ ...confirmPassKey, [e.target.name]: e.target.value });
  };

  React.useEffect(() => {
    if (!loginProfileData.isError && loginProfileData.message === 'executed') {
      setIsResetPasskey(loginProfileData.profileData.isPKReset);
      setUserProfileSessionStorage(JSON.stringify(loginProfileData));
      dispatch(clearProfileReq());
    }
  }, [loginProfileData.isError, loginProfileData.message]);

  React.useEffect(() => {
    if ((confirmPassKey.confirmKey === myPassKey.psKey) && (confirmPassKey.confirmKey !== '' && myPassKey.psKey !== '')) {
      setMatchPassKey(true);
      setDisableText(false);
    } else {
      setMatchPassKey(false);
    }
  }, [confirmPassKey.confirmKey, myPassKey.psKey]);

  const handleSubmitPassKey = (e: any) => {
    e.preventDefault();
    setLoaderProfile(true);
    dispatch(passKeyReq({
      inputBody: myPassKey,
      userReqFor: userType,
      requestType: APIHeader.REQ_UPDATE_PASSWORD,
      token: generatedToken,
    }));
  };

  React.useEffect(() => {
    if (passKeyResponse.error && passKeyResponse.message !== '') {
      setLoaderProfile(false);
      setAlertMsg(passKeyResponse.message);
      setErrorShowAlert(true);
    }
  }, [passKeyResponse.error, passKeyResponse.message]);

  React.useEffect(() => {
    if (!passKeyResponse.error && passKeyResponse.message !== '') {
      setLoaderProfile(false);
      dispatch(getProfile({
        userReqFor: userType,
        requestType: APIHeader.REQ_USER_PROFILE_INFO,
        token: generatedToken,
      }));
      dispatch(clearPassKeyReq());
      setMyPassKey({
        psKey: '',
        prePsKey: '',
      });
      setConfirmPassKey({
        confirmKey: '',
      });
      setAlertMsg(passKeyResponse.message);
      setSuccessShowAlert(true);
    }
  }, [passKeyResponse.error, passKeyResponse.message]);

  return (
    <Row>
      { loaderProfile ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Col xs="12">
        <Form onSubmit={handleSubmitPassKey}>
          <Card className="form-card-border-orng">
            <CardHeader className="form-card-header">
              Update your password
            </CardHeader>
            <CardBody>
              <div className="collapse-bg pt-2">
                <div className="text-right-login-yocard-register pt-1">
                  <h6 className="input-header-text">Current Password<span className="mandate-star">*</span></h6>
                  <Input
                    placeholder="Enter Your Current Password"
                    type="password"
                    name="prePsKey"
                    value={myPassKey.prePsKey}
                    required
                    onChange={handleMyPassKey}
                    className="yocard-input"
                  />
                </div>
                <div className="text-right-login-yocard-register pt-4 mx-1">
                  <h6 className="input-header-text">New Password<span className="mandate-star">*</span></h6>
                  <Input
                    placeholder="Enter Your New Password"
                    type="password"
                    name="psKey"
                    value={myPassKey.psKey}
                    required
                    onChange={handleMyPassKey}
                    className="yocard-input"
                  />
                </div>
                <div className="text-right-login-yocard-register pt-4 mx-1">
                  <h6 className="input-header-text">Confirm Password<span className="mandate-star">*</span></h6>
                  <Input
                    placeholder="Confirm your Password"
                    type="password"
                    name="confirmKey"
                    required
                    value={confirmPassKey.confirmKey}
                    onChange={handleConfirmPassKey}
                    className="yocard-input"
                  />
                  {myPassKey.psKey !== '' && confirmPassKey.confirmKey !== '' ? <div className="float-right-passkey-yocard mx-1"> {matchPassKey ? <span className="correct-pass-key">Password matched</span> : <span className="wrong-pass-key">Password mismatch</span>}</div> : null}
                </div>
                <div className="pb-1 " />
              </div>
            </CardBody>
            <CardFooter className="form-card-footer">
              <div className="align-end">
                <Button className="yocard-button-oval mx-1" disabled={loaderProfile || !matchPassKey || disableText}>
                  Save
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Form>
      </Col>
    </Row>
  );
};

export default ModalPasskeyUpdate;

import { ListTalukaAdmResponse, ListTalukaAdmResults } from './list-taluka-adm.service.types';

export const ListTalukaAdmTransform: (response: ListTalukaAdmResponse) => ListTalukaAdmResults = (listTalukaAdmResults) => {
  const { data, is_error } = listTalukaAdmResults;
  const result: ListTalukaAdmResults = {
    tCnt: 0,
    fCnt: 0,
    talukas: [],
    isError: false,
  };
  if (is_error) {
    result.isError = is_error;
  } else {
    result.tCnt = data.tCnt;
    result.fCnt = data.fCnt;
    result.talukas = data.talukas;
  }
  return result;
};

import React, { useState } from 'react';
import jwt_decode from 'jwt-decode';
import {
  Routes, Route,
  NavLink,
} from 'react-router-dom';
import { useNavigate } from 'react-router';
import {
  faCircleUser, faGear, faRightFromBracket, faUserLock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './printer.scss';
import {
  Row, Col, Modal, ModalBody, ModalHeader, Button, Nav, NavItem, Popover, PopoverBody,
} from 'reactstrap';
import ModalPasskeyUpdate from '../../../components/exclusive/profile/passkey-reset/modal-reset-passkey.component';
import CXUnauthorized from '../../unauthorized/unauthorized.component';
import {
  clearKeysFromLocalStorage, clearKeysFromSessionStorage, getTokenFromLocalStorage, getUserProfileSessionStorage,
} from '../../../utils/service/localstorage-service';
import { PrinterDashboard } from '../../../components/exclusive/printer/printer-dashboard.component';

export const ExclusiveRoutes: React.FC = () => (
  <Routes>
    <Route path="dashboard" element={<PrinterDashboard />} />
  </Routes>
);

export const PrinterComponent: React.FC = () => {
  const history = useNavigate();
  // const [click, setClick] = React.useState(true);
  // const [changeIcon, setChangeIcon] = React.useState(false);
  const tokenData = getTokenFromLocalStorage();
  const userProfile = getUserProfileSessionStorage();
  const [emailId, setEmailId] = useState('');
  const [phone, setPhone] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [resetPassKey, setResetPassKey] = useState(true);
  const [isResetPassKey, setIsResetPasskey] = useState(false);
  const [openMNotification, setOpenMNotification] = useState(false);
  const [openUserNotification, setOpenUserNotification] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  const passKeyModal = () => setResetPassKey(!resetPassKey);
  const [imageURL, setImageUrl] = useState('/images/common/avatar.png');

  const handleScroll = () => {
    if (window.scrollY > 10) {
      setShowFooter(true);
    } else {
      setShowFooter(false);
    }
  };
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.isPKReset) {
      setIsResetPasskey(userProfile && JSON.parse(userProfile).profileData.isPKReset);
    }
  }, [isResetPassKey, userProfile && JSON.parse(userProfile).profileData.isPKReset]);

  React.useEffect(() => {
    if (tokenData !== '') {
      const decodedToken: any = jwt_decode(tokenData);
      if (decodedToken !== undefined || decodedToken !== null) {
        if (decodedToken.exp * 1000 < new Date().getTime()) {
          history('/print/login');
        }
      }
    }
  }, [tokenData]);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.avatar === '') {
      setImageUrl('/images/common/avatar.png');
    } else {
      setImageUrl(userProfile && JSON.parse(userProfile).profileData.avatar);
    }
  }, [userProfile && JSON.parse(userProfile).profileData.avatar]);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.eId === '') {
      setEmailId('');
    } else {
      setEmailId(userProfile && JSON.parse(userProfile).profileData.eId);
    }
  }, [userProfile && JSON.parse(userProfile).profileData.eId]);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.ph === '') {
      setPhone('');
    } else {
      setPhone(userProfile && JSON.parse(userProfile).profileData.ph);
    }
  }, [userProfile && JSON.parse(userProfile).profileData.ph]);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.fNme === '') {
      setFirstName('');
    } else {
      setFirstName(userProfile && JSON.parse(userProfile).profileData.fNme);
    }
  }, [userProfile && JSON.parse(userProfile).profileData.fNme]);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.lNme === '') {
      setLastName('');
    } else {
      setLastName(userProfile && JSON.parse(userProfile).profileData.lNme);
    }
  }, [userProfile && JSON.parse(userProfile).profileData.lNme]);

  const handleMobileNotification = () => {
    setOpenMNotification(!openMNotification);
  };

  const handleUserNotification = () => {
    setOpenUserNotification(!openUserNotification);
  };

  return (
    <div>
      {tokenData !== '' ? (
        <div>
          {userProfile && JSON.parse(userProfile).profileData.isPKReset ? (
            <Modal isOpen={resetPassKey}>
              <ModalHeader toggle={passKeyModal} className="form-card-border-none mx-3 my-3" />
              <ModalBody className="padding-zero">
                <ModalPasskeyUpdate setIsResetPasskey={setIsResetPasskey} userType="PRNT" />
              </ModalBody>
            </Modal>
          )
            : null}
          <Row className="navbar-printer">
            <Col xs="12">
              <div className="align-end">
                <div className="mr-1">
                  <div className="align-center pt-1">
                    <img
                      alt="..."
                      className="yo-user-icon img-fluid rounded-circle cursor-pointer"
                      src={imageURL}
                      id="userInfo"
                    />
                    <Popover placement="bottom" isOpen={openUserNotification} target="userInfo" toggle={handleUserNotification}>
                      <PopoverBody>
                        <Nav>
                          <ul className="menu-list pt-3">
                            <NavItem className="menu-list">
                              <div className="align-center mx-2">
                                <span className="printer-user-header-grey-text">{firstName}{' '}{lastName}</span>
                              </div>
                              <div className="align-center">
                                <span className="printer-user-subheader-grey-text">{emailId}</span>
                              </div>
                              <div className="align-center">
                                <span className="printer-user-subheader-grey-text">+91 {phone}</span>
                              </div>
                            </NavItem>
                            <NavItem className="menu-list pt-1">
                              <hr />
                              <div className="align-center my-2">
                                <NavLink to="/print/login" onClick={() => { clearKeysFromLocalStorage(); clearKeysFromSessionStorage(); window.location.href = '/print/login'; }} className="menu-link-notification">
                                  <FontAwesomeIcon className="printer-grey-icon" icon={faRightFromBracket} />
                                  <span className="printer-grey-text mx-2">Logout</span>
                                </NavLink>
                              </div>
                            </NavItem>
                          </ul>
                        </Nav>
                      </PopoverBody>
                    </Popover>
                  </div>
                  <div className="align-center pt-1">
                    <span className="white-sadm-text">Printing</span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {/* <STAdminSideDrawer menuCollapse={click} setClick={setClick} /> */}
          <Row>
            <div className="route-margin">
              <ExclusiveRoutes />
            </div>
          </Row>
          <footer className={showFooter ? 'footer-visible' : ''}>
            <Popover placement="top" isOpen={openMNotification} target="passkeyMId" toggle={handleMobileNotification}>
              <PopoverBody>
                <Nav>
                  <ul className="menu-list">
                    <NavItem className="menu-list">
                      <NavLink to="avatar" onClick={handleMobileNotification} className="menu-link-notification">
                        <FontAwesomeIcon className="yocard-notification-icon" icon={faCircleUser} />
                        <span className="yocard-notification-txt mx-2">Avatar</span>
                      </NavLink>
                    </NavItem>
                    <NavItem className="menu-list pt-2">
                      <NavLink to="passkey" onClick={handleMobileNotification} className="menu-link-notification">
                        <FontAwesomeIcon className="yocard-notification-icon" icon={faUserLock} />
                        <span className="yocard-notification-txt mx-2">Password</span>
                      </NavLink>
                    </NavItem>
                  </ul>
                </Nav>
              </PopoverBody>
            </Popover>
            <Row>
              <Col xs="2" />
              <Col xs="2">
                <Button className="button-icon" id="passkeyMId" onClick={handleMobileNotification}><FontAwesomeIcon icon={faGear} className="yocard-header-icon" /></Button>
              </Col>
              <Col xs="2" />
            </Row>
          </footer>
        </div>
      )
        : (
          <CXUnauthorized />
        )}
    </div>
  );
};

export default PrinterComponent;

import { CreateAvatarResponse, CreateAvatarResults } from './create-avatar.service.types';

export const CreateAvatarTransform: (response: CreateAvatarResponse) => CreateAvatarResults = (createAvatarDBResults) => {
  const { data } = createAvatarDBResults;
  const result: CreateAvatarResults = {
    fileName: '',
  };
  result.fileName = data;
  return result;
};

import { RefStatusResponse, RefStatusResults } from './ref-sts.service.types';

export const RefStatusTransform: (response: RefStatusResponse) => RefStatusResults = (listRefFileResults) => {
  const { data, is_error, message } = listRefFileResults;
  const result: RefStatusResults = {
    tCnt: 0,
    fCnt: 0,
    lastEvalKey: {
      CardId: '',
      CardTp: '',
      cSts: '',
    },
    refData: [],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.tCnt = data.tCnt;
    result.fCnt = data.fCnt;
    result.refData = data.refData;
  }
  return result;
};

/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { RootState } from 'typesafe-actions';
import {
  Button, Card, CardBody, CardHeader, CardImg, Col, Input, Modal, ModalBody, ModalHeader, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, Tooltip,
} from 'reactstrap';
import * as XLSX from 'xlsx';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import {
  faCalendarDay,
  faCircleInfo, faDownload, faEdit, faFileImage, faIndianRupee, faMagnifyingGlassPlus, faMapLocation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { errorAlert, successAlert } from '../../../../utils/alert';
import { LoaderData } from '../../../../utils/loader';
import { getTokenFromLocalStorage, getUserTpSessionStorage } from '../../../../utils/service/localstorage-service';
import {
  getBilling, clearBilling, getEditBillingStatus, clearEditBillingStatus,
} from '../../../../store/yoCard/actions';
import {
  APIHeader, BillingCLientList, BillingPrinterList, BillingVendor1List, BillingZameerList, VendorList,
} from '../../../../utils/constants';
import { BillingDetails } from '../../../../services/reports-all/superadmin-dashboard/superadmin-billing/billing.types';

export const BillingTable: React.FC = () => {
  const dispatch = useDispatch();
  const userTp = getUserTpSessionStorage();
  const tokenData = getTokenFromLocalStorage();
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [vendorSts, setVendorSts] = useState('');
  const [editBillingStatus, setEditBillingStatus] = useState('');
  const [loaderAdmin, setLoaderAdmin] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [crdDetailsOpen, setCrdDetailsOpen] = React.useState<Record<string, boolean>>({});
  const [printUserOpen, setPrintUserOpen] = React.useState<Record<string, boolean>>({});
  const [openModalCLient, setOpenModalClient] = React.useState<Record<string, boolean>>({});
  const billingResponse = useSelector((state: RootState) => state.yoCard.billingStatus);
  const editBillingResponse = useSelector((state: RootState) => state.yoCard.editBillingStatus);
  const today = new Date().toISOString().split('T')[0];
  const [modalImage, setModalImage] = React.useState<Record<string, boolean>>({});
  const [startDate, setStartDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [billingStatus, setBillingStatus] = useState<BillingDetails[]>([{
    PrntId: '',
    stData: {
      distNme: '',
      stCd: '',
      stNme: '',
    },
    courId: '',
    PrntTp: '',
    costPrint: '',
    stsClient: '',
    stsVendor1: '',
    stsPrnt: '',
    gstVendor1: '0',
    stsZameer: '',
    cSts: '',
    updtOn: '',
    prntUsrCd: {
      st: '',
      ph: '',
      UserCd: '',
      lNme: '',
      fNme: '',
      eId: '',
    },
    updtCnt: 0,
    costVendor1: '',
    tCnt: 0,
    costCrd: '0',
    gstCrd: '0',
    gstPrint: '0',
    cardCost: '0',
    gstCardCost: '0',
    tCrdCost: '',
    tPrntCost: '',
    tVendor1Cost: '',
    costWDS: '0',
    gstWDS: '0',
    tWDSCost: '',
    costZameer: '0',
    gstZameer: '0',
    tZameerCost: '',
    tWDSProf: '',
    gstWDSProf: '',
    costWDSProf: '',
    courURL: '',
    sndrNme: '',
    aCmnt: '',
    balAmnt: '',
    miscCst: '',
    courTp: '',
    courTpVal: '',
  }]);

  const printToggleModalClient = (target: string) => {
    setOpenModalClient((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  const toggleCardDetails = (target: string) => {
    setCrdDetailsOpen((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  const togglePrintUser = (target: string) => {
    setPrintUserOpen((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  React.useEffect(() => {
    if (userTp === 'SADM') {
      setLoaderAdmin(true);
      Promise.resolve(
        dispatch(getBilling({
          userType: userTp,
          requestType: APIHeader.REQ_GET_CARD_BILLS,
          sDt: '',
          eDt: '',
          filtrTp: 'ALL',
          filtrSts: 'NONE',
          token: tokenData,
        })),
      ).then(() => {
        dispatch(clearBilling());
      });
    }
  }, [userTp]);

  const handleBillingSearch = () => {
    setLoaderAdmin(true);
    Promise.resolve(
      dispatch(getBilling({
        userType: userTp,
        requestType: APIHeader.REQ_GET_CARD_BILLS,
        sDt: startDate,
        eDt: endDate,
        filtrTp: 'STATUS',
        filtrSts: vendorSts,
        token: tokenData,
      })),
    ).then(() => {
      dispatch(clearBilling());
    });
  };

  React.useEffect(() => {
    if (billingResponse.isError && billingResponse.message !== '') {
      setLoaderAdmin(false);
      setErrorShowAlert(true);
      setAlertMsg(billingResponse.message);
      dispatch(clearBilling());
    }
  }, [billingResponse.isError, billingResponse.message]);

  React.useEffect(() => {
    if (!billingResponse.isError && billingResponse.message === 'executed') {
      setLoaderAdmin(false);
      setBillingStatus(billingResponse.data);
      dispatch(clearBilling());
    }
  }, [billingResponse.isError, billingResponse.message]);

  const vendoroptions = VendorList.map((vendor) => ({
    label: vendor.label,
    value: vendor.value,
  }));

  const handleSelectVendorData = (e: any) => {
    if (e) {
      setVendorSts(e.value);
    } else {
      setVendorSts('');
    }
  };

  const selectedVendorOptions = vendoroptions.find(
    (option) => option.value === vendorSts,
  );
  // Start Billing Client List

  const billingClientoptions = BillingCLientList.map((client) => ({
    label: client.label,
    value: client.value,
  }));

  const handleSelectBillingClientData = (e: any) => {
    if (e) {
      setEditBillingStatus(e.value);
    } else {
      setEditBillingStatus('');
    }
  };

  const selectedBillingClientOptions = billingClientoptions.find(
    (option) => option.value === editBillingStatus,
  );
  // End Billing Client List

  // Start Billing Printer List

  const billingPrinteroptions = BillingPrinterList.map((printer) => ({
    label: printer.label,
    value: printer.value,
  }));

  const handleSelectBillingPrinterData = (e: any) => {
    if (e) {
      setEditBillingStatus(e.value);
    } else {
      setEditBillingStatus('');
    }
  };

  const selectedBillingPrinterOptions = billingPrinteroptions.find(
    (option) => option.value === editBillingStatus,
  );
  // End Billing Printer List

  // Start Billing Vendor1 List

  const billingVendor1options = BillingVendor1List.map((vendor1) => ({
    label: vendor1.label,
    value: vendor1.value,
  }));

  const handleSelectBillingVendor1Data = (e: any) => {
    if (e) {
      setEditBillingStatus(e.value);
    } else {
      setEditBillingStatus('');
    }
  };

  const selectedBillingVendor1Options = billingVendor1options.find(
    (option) => option.value === editBillingStatus,
  );
  // End Billing Vendor1 List

  // Start Billing Zameer List

  const billingZameeroptions = BillingZameerList.map((zameer) => ({
    label: zameer.label,
    value: zameer.value,
  }));

  const handleSelectBillingZameerData = (e: any) => {
    if (e) {
      setEditBillingStatus(e.value);
    } else {
      setEditBillingStatus('');
    }
  };

  const selectedBillingZameerOptions = billingZameeroptions.find(
    (option) => option.value === editBillingStatus,
  );
  // End Billing Zameer List

  const handleEditBillingStatus = (distCd:string, cardDate:string) => {
    setLoaderAdmin(true);
    dispatch(getEditBillingStatus({
      userType: userTp,
      requestType: APIHeader.REQ_UPDATE_BILLING_STS,
      crdDt: cardDate,
      filtrSts: editBillingStatus,
      dist: distCd,
      token: tokenData,
    }));
  };

  React.useEffect(() => {
    if (editBillingResponse.error && editBillingResponse.message !== '') {
      setLoaderAdmin(false);
      setErrorShowAlert(true);
      setAlertMsg(editBillingResponse.message);
      dispatch(clearEditBillingStatus());
    }
  }, [editBillingResponse.error, editBillingResponse.message]);

  React.useEffect(() => {
    if (!editBillingResponse.error && editBillingResponse.message === 'executed') {
      setLoaderAdmin(false);
      setSuccessShowAlert(true);
      setAlertMsg(editBillingResponse.id);
      dispatch(clearEditBillingStatus());
    }
  }, [editBillingResponse.error, editBillingResponse.message]);

  const handleStartDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  };

  const handleShowCourImage = (target: string) => {
    setModalImage((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  const handleDownloadXLSX = () => {
    const data: any[][] = [];
    const headers = [
      'Date',
      'District(ST)',
      'Total Cards',
      'Bill Cost(Rs)',
      'Bill GST(Rs)',
      'Total Bill(Rs)',
      'Balance Amount(Rs)',
      'Print Cost(Rs)',
      'Print GST(Rs)',
      'Total Print(Rs)',
      'Ownership',
      'WDS Profit(Rs)',
      'WDS GST(Rs)',
      'Total WDS Profit(Rs)',
      'RZ Ent Profit(Rs)',
      'RZ Ent GST(Rs)',
      'Total RZ Ent Profit(Rs)',
    ];

    data.push(headers);

    billingStatus.forEach((response) => {
      const date = response.PrntId;
      const district = `${response.stData.distNme}(${response.stData.stCd})`;
      const totalCards = response.tCnt;
      const balAmount = response.balAmnt;
      const billCost = parseFloat(response.cardCost);
      const billGST = parseFloat(response.gstCardCost);
      const totalBill = billCost + billGST;
      const printCost = parseFloat(response.costPrint);
      const printGST = parseFloat(response.gstPrint);
      const totalPrint = printCost + printGST;
      const ownership = response.sndrNme;
      const wdsProf = parseFloat(response.costWDSProf);
      const wdsGST = parseFloat(response.gstWDSProf);
      const totalWDSProf = wdsProf + wdsGST;
      const rZProf = parseFloat(response.costZameer);
      const rZGST = parseFloat(response.gstZameer);
      const totalRZProf = rZProf + rZGST;

      data.push([date, district, totalCards, billCost, billGST, totalBill, balAmount, printCost, printGST, totalPrint, ownership, wdsProf, wdsGST, totalWDSProf, rZProf, rZGST, totalRZProf]);
    });

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Save the workbook
    XLSX.writeFile(wb, 'YO_Bill_Details.xlsx');
  };

  return (
    <div>
      { loaderAdmin ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="print-sadmin-card-box my-2">
        <Row className="align-center">
          <Col lg="3" xs="6">
            <div className="pt-1">
              <h6 className="input-header-text">Start Date</h6>
              <Input
                value={startDate}
                type="date"
                name="refDt"
                onChange={handleStartDate}
                className="yocard-input-ref"
              />
            </div>
          </Col>
          <Col lg="3" xs="6">
            <div className="pt-1">
              <h6 className="input-header-text">End Date</h6>
              <Input
                max={today}
                value={endDate}
                type="date"
                name="refDt"
                onChange={handleEndDate}
                className="yocard-input-ref"
              />
            </div>
          </Col>
          <Col lg="4">
            <div className="pt-1">
              <h6 className="input-header-text">Select Status</h6>
              <Select
                value={selectedVendorOptions}
                options={vendoroptions}
                isClearable
                onChange={handleSelectVendorData}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (base) => ({
                    ...base,
                  // position: 'absolute',
                  // overflow: 'visible',
                  }),
                  control: (base: any) => ({
                    ...base,
                    '&:hover': { borderColor: '#880000' },
                    border: '1px solid #DDE0E4',
                    width: '100%',
                    borderRadius: '5px',
                    fontSize: '15px',
                    fontWeight: 400,
                    color: '#575656',
                    boxShadow: 'none',
                    backgroundColor: '#fff',
                    '&:focus': {
                      borderColor: '#E1EDF8',
                      boxShadow: 'none',
                      color: '#575656',
                      background: '#fff',
                    },
                  }),
                  option: (provided: any, state: { isSelected: any; }) => ({
                    ...provided,
                    color: state.isSelected ? '#383838' : '#212121',
                    padding: 10,
                    textAlign: 'left',
                    background: state.isSelected ? '#FAFCFB' : '#fff',
                    '&:hover': {
                      background: '#FAFCFB',
                    },
                  }),
                }}
              />
            </div>
          </Col>
          <Col lg="10">
            <div className="my-2 align-end">
              <div className="pt-1">
                {billingStatus.length > 0
                  ? (
                    <Button className="yocard-button-link mx-1" onClick={() => handleDownloadXLSX()}>
                      Download Bill<FontAwesomeIcon icon={faDownload} className="fa-file-ref mx-2" />
                    </Button>
                  ) : null}
                <Button className="yocard-button-oval mx-1" onClick={() => handleBillingSearch()}>
                  Search
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div>
        {billingStatus.length === 0 ? (
          <div className="pt-5 align-center">
            <span className="form-collapse-card-header">No Record Found</span>
          </div>
        )
          : (
            <Row className="pt-5 align-center">
              {billingStatus && billingStatus.map((row, rowIndex) => (
                <Col key={`row-${row.PrntId}-${row.PrntTp}`} lg="6" className="my-2">
                  <Card className="form-card">
                    <CardHeader className="form-card-header cursor-pointer" onClick={() => toggleCardDetails(`cardDetails-${rowIndex}`)}>
                      <Row>
                        <Col lg="8" xs="7">
                          <div className="align-start">
                            <FontAwesomeIcon className="accounts-card-icon mx-2 pt-1" icon={faMapLocation} />
                            <span className="accounts-header-black">{row.PrntTp}</span>
                          </div>
                          <div className="align-start ml-2">
                            <span className="td-text-lite-grey">{row.stData.distNme}({row.stData.stCd})</span>
                          </div>
                        </Col>
                        <Col lg="4" xs="5">
                          <div className="align-end">
                            <span className="accounts-header-black"><FontAwesomeIcon className="accounts-card-icon mx-2" icon={faCalendarDay} />
                              {row.PrntId}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </CardHeader>
                    <div className="align-end mx-2">
                      <span className="td-text-lite-grey">Ownership:</span>
                      <span className="td-text-lite-main-bold mx-1">{row.sndrNme !== '' ? row.sndrNme : 'NA'}</span>
                    </div>
                    {row.courId && row.courId !== ''
                      ? (
                        <div className="align-start mx-1">
                          <span className="td-text-lite-main-bold mx-1">{row.courId}</span>
                          <span className="td-text-lite-grey">({row.courTpVal})</span>
                        </div>
                      ) : <div className="align-start mx-1"><span className="td-text-lite-grey">NA</span></div>}
                    <CardBody className="margin-0">
                      <Row>
                        <Col lg="4" xs="5">
                          <div className="align-center ml">
                            <div className="stopr-circle_container">
                              <div className="stopr-circle_main">
                                <div className="stopr-circle_text_container">
                                  <div className="stopr-circle_text">
                                    {row.tCnt.toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="align-center my-2">
                            <span className="td-text-lite-grey">Count
                              <span className="td-text-lite-main">(<FontAwesomeIcon className="accounts-card-icon-lite mx-1" icon={faIndianRupee} />{(parseFloat(row.costCrd) / 100).toFixed(2)} + {row.gstCrd}%)</span>
                            </span>
                          </div>
                        </Col>
                        <Col lg="8" xs="7">
                          <Row>
                            <Col xs="12">
                              <div className="align-end mx-2">
                                <span className={row.cSts === 'Printing Completed' ? 'print-status-completed mb-2' : 'print-status-pending mb-2'}>{row.cSts}</span>
                                {row.cSts === 'Printing Completed'
                                  ? (
                                    <div className="align-start">
                                      <Button disabled={row.stsZameer === ''} className="button-icon" onClick={() => printToggleModalClient(`courierSts-${rowIndex}`)}>
                                        <FontAwesomeIcon className="dash-edit-icon" icon={faFileImage} />
                                      </Button>
                                      <Modal
                                        isOpen={openModalCLient[`courierSts-${rowIndex}`] || false}
                                        target={`courierSts-${rowIndex}`}
                                        className="pt-5"
                                      >
                                        <ModalHeader toggle={() => printToggleModalClient(`courierSts-${rowIndex}`)} className="form-card-border-none mx-3 my-2" />
                                        <div className="align-center">
                                          <span className="td-text-lite-main">Village/Town: {row.PrntTp}, {row.stData.distNme}({row.stData.stCd})</span>
                                        </div>
                                        <ModalBody className="padding-zero my-3 mx-3">
                                          <Row>
                                            <Col lg="12">
                                              <div className="pt-1">
                                                {row.courURL !== ''
                                                  ? (
                                                    <div className="align-center pt-2">
                                                      <CardImg alt="logo" className="img-fluid avatar-user-profile-preview" src={row.courURL} />
                                                    </div>
                                                  ) : (
                                                    <div className="align-center">
                                                      <span className="accounts-header">Courier details not updated yet</span>
                                                    </div>
                                                  ) }
                                              </div>
                                            </Col>
                                          </Row>
                                        </ModalBody>
                                      </Modal>
                                    </div>
                                  ) : null}
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="align-center pt-4">
                                <span className="accounts-header-bal">
                                  <FontAwesomeIcon className="mx-1" icon={faIndianRupee} /> {new Intl.NumberFormat('en-US').format(parseFloat(row.tCrdCost))}
                                </span>
                              </div>
                              <div className="align-center">
                                <span className="td-text-lite-grey">
                                  (<FontAwesomeIcon className="accounts-card-icon-lite mx-1" icon={faIndianRupee} />{new Intl.NumberFormat('en-US').format(parseFloat(row.cardCost))} + <FontAwesomeIcon className="accounts-card-icon-lite mx-1" icon={faIndianRupee} />{new Intl.NumberFormat('en-US').format(parseFloat(row.gstCardCost))})
                                </span>
                              </div>
                              <div className="align-center">
                                <span className="td-text-lite-grey">
                                  Total Cost
                                </span>
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="align-center pt-4">
                                <span className="accounts-header">
                                  <FontAwesomeIcon className="mx-1" icon={faIndianRupee} /> {new Intl.NumberFormat('en-US').format(parseFloat(row.balAmnt))}
                                </span>
                              </div>
                              <div className="align-center">
                                <span className="td-text-lite-grey">
                                  Balance
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="pt-2">
                        <Col lg="2">
                          {row.courURL !== ''
                            ? (
                              <div className="pt-1">
                                <Card className="card-container cursor-pointer" onClick={() => handleShowCourImage(`courierImg-${rowIndex}`)} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                                  <CardImg alt="logo" className="" src={row.courURL} onClick={() => handleShowCourImage(`courierImg-${rowIndex}`)} />
                                  {isHovered && (
                                  <div className="hover-icon-container">
                                    <FontAwesomeIcon icon={faMagnifyingGlassPlus} className="hover-icon" />
                                  </div>
                                  )}
                                </Card>
                                <Modal
                                  isOpen={modalImage[`courierImg-${rowIndex}`] || false}
                                  target={`courierImg-${rowIndex}`}
                                  className="pt-5 cour-image"
                                >
                                  <ModalHeader toggle={() => handleShowCourImage(`courierImg-${rowIndex}`)} className="form-card-border-none mx-3 my-2" />
                                  <div className="align-center">
                                    <span className="td-text-lite-main">Village/Town: {row.PrntTp}, {row.stData.distNme}({row.stData.stCd})</span>
                                  </div>
                                  <ModalBody className="padding-zero my-3 mx-3">
                                    <div className="align-center pt-2">
                                      <CardImg alt="logo" className="" src={row.courURL} />
                                    </div>
                                  </ModalBody>
                                </Modal>
                                {/* <Button className="button-icon cursor-pointer" onClick={() => handleShowCourImage(`courierImg-${rowIndex}`)}>
                                  <FontAwesomeIcon icon={faMagnifyingGlassPlus} className="mx-1 button-icon-small" />
                                </Button>
                                <Modal
                                  isOpen={modalImage[`courierImg-${rowIndex}`] || false}
                                  target={`courierImg-${rowIndex}`}
                                >
                                  <ModalHeader toggle={() => handleShowCourImage(`courierImg-${rowIndex}`)} className="form-card-border-none mx-3 my-2" />
                                  <div className="ml-1">
                                    <span className="input-header-text">{row.stData.distNme}({row.stData.stCd})</span>
                                  </div>
                                  <ModalBody className="padding-zero my-3 mx-3">
                                    <CardImg alt="logo" src={row.courURL} />
                                  </ModalBody>
                                </Modal> */}
                              </div>
                            ) : null}
                        </Col>
                        <Col lg="10">
                          <div className="align-end mx-1">
                            {row.stsClient === 'Pending from Client' ? <span className="status-pending">{row.stsClient}</span> : <span className="status-completed">{row.stsClient}</span>}
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                    <Offcanvas
                      direction="end"
                      isOpen={crdDetailsOpen[`cardDetails-${rowIndex}`]}
                      toggle={() => toggleCardDetails(`cardDetails-${rowIndex}`)}
                    >
                      <OffcanvasHeader toggle={() => toggleCardDetails(`cardDetails-${rowIndex}`)} className="margin-0 mx-1">
                        <div className="align-start pt-3 mx-3">
                          <span className="print-status-pending"><FontAwesomeIcon className="accounts-card-icon-lite mr" icon={faCalendarDay} />
                            {row.PrntId}
                          </span>
                        </div>
                      </OffcanvasHeader>
                      <div className="align-start ml-1">
                        <span className="accounts-header-black">{row.PrntTp}</span>
                      </div>
                      <div className="align-start ml-1">
                        <span className="td-text-lite-grey">{row.stData.distNme}({row.stData.stCd})</span>
                      </div>
                      <OffcanvasBody className="margin-0">
                        <Row>
                          <Col xs="12">
                            <div className="align-start mx-1">
                              <span className="td-text-lite-grey">Ownership:</span>
                              <span className="td-text-lite-main-bold mx-1">{row.sndrNme !== '' ? row.sndrNme : 'NA'}</span>
                            </div>
                            <div className="align-start mx-1 pt-1">
                              <span className="text-lite-grey">Print Status:</span>
                              <span className={row.cSts === 'Printing Completed' ? 'print-status-completed mb-2 mx-1' : 'print-status-pending mb-2 mx-1'}>{row.cSts}</span>
                              {row.cSts === 'Printing Completed'
                                ? (
                                  <div className="align-start">
                                    <Button disabled={row.stsZameer === ''} className="button-icon" onClick={() => printToggleModalClient(`courierSts-${rowIndex}`)}>
                                      <FontAwesomeIcon className="dash-edit-icon" icon={faFileImage} />
                                    </Button>
                                    <Modal
                                      isOpen={openModalCLient[`courierSts-${rowIndex}`] || false}
                                      target={`courierSts-${rowIndex}`}
                                      className="pt-5"
                                    >
                                      <ModalHeader toggle={() => printToggleModalClient(`courierSts-${rowIndex}`)} className="form-card-border-none mx-3 my-2" />
                                      <div className="align-center">
                                        <span className="td-text-lite-main">Village/Town: {row.PrntTp}, {row.stData.distNme}({row.stData.stCd})</span>
                                      </div>
                                      <ModalBody className="padding-zero my-3 mx-3">
                                        <Row>
                                          <Col lg="12">
                                            <div className="pt-1">
                                              {row.courURL !== ''
                                                ? (
                                                  <div className="align-center pt-2">
                                                    <CardImg alt="logo" className="img-fluid avatar-user-profile-preview" src={row.courURL} />
                                                  </div>
                                                ) : (
                                                  <div className="align-center">
                                                    <span className="accounts-header">Courier details not updated yet</span>
                                                  </div>
                                                ) }
                                            </div>
                                          </Col>
                                        </Row>
                                      </ModalBody>
                                    </Modal>
                                  </div>
                                ) : null}
                            </div>
                          </Col>
                          <Col xs="12">
                            <div className="align-center pt-2">
                              <span className="accounts-header">
                                <FontAwesomeIcon className="mx-1" icon={faIndianRupee} /> {new Intl.NumberFormat('en-US').format(parseFloat(row.tCrdCost))}
                              </span>
                            </div>
                            <div className="align-center">
                              <span className="td-text-lite-grey">
                                (<FontAwesomeIcon className="accounts-card-icon-lite mx-1" icon={faIndianRupee} />{new Intl.NumberFormat('en-US').format(parseFloat(row.cardCost))} + <FontAwesomeIcon className="accounts-card-icon-lite mx-1" icon={faIndianRupee} />{new Intl.NumberFormat('en-US').format(parseFloat(row.gstCardCost))})
                              </span>
                            </div>
                          </Col>
                          <Col lg="12">
                            <div className="align-end pt-3">
                              {row.stsClient === 'Pending from Client' ? <span className="status-pending">{row.stsClient}</span> : <span className="status-completed">{row.stsClient}</span>}
                              <Button disabled={row.stsClient === ''} className="button-icon" onClick={() => printToggleModalClient(`clientStatus-${rowIndex}`)}>
                                <FontAwesomeIcon className="dash-edit-icon" icon={faEdit} />
                              </Button>
                              <Modal
                                isOpen={openModalCLient[`clientStatus-${rowIndex}`] || false}
                                target={`clientStatus-${rowIndex}`}
                                className="pt-5"
                              >
                                <ModalHeader toggle={() => printToggleModalClient(`clientStatus-${rowIndex}`)} className="form-card-border-none mx-3 my-2" />
                                <div className="ml-1">
                                  <span className="td-text-highlight">{row.PrntId}</span>
                                </div>
                                <div className="ml-1">
                                  <span className="td-text-highlight">{row.PrntTp}, {row.stData.distNme}({row.stData.stCd})</span>
                                </div>
                                <div className="ml-1">
                                  <span className="text-lite-grey">Current Status: {row.stsClient}</span>
                                </div>
                                <ModalBody className="padding-zero my-1 mx-3">
                                  <Row>
                                    <Col lg="12">
                                      <div className="pt-1">
                                        <h6 className="input-header-text">Select Client Status</h6>
                                        <Select
                                          value={selectedBillingClientOptions}
                                          options={billingClientoptions}
                                          isClearable
                                          onChange={handleSelectBillingClientData}
                                          menuPortalTarget={document.body}
                                          styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            menu: (base) => ({
                                              ...base,
                                              // position: 'absolute',
                                              // overflow: 'visible',
                                            }),
                                            control: (base: any) => ({
                                              ...base,
                                              '&:hover': { borderColor: '#880000' },
                                              border: '1px solid #DDE0E4',
                                              width: '100%',
                                              borderRadius: '5px',
                                              fontSize: '15px',
                                              fontWeight: 400,
                                              color: '#575656',
                                              boxShadow: 'none',
                                              backgroundColor: '#fff',
                                              '&:focus': {
                                                borderColor: '#E1EDF8',
                                                boxShadow: 'none',
                                                color: '#575656',
                                                background: '#fff',
                                              },
                                            }),
                                            option: (provided: any, state: { isSelected: any; }) => ({
                                              ...provided,
                                              color: state.isSelected ? '#383838' : '#212121',
                                              padding: 10,
                                              textAlign: 'left',
                                              background: state.isSelected ? '#FAFCFB' : '#fff',
                                              '&:hover': {
                                                background: '#FAFCFB',
                                              },
                                            }),
                                          }}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <div className="align-center my-1 pt-3">
                                    <Button className="yocard-button-oval" onClick={() => handleEditBillingStatus(row.PrntTp, row.PrntId)}>Submit</Button>
                                  </div>
                                </ModalBody>
                              </Modal>
                            </div>
                          </Col>
                          <Col xs="12">
                            <div className="align-center my-2 pt-3">
                              <div className="stopr-circle_container">
                                <div className="stopr-circle_main">
                                  <div className="stopr-circle_text_container">
                                    <div className="stopr-circle_text">
                                      {row.tCnt.toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="align-center my-2">
                              <span className="td-text-lite-grey">Count
                                <span className="td-text-lite-main">(<FontAwesomeIcon className="accounts-card-icon-lite mx-1" icon={faIndianRupee} />{(parseFloat(row.costCrd) / 100).toFixed(2)} + {row.gstCrd}%)</span>
                              </span>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <hr className="hr-accounts-det my-3" />
                            <Row>
                              <div className="align-center">
                                <span className="td-text-lite-main-bold">Printer
                                  <Button className="button-icon" id={`printUser-${rowIndex}`}>
                                    <FontAwesomeIcon className="accounts-card-icon-lite mb-1" icon={faCircleInfo} />
                                  </Button>
                                  <Tooltip
                                    placement="top"
                                    isOpen={printUserOpen[`printUser-${rowIndex}`] || false}
                                    target={`printUser-${rowIndex}`}
                                    toggle={() => togglePrintUser(`printUser-${rowIndex}`)}
                                  >
                                    <div><h6>{row.prntUsrCd.fNme} {' '}{row.prntUsrCd.lNme}</h6></div>
                                    <div><span>{row.prntUsrCd.eId}</span></div>
                                    <div><span>{row.prntUsrCd.ph}</span></div>
                                  </Tooltip>
                                </span>
                              </div>
                              <Col lg="12">
                                <div className="align-center">
                                  <span className="td-text-lite-main-bold">{new Intl.NumberFormat('en-US').format(parseFloat(row.tPrntCost))}</span>
                                </div>
                                <div className="align-center">
                                  <span className="td-text-lite-main">
                                    (<FontAwesomeIcon className="accounts-card-icon-lite mx-1" icon={faIndianRupee} />{new Intl.NumberFormat('en-US').format(parseFloat(row.costPrint))} + <FontAwesomeIcon className="accounts-card-icon-lite mx-1" icon={faIndianRupee} />{new Intl.NumberFormat('en-US').format(parseFloat(row.gstPrint))})
                                  </span>
                                </div>
                                <div className="align-end pt-2">
                                  {row.stsPrnt === 'Printer Payment Pending' ? <span className="status-pending">{row.stsPrnt}</span> : <span className="status-completed">{row.stsPrnt}</span>}
                                  <Button disabled={row.stsPrnt === ''} className="button-icon" onClick={() => printToggleModalClient(`printerStatus-${rowIndex}`)}>
                                    <FontAwesomeIcon className="dash-edit-icon" icon={faEdit} />
                                  </Button>
                                  <Modal
                                    isOpen={openModalCLient[`printerStatus-${rowIndex}`] || false}
                                    target={`printerStatus-${rowIndex}`}
                                    className="pt-5"
                                  >
                                    <ModalHeader toggle={() => printToggleModalClient(`printerStatus-${rowIndex}`)} className="form-card-border-none mx-3 my-2" />
                                    <div className="ml-1">
                                      <span className="td-text-highlight">{row.PrntId}</span>
                                    </div>
                                    <div className="ml-1">
                                      <span className="td-text-highlight">{row.PrntTp}, {row.stData.distNme}({row.stData.stCd})</span>
                                    </div>
                                    <div className="ml-1">
                                      <span className="text-lite-grey">Current Status: {row.stsPrnt}</span>
                                    </div>
                                    <ModalBody className="padding-zero my-3 mx-3">
                                      <Row>
                                        <Col lg="12">
                                          <div className="pt-1">
                                            <h6 className="input-header-text">Select Printer Status</h6>
                                            <Select
                                              value={selectedBillingPrinterOptions}
                                              options={billingPrinteroptions}
                                              isClearable
                                              onChange={handleSelectBillingPrinterData}
                                              menuPortalTarget={document.body}
                                              styles={{
                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                menu: (base) => ({
                                                  ...base,
                                                  // position: 'absolute',
                                                  // overflow: 'visible',
                                                }),
                                                control: (base: any) => ({
                                                  ...base,
                                                  '&:hover': { borderColor: '#880000' },
                                                  border: '1px solid #DDE0E4',
                                                  width: '100%',
                                                  borderRadius: '5px',
                                                  fontSize: '15px',
                                                  fontWeight: 400,
                                                  color: '#575656',
                                                  boxShadow: 'none',
                                                  backgroundColor: '#fff',
                                                  '&:focus': {
                                                    borderColor: '#E1EDF8',
                                                    boxShadow: 'none',
                                                    color: '#575656',
                                                    background: '#fff',
                                                  },
                                                }),
                                                option: (provided: any, state: { isSelected: any; }) => ({
                                                  ...provided,
                                                  color: state.isSelected ? '#383838' : '#212121',
                                                  padding: 10,
                                                  textAlign: 'left',
                                                  background: state.isSelected ? '#FAFCFB' : '#fff',
                                                  '&:hover': {
                                                    background: '#FAFCFB',
                                                  },
                                                }),
                                              }}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <div className="align-center my-1 pt-3">
                                        <Button className="yocard-button-oval" onClick={() => handleEditBillingStatus(row.PrntTp, row.PrntId)}>Submit</Button>
                                      </div>
                                    </ModalBody>
                                  </Modal>
                                </div>
                              </Col>
                            </Row>
                            <hr className="hr-accounts-det my-3" />
                            <Row>
                              <div className="align-center">
                                <span className="td-text-lite-main-bold">Vendor#1</span>
                              </div>
                              <Col lg="12">
                                <div className="align-center">
                                  <span className="td-text-lite-main-bold">{new Intl.NumberFormat('en-US').format(parseFloat(row.tVendor1Cost))}</span>
                                </div>
                                <div className="align-center">
                                  <span className="td-text-lite-main">
                                    (<FontAwesomeIcon className="accounts-card-icon-lite mx-1" icon={faIndianRupee} />{new Intl.NumberFormat('en-US').format(parseFloat(row.costVendor1))} + <FontAwesomeIcon className="accounts-card-icon-lite mx-1" icon={faIndianRupee} />{new Intl.NumberFormat('en-US').format(parseFloat(row.gstVendor1))})
                                  </span>
                                </div>
                                <div className="align-end pt-2">
                                  {row.stsVendor1 === 'Vendor1 Payment Pending' ? <span className="status-pending">{row.stsVendor1}</span> : <span className="status-completed">{row.stsVendor1}</span>}
                                  <Button disabled={row.stsVendor1 === ''} className="button-icon" onClick={() => printToggleModalClient(`vendor1Status-${rowIndex}`)}>
                                    <FontAwesomeIcon className="dash-edit-icon" icon={faEdit} />
                                  </Button>
                                  <Modal
                                    isOpen={openModalCLient[`vendor1Status-${rowIndex}`] || false}
                                    target={`vendor1Status-${rowIndex}`}
                                    className="pt-5"
                                  >
                                    <ModalHeader toggle={() => printToggleModalClient(`vendor1Status-${rowIndex}`)} className="form-card-border-none mx-3 my-2" />
                                    <div className="ml-1">
                                      <span className="td-text-highlight">{row.PrntId}</span>
                                    </div>
                                    <div className="ml-1">
                                      <span className="td-text-highlight">{row.PrntTp}, {row.stData.distNme}({row.stData.stCd})</span>
                                    </div>
                                    <div className="ml-1">
                                      <span className="text-lite-grey">Current Status: {row.stsVendor1}</span>
                                    </div>
                                    <ModalBody className="padding-zero my-3 mx-3">
                                      <Row>
                                        <Col lg="12">
                                          <div className="pt-1">
                                            <h6 className="input-header-text">Select Vendor#1 Status</h6>
                                            <Select
                                              value={selectedBillingVendor1Options}
                                              options={billingVendor1options}
                                              isClearable
                                              onChange={handleSelectBillingVendor1Data}
                                              menuPortalTarget={document.body}
                                              styles={{
                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                menu: (base) => ({
                                                  ...base,
                                                // position: 'absolute',
                                                // overflow: 'visible',
                                                }),
                                                control: (base: any) => ({
                                                  ...base,
                                                  '&:hover': { borderColor: '#880000' },
                                                  border: '1px solid #DDE0E4',
                                                  width: '100%',
                                                  borderRadius: '5px',
                                                  fontSize: '15px',
                                                  fontWeight: 400,
                                                  color: '#575656',
                                                  boxShadow: 'none',
                                                  backgroundColor: '#fff',
                                                  '&:focus': {
                                                    borderColor: '#E1EDF8',
                                                    boxShadow: 'none',
                                                    color: '#575656',
                                                    background: '#fff',
                                                  },
                                                }),
                                                option: (provided: any, state: { isSelected: any; }) => ({
                                                  ...provided,
                                                  color: state.isSelected ? '#383838' : '#212121',
                                                  padding: 10,
                                                  textAlign: 'left',
                                                  background: state.isSelected ? '#FAFCFB' : '#fff',
                                                  '&:hover': {
                                                    background: '#FAFCFB',
                                                  },
                                                }),
                                              }}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <div className="align-center my-1 pt-3">
                                        <Button className="yocard-button-oval" onClick={() => handleEditBillingStatus(row.PrntTp, row.PrntId)}>Submit</Button>
                                      </div>
                                    </ModalBody>
                                  </Modal>
                                </div>
                              </Col>
                            </Row>
                            <hr className="hr-accounts-det my-3" />
                            <Row>
                              <div className="align-center">
                                <span className="td-text-lite-main-bold">RZ Enterprises</span>
                              </div>
                              <Col lg="12">
                                <div className="align-center">
                                  <span className="td-text-lite-main-bold">{new Intl.NumberFormat('en-US').format(parseFloat(row.tZameerCost))}</span>
                                </div>
                                <div className="align-center">
                                  <span className="td-text-lite-main">
                                    (<FontAwesomeIcon className="accounts-card-icon-lite mx-1" icon={faIndianRupee} />{new Intl.NumberFormat('en-US').format(parseFloat(row.costZameer))} + <FontAwesomeIcon className="accounts-card-icon-lite mx-1" icon={faIndianRupee} />{new Intl.NumberFormat('en-US').format(parseFloat(row.gstZameer))})
                                  </span>
                                </div>
                                <div className="align-end pt-2">
                                  {row.stsZameer === 'RZ Ent. Payment Pending' ? <span className="status-pending">{row.stsZameer}</span> : <span className="status-completed">{row.stsZameer}</span>}
                                  <Button disabled={row.stsZameer === ''} className="button-icon" onClick={() => printToggleModalClient(`zameerStatus-${rowIndex}`)}>
                                    <FontAwesomeIcon className="dash-edit-icon" icon={faEdit} />
                                  </Button>
                                  <Modal
                                    isOpen={openModalCLient[`zameerStatus-${rowIndex}`] || false}
                                    target={`zameerStatus-${rowIndex}`}
                                    className="pt-5"
                                  >
                                    <ModalHeader toggle={() => printToggleModalClient(`zameerStatus-${rowIndex}`)} className="form-card-border-none mx-3 my-2" />
                                    <div className="ml-1">
                                      <span className="td-text-highlight">{row.PrntId}</span>
                                    </div>
                                    <div className="ml-1">
                                      <span className="td-text-highlight">{row.PrntTp}, {row.stData.distNme}({row.stData.stCd})</span>
                                    </div>
                                    <div className="ml-1">
                                      <span className="text-lite-grey">Current Status: {row.stsZameer}</span>
                                    </div>
                                    <ModalBody className="padding-zero my-3 mx-3">
                                      <Row>
                                        <Col lg="12">
                                          <div className="pt-1">
                                            <h6 className="input-header-text">Select RZ Ent.&apos;s Status</h6>
                                            <Select
                                              value={selectedBillingZameerOptions}
                                              options={billingZameeroptions}
                                              isClearable
                                              onChange={handleSelectBillingZameerData}
                                              menuPortalTarget={document.body}
                                              styles={{
                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                menu: (base) => ({
                                                  ...base,
                                                // position: 'absolute',
                                                // overflow: 'visible',
                                                }),
                                                control: (base: any) => ({
                                                  ...base,
                                                  '&:hover': { borderColor: '#880000' },
                                                  border: '1px solid #DDE0E4',
                                                  width: '100%',
                                                  borderRadius: '5px',
                                                  fontSize: '15px',
                                                  fontWeight: 400,
                                                  color: '#575656',
                                                  boxShadow: 'none',
                                                  backgroundColor: '#fff',
                                                  '&:focus': {
                                                    borderColor: '#E1EDF8',
                                                    boxShadow: 'none',
                                                    color: '#575656',
                                                    background: '#fff',
                                                  },
                                                }),
                                                option: (provided: any, state: { isSelected: any; }) => ({
                                                  ...provided,
                                                  color: state.isSelected ? '#383838' : '#212121',
                                                  padding: 10,
                                                  textAlign: 'left',
                                                  background: state.isSelected ? '#FAFCFB' : '#fff',
                                                  '&:hover': {
                                                    background: '#FAFCFB',
                                                  },
                                                }),
                                              }}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <div className="align-center my-1 pt-3">
                                        <Button className="yocard-button-oval" onClick={() => handleEditBillingStatus(row.PrntTp, row.PrntId)}>Submit</Button>
                                      </div>
                                    </ModalBody>
                                  </Modal>
                                </div>
                              </Col>
                            </Row>
                            <hr className="hr-accounts-det my-3" />
                            <Row>
                              <div className="align-center">
                                <span className="td-text-lite-main-bold">WDSInfotech</span>
                              </div>
                              <Col lg="6">
                                <div className="align-center pt-4">
                                  <span className="td-text-lite-main-bold">{new Intl.NumberFormat('en-US').format(parseFloat(row.tWDSCost))}</span>
                                </div>
                                <div className="align-center">
                                  <span className="td-text-lite-main">
                                    (<FontAwesomeIcon className="accounts-card-icon-lite mx-1" icon={faIndianRupee} />{new Intl.NumberFormat('en-US').format(parseFloat(row.costWDS))} + <FontAwesomeIcon className="accounts-card-icon-lite mx-1" icon={faIndianRupee} />{new Intl.NumberFormat('en-US').format(parseFloat(row.gstWDS))})
                                  </span>
                                </div>
                                <div className="align-center">
                                  <span className="td-text-lite-grey">Costing</span>
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="align-center pt-4">
                                  <span className="td-text-lite-main-bold">{new Intl.NumberFormat('en-US').format(parseFloat(row.tWDSProf))}</span>
                                </div>
                                <div className="align-center">
                                  <span className="td-text-lite-main">
                                    (<FontAwesomeIcon className="accounts-card-icon-lite mx-1" icon={faIndianRupee} />{new Intl.NumberFormat('en-US').format(parseFloat(row.costWDSProf))} + <FontAwesomeIcon className="accounts-card-icon-lite mx-1" icon={faIndianRupee} />{new Intl.NumberFormat('en-US').format(parseFloat(row.gstWDSProf))})
                                  </span>
                                </div>
                                <div className="align-center">
                                  <span className="td-text-lite-grey">Profit</span>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg="12">
                            <div className="pt-4">
                              <span className="text-lite-grey">{row.aCmnt}</span>
                            </div>
                          </Col>
                        </Row>
                        <div className="my-4" />
                      </OffcanvasBody>
                    </Offcanvas>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
      </div>
    </div>
  );
};

export default BillingTable;

import React, { useState } from 'react';
import {
  Row, Col, CardBody, Card, Input, Label,
} from 'reactstrap';
import '../login.scss';
import { LoginSTAdmin } from './stadmin.login';
import LoginCardOperator from './ccardopt.login';

export const PublicLogin: React.FC = () => {
  const [userType, setUserType] = useState('CRDOPT');

  const handleUserLogin = (user_tp: string) => {
    if (user_tp === 'CRDOPT') {
      setUserType('CRDOPT');
    } else if (user_tp === 'STADM') {
      setUserType('STADM');
    }
  };
  return (
    <div className="login-align-vertical1 mx-1">
      <div className="image-top-left" />
      <div className="image-bottom-right" />
      <Card className="login-card-home">
        <CardBody>
          <Row className="pt-2">
            <Col lg={{ size: 6, order: 2 }} md={{ size: 5, order: 2 }} xs={{ order: 1 }} className="vertical-align1">
              <Row className="align-center">
                <Col lg="4" xs="6" className="pt-1">
                  <Input
                    type="radio"
                    name="usrTp"
                    checked={userType === 'CRDOPT'}
                    className="yocard-radio-box cursor-pointer"
                    onChange={() => handleUserLogin('CRDOPT')}
                  />
                  <Label check className="collapse-header mx-1">Card Operator</Label>
                </Col>
                <Col lg="4" xs="6" className="pt-1">
                  <Input
                    type="radio"
                    name="usrTp"
                    checked={userType === 'STADM'}
                    className="yocard-radio-box cursor-pointer"
                    onChange={() => handleUserLogin('STADM')}
                  />
                  <Label check className="collapse-header mx-1">State Admin</Label>
                </Col>
              </Row>
              {
                userType === 'CRDOPT' ? <LoginCardOperator /> : <LoginSTAdmin />
              }
            </Col>
            <Col lg={{ size: 6, order: 1 }} md={{ size: 7, order: 1 }} xs={{ order: 2 }}>
              <div className="mx-2 align-center">
                <img style={{ height: 'auto', width: '330px' }} src="/images/common/login-admin.png" alt="YoLoginLogo" />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
export default PublicLogin;

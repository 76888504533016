import { ActionType, getType, Reducer } from 'typesafe-actions';

import * as actions from './actions';
import { YoCardAppState } from './action.types';
import {
  clearAgntListReq,
  clearAgntReq,
  clearAWSFilesUpload,
  clearAWSFolderList,
  clearBilling,
  clearCardCountStateWise,
  clearCardDashboard,
  clearCardMassStatus,
  clearCardPrintReadyReq,
  clearCardStatusReq,
  clearCardTrendStatus,
  clearCheckRefFileReq,
  clearContactUsPostReq, clearDelFileURLReq, clearDistListReq, clearDownloadFileReq, clearEditBillingStatus, clearGenPrintUrlReq, clearGetAllTxn, clearGetTxnReport, clearListPrinterId, clearModifyTxn, clearPassKeyReq, clearPostRefFileReq, clearPostTxn, clearPrePrintZipReq, clearPrinterDashboard, clearPrintId, clearPrintReadyReq, clearPrintStatusReq, clearProfileReq, clearRefFileListReq, clearRefStatusReq, clearRegisterPostReq, clearStateListReq, clearSubFolCountListReq, clearSuperAdminDashboard, clearSuperAdminTrendSts, clearTalukaAdmListReq, clearTalukaListReq, clearUploadImageReq, clearUserExistReq, updateAgntListReq, updateAgntReq, updateAWSFilesUpload, updateAWSFolderList, updateBilling, updateCardCountStateWise, updateCardDashboard, updateCardMassStatus, updateCardPrintReadyReq, updateCardStatusReq, updateCardTrendStatus, updateCheckRefFileReq, updateContactUsPostReq, updateData, updateDelFileURLReq, updateDistListReq, updateDownloadFileReq, updateEditBillingStatus, updateFileURLReq, updateForgotPassword, updateGenPrintUrlReq, updateGetAllTxn, updateGetTxnReport, updateListPrinterId, updateModifyTxn, updatePassKeyReq, updatePostRefFileReq, updatePostTxn, updatePrePrintZipReq, updatePrinterDashboard, updatePrintId, updatePrintReadyReq, updatePrintStatusReq, updateProfile, updateRefFileListReq, updateRefStatusReq, updateRegisterPostReq, updateStateListReq, updateSubFolCountListReq, updateSuperAdminDashboard, updateSuperAdminTrendSts, updateTalukaAdmListReq, updateTalukaListReq, updateToken, updateUploadImageReq, updateUserExistReq,
} from './actions';

export const initialState: YoCardAppState = {
  data: {
    show: '',
  },
  contactUs: {
    message: '',
    errorMessage: '',
    error: false,
  },
  registerUser: {
    message: '',
    data: '',
    error: false,
  },
  genToken: {
    token: '',
    message: '',
    isError: false,
  },
  profileData: {
    isError: false,
    message: '',
    profileData: {
      fNme: '',
      uNme: '',
      lNme: '',
      ph: '',
      eId: '',
      gnd: '',
      UserCd: '',
      UserTp: '',
      isPKReset: false,
      lgnSts: '',
      avatar: '',
      st: '',
    },
  },
  forgotPassKeyData: {
    forgotPasswordData: '',
    message: '',
    isError: false,
  },
  updateFileURL: {
    url: '',
    isError: false,
    message: '',
  },
  updatePassKey: {
    message: '',
    error: false,
  },
  updateProfileImage: {
    message: '',
    error: false,
  },
  getStateMetaList: {
    tCnt: 0,
    fCnt: 0,
    stData: [],
    isError: false,
    message: '',
  },
  getDistMetaList: {
    tCnt: 0,
    fCnt: 0,
    distData: [],
    isError: false,
    message: '',
  },
  getAgntList: {
    tCnt: 0,
    fCnt: 0,
    agntData: [],
    isError: false,
    message: '',
  },
  updateAgntStatus: {
    message: '',
    error: false,
    data: '',
  },
  updateAWSFilesData: {
    success: [],
    failed: [],
    message: '',
  },
  addPostRefFileData: {
    message: '',
    error: false,
  },
  getRefFileList: {
    tCnt: 0,
    fCnt: 0,
    refData: [],
    lastEvalKey: {
      CardId: '',
      CardTp: '',
      cSts: '',
    },
    isError: false,
    message: '',
  },
  getCheckRefFile: {
    tCnt: 0,
    fCnt: 0,
    refFileData: [],
    isError: false,
    message: '',
  },
  putPrintReadyData: {
    message: '',
    error: false,
  },
  refStatusList: {
    tCnt: 0,
    fCnt: 0,
    lastEvalKey: {
      CardId: '',
      CardTp: '',
      cSts: '',
    },
    refData: [],
    isError: false,
    message: '',
  },
  genPrintUrl: {
    message: '',
    isError: false,
    url: '',
  },
  cardStatus: {
    id: '',
    message: '',
    error: false,
  },
  printReady: {
    message: '',
    error: false,
  },
  postPrintStatus: {
    message: '',
    error: false,
    errorMessage: '',
  },
  getTalukaMetaList: {
    tCnt: 0,
    fCnt: 0,
    talukaData: [],
    isError: false,
  },
  getTalukaAdmMetaList: {
    tCnt: 0,
    fCnt: 0,
    talukas: [],
    isError: false,
  },
  getTalukaLocCountMetaList: {
    tCnt: 0,
    fCnt: 0,
    talukaData: [],
    isError: false,
    message: '',
  },
  awsFoldersList: {
    awsDir: {
      st: '',
      distDet: [],
    },
    message: '',
  },
  delPrintUrl: {
    message: '',
    error: false,
    data: '',
  },
  prePrintZipReady: {
    message: '',
    error: false,
    id: '',
  },
  getSubFolderList: {
    printDet: [{
      PrntId: '',
      PrntTp: '',
      updtCnt: 0,
      tCnt: '0',
      courId: '',
      cSts: '',
      updtOn: '',
      costCrd: '',
      cStsV: '',
      gstCrd: '',
      gstPrint: '',
      costVendor1: '',
      costPrint: '',
      gstVendor1: '',
      sndrNme: '',
      aCmnt: '',
      miscCst: '',
      courTp: '',
      distDet: {
        distNme: '',
        stCd: '',
        stNme: '',
      },
      prntUsrCd: {
        ph: '',
        st: '',
        UserCd: '',
        lNme: '',
        fNme: '',
        eId: '',
      },
    }],
    isError: false,
    message: '',
  },
  checkUserExist: {
    data: {
      stData: {
        stNme: '',
        stCd: '',
      },
      sts: false,
    },
    message: '',
    error: false,
  },
  downloadFiles: {
    message: '',
    url: '',
  },
  updateCardMassStatus: {
    message: '',
    error: false,
    data: '',
  },
  getCardCountStateWise: {
    message: '',
    isError: false,
    data: {
      tCnt: 0,
      data: [{
        cnt: 0,
        st: '',
        currentSts: '',
      }],
    },
  },
  getCardDashboard: {
    data: {
      oprSts: {
        tCnt: 0,
        fCnt: 0,
        agntData: [],
      },
      stsCnt: {
        errCnt: 0,
        progCnt: 0,
        compCnt: 0,
      },
    },
    isError: false,
    message: '',
  },
  getCardTrendStatus: {
    data: [{
      dt: '',
      statusData: [
        {
          cSts: '',
          tCnt: 0,
          dist: '',
          stsVal: '',
        },
      ],
    }],
    isError: false,
    message: '',
  },
  getAllPrinterId: {
    data: [],
    isError: false,
    message: '',
  },
  createPrintId: {
    message: '',
    error: false,
  },
  getPrinterDashboard: {
    tCnt: 0,
    fCnt: 0,
    prntData: [
      {
        PrntTp: '',
        PrntId: '',
        cSts: '',
        distNme: '',
        stCd: '',
        stNme: '',
        tCnt: 0,
        fCnt: 0,
      },
    ],
    isError: false,
    message: '',
  },
  superAdminDashboard: {
    data: {
      stData: [
        {
          stCd: '',
          nme: '',
          distCd: [{
            distCd: '',
            nme: '',
            tCnt: '',
          }],
        },
      ],
      crntSts: {
        progCnt: 0,
        compCnt: 0,
        errCnt: 0,
      },
      usrCnt: [
        {
          uCnt: 0,
          st: '',
        },
      ],
    },
    isError: false,
    message: '',
  },
  superAdminTrendSts: {
    data: [{
      st: '',
      dt: '',
      statusData: [
        {
          cSts: '',
          tCnt: 0,
          dist: '',
          stsVal: '',
        },
      ],
    }],
    isError: false,
    message: '',
  },
  billingStatus: {
    data: [
      {
        PrntId: '',
        stData: {
          distNme: '',
          stCd: '',
          stNme: '',
        },
        PrntTp: '',
        courId: '',
        costPrint: '',
        stsClient: '',
        stsVendor1: '',
        stsPrnt: '',
        gstVendor1: '',
        stsZameer: '',
        cSts: '',
        updtOn: '',
        prntUsrCd: {
          st: '',
          ph: '',
          UserCd: '',
          lNme: '',
          fNme: '',
          eId: '',
        },
        updtCnt: 0,
        costVendor1: '',
        tCnt: 0,
        costCrd: '',
        gstCrd: '',
        gstPrint: '',
        cardCost: '',
        gstCardCost: '',
        tCrdCost: '',
        tPrntCost: '',
        tVendor1Cost: '',
        costWDS: '',
        gstWDS: '',
        tWDSCost: '',
        costZameer: '',
        gstZameer: '',
        tZameerCost: '',
        tWDSProf: '',
        gstWDSProf: '',
        costWDSProf: '',
        courURL: '',
        sndrNme: '',
        aCmnt: '',
        balAmnt: '',
        miscCst: '',
        courTp: '',
        courTpVal: '',
      },
    ],
    isError: false,
    message: '',
  },
  editBillingStatus: {
    message: '',
    error: false,
    id: '',
  },
  getAllTxn: {
    data: [],
    isError: false,
    message: '',
  },
  createTxn: {
    message: '',
    error: false,
  },
  updateTxn: {
    message: '',
    error: false,
  },
  getTxnReport: {
    data: {
      client: 0,
      printer: 0,
      rzEnt: 0,
      vendor1: 0,
    },
    isError: false,
    message: '',
  },
};

export type AllAction = ActionType<typeof actions>;
const yoCardReducer: Reducer<YoCardAppState, AllAction> = (state: YoCardAppState = initialState, action: AllAction): YoCardAppState => {
  switch (action.type) {
    case getType(updateData):
      return {
        ...state,
        data: {
          show: action.payload,
        },
      };
    case getType(updateContactUsPostReq):
      return {
        ...state,
        contactUs: {
          message: action.payload.message,
          errorMessage: action.payload.errorMessage,
          error: action.payload.error,
        },
      };
    case getType(clearContactUsPostReq):
      return {
        ...state,
        contactUs: initialState.contactUs,
      };
    case getType(updateRegisterPostReq):
      return {
        ...state,
        registerUser: {
          message: action.payload.message,
          data: action.payload.data,
          error: action.payload.error,
        },
      };
    case getType(clearRegisterPostReq):
      return {
        ...state,
        registerUser: initialState.registerUser,
      };
    case getType(updateToken):
      return {
        ...state,
        genToken: {
          token: action.payload.token,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(updateProfile):
      return {
        ...state,
        profileData: {
          isError: action.payload.isError,
          message: action.payload.message,
          profileData: {
            fNme: action.payload.profileData.fNme,
            uNme: action.payload.profileData.uNme,
            lNme: action.payload.profileData.lNme,
            ph: action.payload.profileData.ph,
            eId: action.payload.profileData.eId,
            UserCd: action.payload.profileData.UserCd,
            UserTp: action.payload.profileData.UserTp,
            avatar: action.payload.profileData.avatar,
            gnd: action.payload.profileData.gnd,
            isPKReset: action.payload.profileData.isPKReset,
            lgnSts: action.payload.profileData.lgnSts,
            st: action.payload.profileData.st,
          },
        },
      };
    case getType(clearProfileReq):
      return {
        ...state,
        profileData: initialState.profileData,
      };
    case getType(updateForgotPassword):
      return {
        ...state,
        forgotPassKeyData: {
          forgotPasswordData: action.payload.forgotPasswordData,
          message: action.payload.message,
          isError: action.payload.isError,
        },
      };
    case getType(updateFileURLReq):
      return {
        ...state,
        updateFileURL: {
          url: action.payload.url,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(updatePassKeyReq):
      return {
        ...state,
        updatePassKey: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearPassKeyReq):
      return {
        ...state,
        updateFileURL: initialState.updateFileURL,
      };
    case getType(updateUploadImageReq):
      return {
        ...state,
        updateProfileImage: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearUploadImageReq):
      return {
        ...state,
        updateProfileImage: initialState.updateProfileImage,
      };
    case getType(updateStateListReq):
      return {
        ...state,
        getStateMetaList: {
          isError: action.payload.isError,
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          stData: action.payload.stData,
          message: action.payload.message,
        },
      };
    case getType(clearStateListReq):
      return {
        ...state,
        getStateMetaList: initialState.getStateMetaList,
      };
    case getType(updateDistListReq):
      return {
        ...state,
        getDistMetaList: {
          isError: action.payload.isError,
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          message: action.payload.message,
          distData: action.payload.distData,
        },
      };
    case getType(clearDistListReq):
      return {
        ...state,
        getDistMetaList: initialState.getDistMetaList,
      };
    case getType(updateAgntListReq):
      return {
        ...state,
        getAgntList: {
          isError: action.payload.isError,
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          agntData: action.payload.agntData,
          message: action.payload.message,
        },
      };
    case getType(clearAgntListReq):
      return {
        ...state,
        getAgntList: initialState.getAgntList,
      };
    case getType(updateAgntReq):
      return {
        ...state,
        updateAgntStatus: {
          message: action.payload.message,
          error: action.payload.error,
          data: action.payload.data,
        },
      };
    case getType(clearAgntReq):
      return {
        ...state,
        updateAgntStatus: initialState.updateAgntStatus,
      };
    case getType(updateAWSFilesUpload):
      return {
        ...state,
        updateAWSFilesData: {
          success: action.payload.success,
          failed: action.payload.failed,
          message: action.payload.message,
        },
      };
    case getType(clearAWSFilesUpload):
      return {
        ...state,
        updateAWSFilesData: initialState.updateAWSFilesData,
      };
    case getType(updatePostRefFileReq):
      return {
        ...state,
        addPostRefFileData: {
          error: action.payload.error,
          message: action.payload.message,
        },
      };
    case getType(clearPostRefFileReq):
      return {
        ...state,
        addPostRefFileData: initialState.addPostRefFileData,
      };
    case getType(updateRefFileListReq):
      return {
        ...state,
        getRefFileList: {
          isError: action.payload.isError,
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          lastEvalKey: action.payload.lastEvalKey,
          refData: action.payload.refData,
          message: action.payload.message,
        },
      };
    case getType(clearRefFileListReq):
      return {
        ...state,
        getRefFileList: initialState.getRefFileList,
      };
    case getType(updateCheckRefFileReq):
      return {
        ...state,
        getCheckRefFile: {
          isError: action.payload.isError,
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          refFileData: action.payload.refFileData,
          message: action.payload.message,
        },
      };
    case getType(clearCheckRefFileReq):
      return {
        ...state,
        getCheckRefFile: initialState.getCheckRefFile,
      };
    case getType(updatePrintReadyReq):
      return {
        ...state,
        putPrintReadyData: {
          error: action.payload.error,
          message: action.payload.message,
        },
      };
    case getType(clearPrintReadyReq):
      return {
        ...state,
        putPrintReadyData: initialState.putPrintReadyData,
      };
    case getType(updateRefStatusReq):
      return {
        ...state,
        refStatusList: {
          isError: action.payload.isError,
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          lastEvalKey: action.payload.lastEvalKey,
          refData: action.payload.refData,
          message: action.payload.message,
        },
      };
    case getType(clearRefStatusReq):
      return {
        ...state,
        refStatusList: initialState.refStatusList,
      };
    case getType(updateGenPrintUrlReq):
      return {
        ...state,
        genPrintUrl: {
          isError: action.payload.isError,
          message: action.payload.message,
          url: action.payload.url,
        },
      };
    case getType(clearGenPrintUrlReq):
      return {
        ...state,
        genPrintUrl: initialState.genPrintUrl,
      };
    case getType(updateCardPrintReadyReq):
      return {
        ...state,
        printReady: {
          error: action.payload.error,
          message: action.payload.message,
        },
      };
    case getType(clearCardPrintReadyReq):
      return {
        ...state,
        printReady: initialState.printReady,
      };
    case getType(updateCardStatusReq):
      return {
        ...state,
        cardStatus: {
          id: action.payload.id,
          error: action.payload.error,
          message: action.payload.message,
        },
      };
    case getType(clearCardStatusReq):
      return {
        ...state,
        cardStatus: initialState.cardStatus,
      };
    case getType(updatePrintStatusReq):
      return {
        ...state,
        postPrintStatus: {
          message: action.payload.message,
          errorMessage: action.payload.errorMessage,
          error: action.payload.error,
        },
      };
    case getType(clearPrintStatusReq):
      return {
        ...state,
        postPrintStatus: initialState.postPrintStatus,
      };
    case getType(updateTalukaListReq):
      return {
        ...state,
        getTalukaMetaList: {
          isError: action.payload.isError,
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          talukaData: action.payload.talukaData,
        },
      };
    case getType(clearTalukaListReq):
      return {
        ...state,
        getTalukaMetaList: initialState.getTalukaMetaList,
      };
    case getType(updateTalukaAdmListReq):
      return {
        ...state,
        getTalukaAdmMetaList: {
          isError: action.payload.isError,
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          talukas: action.payload.talukas,
        },
      };
    case getType(clearTalukaAdmListReq):
      return {
        ...state,
        getTalukaAdmMetaList: initialState.getTalukaAdmMetaList,
      };
    case getType(updateSubFolCountListReq):
      return {
        ...state,
        getSubFolderList: {
          isError: action.payload.isError,
          printDet: action.payload.printDet,
          message: action.payload.message,
        },
      };
    case getType(clearSubFolCountListReq):
      return {
        ...state,
        getSubFolderList: initialState.getSubFolderList,
      };
    case getType(updateAWSFolderList):
      return {
        ...state,
        awsFoldersList: {
          awsDir: action.payload.awsDir,
          message: action.payload.message,
        },
      };
    case getType(clearAWSFolderList):
      return {
        ...state,
        awsFoldersList: initialState.awsFoldersList,
      };
    case getType(updateDelFileURLReq):
      return {
        ...state,
        delPrintUrl: {
          message: action.payload.message,
          error: action.payload.error,
          data: action.payload.data,
        },
      };
    case getType(clearDelFileURLReq):
      return {
        ...state,
        delPrintUrl: initialState.delPrintUrl,
      };
    case getType(updatePrePrintZipReq):
      return {
        ...state,
        prePrintZipReady: {
          message: action.payload.message,
          error: action.payload.error,
          id: action.payload.id,
        },
      };
    case getType(clearPrePrintZipReq):
      return {
        ...state,
        prePrintZipReady: initialState.prePrintZipReady,
      };
    case getType(updateUserExistReq):
      return {
        ...state,
        checkUserExist: {
          message: action.payload.message,
          error: action.payload.error,
          data: action.payload.data,
        },
      };
    case getType(clearUserExistReq):
      return {
        ...state,
        checkUserExist: initialState.checkUserExist,
      };
    case getType(updateDownloadFileReq):
      return {
        ...state,
        downloadFiles: {
          message: action.payload.message,
          url: action.payload.url,
        },
      };
    case getType(clearDownloadFileReq):
      return {
        ...state,
        downloadFiles: initialState.downloadFiles,
      };
    case getType(updateCardMassStatus):
      return {
        ...state,
        updateCardMassStatus: {
          message: action.payload.message,
          data: action.payload.data,
          error: action.payload.error,
        },
      };
    case getType(clearCardMassStatus):
      return {
        ...state,
        updateCardMassStatus: initialState.updateCardMassStatus,
      };
    case getType(updateCardCountStateWise):
      return {
        ...state,
        getCardCountStateWise: {
          message: action.payload.message,
          data: action.payload.data,
          isError: action.payload.isError,
        },
      };
    case getType(clearCardCountStateWise):
      return {
        ...state,
        getCardCountStateWise: initialState.getCardCountStateWise,
      };
    case getType(updateCardDashboard):
      return {
        ...state,
        getCardDashboard: {
          message: action.payload.message,
          data: action.payload.data,
          isError: action.payload.isError,
        },
      };
    case getType(clearCardDashboard):
      return {
        ...state,
        getCardDashboard: initialState.getCardDashboard,
      };
    case getType(updateCardTrendStatus):
      return {
        ...state,
        getCardTrendStatus: {
          message: action.payload.message,
          data: action.payload.data,
          isError: action.payload.isError,
        },
      };
    case getType(clearCardTrendStatus):
      return {
        ...state,
        getCardTrendStatus: initialState.getCardTrendStatus,
      };
    case getType(updateListPrinterId):
      return {
        ...state,
        getAllPrinterId: {
          message: action.payload.message,
          data: action.payload.data,
          isError: action.payload.isError,
        },
      };
    case getType(clearListPrinterId):
      return {
        ...state,
        getAllPrinterId: initialState.getAllPrinterId,
      };
    case getType(updatePrintId):
      return {
        ...state,
        createPrintId: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearPrintId):
      return {
        ...state,
        createPrintId: initialState.createPrintId,
      };
    case getType(updatePrinterDashboard):
      return {
        ...state,
        getPrinterDashboard: {
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          prntData: action.payload.prntData,
          message: action.payload.message,
          isError: action.payload.isError,
        },
      };
    case getType(clearPrinterDashboard):
      return {
        ...state,
        getPrinterDashboard: initialState.getPrinterDashboard,
      };
    case getType(updateSuperAdminDashboard):
      return {
        ...state,
        superAdminDashboard: {
          data: action.payload.data,
          message: action.payload.message,
          isError: action.payload.isError,
        },
      };
    case getType(clearSuperAdminDashboard):
      return {
        ...state,
        superAdminDashboard: initialState.superAdminDashboard,
      };
    case getType(updateSuperAdminTrendSts):
      return {
        ...state,
        superAdminTrendSts: {
          data: action.payload.data,
          message: action.payload.message,
          isError: action.payload.isError,
        },
      };
    case getType(clearSuperAdminTrendSts):
      return {
        ...state,
        superAdminTrendSts: initialState.superAdminTrendSts,
      };
    case getType(updateBilling):
      return {
        ...state,
        billingStatus: {
          data: action.payload.data,
          message: action.payload.message,
          isError: action.payload.isError,
        },
      };
    case getType(clearBilling):
      return {
        ...state,
        billingStatus: initialState.billingStatus,
      };
    case getType(updateEditBillingStatus):
      return {
        ...state,
        editBillingStatus: {
          error: action.payload.error,
          message: action.payload.message,
          id: action.payload.id,
        },
      };
    case getType(clearEditBillingStatus):
      return {
        ...state,
        editBillingStatus: initialState.editBillingStatus,
      };
    case getType(updatePostTxn):
      return {
        ...state,
        createTxn: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearPostTxn):
      return {
        ...state,
        createTxn: initialState.createTxn,
      };
    case getType(updateModifyTxn):
      return {
        ...state,
        updateTxn: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearModifyTxn):
      return {
        ...state,
        updateTxn: initialState.updateTxn,
      };
    case getType(updateGetAllTxn):
      return {
        ...state,
        getAllTxn: {
          data: action.payload.data,
          message: action.payload.message,
          isError: action.payload.isError,
        },
      };
    case getType(clearGetAllTxn):
      return {
        ...state,
        getAllTxn: initialState.getAllTxn,
      };
    case getType(updateGetTxnReport):
      return {
        ...state,
        getTxnReport: {
          data: action.payload.data,
          message: action.payload.message,
          isError: action.payload.isError,
        },
      };
    case getType(clearGetTxnReport):
      return {
        ...state,
        getTxnReport: initialState.getTxnReport,
      };
    default:
      return state;
  }
};
export default yoCardReducer;

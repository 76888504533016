import { PrinterDashboardResponse, PrinterDashboardResults } from './printer-dashboard.service.types';

export const PrinterDashboardTransform: (response: PrinterDashboardResponse) => PrinterDashboardResults = (printerDashboardResults) => {
  const { data, is_error, message } = printerDashboardResults;
  const result: PrinterDashboardResults = {
    tCnt: 0,
    fCnt: 0,
    prntData: [
      {
        PrntTp: '',
        PrntId: '',
        cSts: '',
        distNme: '',
        stCd: '',
        stNme: '',
        tCnt: 0,
        fCnt: 0,
      },
    ],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.tCnt = data.tCnt ?? 0;
    result.fCnt = data.fCnt ?? 0;
    result.prntData = data.prntData ?? [];
    result.message = 'executed';
  }
  return result;
};

import React from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import './public.scss';
import { PublicLogin } from '../../components/public/login/public-login/public.login.component';

export const LoginComponent: React.FC = () => {
  const history = useNavigate();
  const homePage = () => {
    history('/');
    window.scrollTo(0, 0);
  };
  return (
    <div className="mx-3">
      <div className="align-end my-2">
        <NavLink to="/#" onClick={homePage}>
          <img style={{ height: '40px', width: 'auto' }} src="/yoCard.png" alt="campusXcelLogo" />
        </NavLink>
      </div>
      <PublicLogin />
    </div>
  );
};
export default LoginComponent;

import { SuperAdminTrendStsResponse, SuperAdminTrendStsResults } from './superadmin-trend-sts.service.types';

export const SuperAdminTrendStsTransform: (response: SuperAdminTrendStsResponse) => SuperAdminTrendStsResults = (superAdminTrendStsResults) => {
  const { data, is_error, message } = superAdminTrendStsResults;
  const result: SuperAdminTrendStsResults = {
    data: [
      {
        st: '',
        dt: '',
        statusData: [
          {
            cSts: '',
            tCnt: 0,
            dist: '',
            stsVal: '',
          },
        ],
      },
    ],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data ?? [];
    result.message = 'executed';
  }
  return result;
};

/* eslint-disable jsx-a11y/control-has-associated-label */
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Tooltip } from 'reactstrap';

interface TableProps {
  data: {
    dt: string;
    statusData: {
      cSts: string;
      tCnt: number;
      dist: string;
      stsVal: string;
    }[];
  }[];
}

const STAdminTable: React.FC<TableProps> = ({ data }) => {
  const [tooltipOpen, setTooltipOpen] = React.useState<Record<string, boolean>>({});

  const toggle = (target: string) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };
  if (!data || data.length === 0) {
    return null;
  }

  const uniqueSt = Array.from(new Set(data.flatMap((row) => row.statusData.map((status) => status.dist))));
  const uniqueDt = Array.from(new Set(data.map((row) => row.dt)));

  return (
    <table className="styled-table">
      <thead>
        <tr>
          <th>District</th>
          {uniqueDt.map((dt) => (
            <React.Fragment key={`dt-${dt}`}>
              <th colSpan={2}>{dt}</th>
            </React.Fragment>
          ))}
        </tr>
        <tr>
          <th />
          {uniqueDt.map((dt) => (
            <React.Fragment key={`cSts-${dt}`}>
              <th>Status</th>
              <th>T Count</th>
            </React.Fragment>
          ))}
        </tr>
      </thead>
      <tbody>
        {uniqueSt.map((dist) => (
          <tr key={`dist-${dist}`}>
            <td>{dist}</td>
            {uniqueDt.map((dt) => {
              const row = data.find((r) => r.dt === dt);
              const status = row?.statusData.find((s) => s.dist === dist);
              const rowIndex = row !== undefined ? data.indexOf(row) : 0;
              const target = `tooltip-${dt}-${dist}-${rowIndex}`;
              return (
                <React.Fragment key={`cell-${dt}-${dist}`}>
                  <td>
                    {status ? (
                      <span className="stadmincard-dashboard-header">
                        {status.cSts}
                        <Button className="button-icon" id={target}>
                          <FontAwesomeIcon className="info-stadm-icon my-1" icon={faCircleInfo} />
                        </Button>
                        <Tooltip
                          placement="top"
                          isOpen={tooltipOpen[target] || false}
                          target={target}
                          toggle={() => toggle(target)}
                        >
                          {status.stsVal}
                        </Tooltip>
                      </span>
                    ) : ''}
                  </td>
                  <td>{status ? status.tCnt : ''}</td>
                </React.Fragment>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default STAdminTable;

import { PrintStatusResponse, PrintStatusResults } from './update-print-status.service.types';

export const PrintStatusTransform: (response: PrintStatusResponse) => PrintStatusResults = (profileResults) => {
  const { data, is_error, message } = profileResults;
  const result :PrintStatusResults = {
    message: '',
    error: false,
    errorMessage: '',
  };
  if (is_error) {
    result.error = is_error;
    // result.errorMessage = 'Backend Issue';
    result.errorMessage = message;
  } else {
    result.message = data.message;
  }
  return result;
};

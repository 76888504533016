import {
  faFilter, faPhone,
} from '@fortawesome/free-solid-svg-icons';
// faEnvelope, faPhone,
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import {
  Row, Col, Button, Input, Card, CardBody, Modal, ModalBody, CardImg,
} from 'reactstrap';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import {
  agntUpdtReq, clearAgntReq, getAgntListReq,
} from '../../../../store/yoCard/actions';
import { getTokenFromLocalStorage, getUserTpSessionStorage } from '../../../../utils/service/localstorage-service';
import { errorAlert } from '../../../../utils/alert';
import { APIHeader } from '../../../../utils/constants';
import LoaderData from '../../../../utils/loader';
import { AgntPutDataInput } from '../../../../services/sadmin/agents/update-status/update-status.types';
import { UserProps } from './user.type';

export const PendingUser: React.FC<UserProps> = ({ agntData }) => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const userTp = getUserTpSessionStorage();
  // const [isSentSucc, setIsSentSucc] = React.useState(false);
  // const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [loaderUpdateUser, setLoaderUpdateUser] = React.useState(false);
  const [isApproveConfirmed, setIsApproveConfirmed] = useState(false);
  const [isRejectConfirmed, setIsRejectConfirmed] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  // const [showAdmin, setShowAdmin] = useState(false);
  // const [showLoc, setShowLoc] = useState(false);
  const [modalConfirmApprove, setModalConfirmApprove] = useState(false);
  const [modalConfirmReject, setModalConfirmReject] = useState(false);
  const updateAgntPendingStatus = useSelector((state: RootState) => state.yoCard.updateAgntStatus);
  const [pendingAgnt, setPendingAgnt] = useState<AgntPutDataInput>({
    isAcs: '',
    lgnSts: '',
    userCd: '',
    reason: '',
    usrReqFor: '',
  });
  // const [popupAdminStates, setPopupAdminStates] = useState<boolean[]>([]);
  // const [popupLocStates, setPopupLocStates] = useState<boolean[]>([]);

  const handleConfApprove = (userCode: string, userType: string) => {
    setPendingAgnt({ ...pendingAgnt, userCd: userCode, usrReqFor: userType });
    setModalConfirmApprove(true);
  };

  const handleConfReject = (userCode: string) => {
    setPendingAgnt({ ...pendingAgnt, userCd: userCode });
    setModalConfirmReject(true);
  };

  // const handleShowAdmin = (index: number) => {
  //   const newPopupAdminStates = [...popupAdminStates];
  //   newPopupAdminStates[index] = !popupAdminStates[index];
  //   setPopupAdminStates(newPopupAdminStates);
  // };

  // const handleShowLocation = (index: number) => {
  //   const newPopupLocStates = [...popupLocStates];
  //   newPopupLocStates[index] = !popupLocStates[index];
  //   setPopupLocStates(newPopupLocStates);
  // };

  // const handleShowLocation = () => {
  //   setShowLoc(showLoc);
  // };

  const handleConfirmApprove = (status: string) => {
    if (status === 'approve') {
      setPendingAgnt({
        ...pendingAgnt, isAcs: 'True', lgnSts: 'APPR',
      });
      setIsApproveConfirmed(true);
    } else {
      setIsApproveConfirmed(false);
    }
    setModalConfirmApprove(false);
  };

  const handleConfirmReject = (status: string) => {
    if (status === 'reject') {
      setPendingAgnt({
        ...pendingAgnt, isAcs: 'False', lgnSts: 'NACC',
      });
      setIsRejectConfirmed(true);
    } else {
      setIsRejectConfirmed(false);
    }
    setModalConfirmReject(false);
  };
  React.useEffect(() => {
    if (isApproveConfirmed) {
      setLoaderUpdateUser(true);
      Promise.resolve(
        dispatch(clearAgntReq()),
      ).then(() => {
        dispatch(agntUpdtReq({
          requestType: APIHeader.REQ_UPDATE_AGNT_STS,
          userType: userTp,
          token: tokenData,
          status: pendingAgnt.lgnSts,
          isAccess: pendingAgnt.isAcs,
          userCd: pendingAgnt.userCd,
          usrReqFor: pendingAgnt.usrReqFor,
          reason: pendingAgnt.reason,
        }));
      });
    }
  }, [isApproveConfirmed]);

  React.useEffect(() => {
    if (isRejectConfirmed) {
      setLoaderUpdateUser(true);
      Promise.resolve(
        dispatch(clearAgntReq()),
      ).then(() => {
        dispatch(agntUpdtReq({
          requestType: APIHeader.REQ_UPDATE_AGNT_STS,
          userType: userTp,
          token: tokenData,
          status: pendingAgnt.lgnSts,
          isAccess: pendingAgnt.isAcs,
          userCd: pendingAgnt.userCd,
          usrReqFor: pendingAgnt.usrReqFor,
          reason: pendingAgnt.reason,
        }));
      });
    }
  }, [isRejectConfirmed]);

  React.useEffect(() => {
    if (updateAgntPendingStatus.error && updateAgntPendingStatus.message !== '') {
      setLoaderUpdateUser(false);
      setAlertMsg(updateAgntPendingStatus.message);
      setErrorShowAlert(true);
    }
  }, [updateAgntPendingStatus.error, updateAgntPendingStatus.message]);
  React.useEffect(() => {
    if (!updateAgntPendingStatus.error && updateAgntPendingStatus.message === 'executed') {
      setLoaderUpdateUser(false);
      Promise.resolve(
        dispatch(getAgntListReq({
          userType: userTp,
          requestType: APIHeader.REQ_GET_ALL_AGNT_STS,
          status: 'PADM',
          // limit: ApiLIMIT.LIMIT,
          token: tokenData,
          // searchData: '',
        })),
      ).then(() => {
        dispatch(clearAgntReq());
      });
    }
  }, [updateAgntPendingStatus.error, updateAgntPendingStatus.message]);

  // const handleAdminMouseEnter = (index: number) => {
  //   const newPopupAdminStates = [...popupAdminStates];
  //   newPopupAdminStates[index] = true;
  //   setPopupAdminStates(newPopupAdminStates);
  // };

  // const handleAdminMouseLeave = (index: number) => {
  //   const newPopupAdminStates = [...popupAdminStates];
  //   newPopupAdminStates[index] = false;
  //   setPopupAdminStates(newPopupAdminStates);
  // };

  // const handleLocMouseEnter = (index: number) => {
  //   const newPopupLocStates = [...popupLocStates];
  //   newPopupLocStates[index] = true;
  //   setPopupLocStates(newPopupLocStates);
  // };

  // const handleLocMouseLeave = (index: number) => {
  //   const newPopupLocStates = [...popupLocStates];
  //   newPopupLocStates[index] = false;
  //   setPopupLocStates(newPopupLocStates);
  // };

  return (
    <Row>
      { loaderUpdateUser ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Row className="pt-3 form-card-header my-2">
        <Col lg="6" xs="8">
          <div className="align-start mx-1">
            <Input
              placeholder="search..."
              className="yocard-input-oval-search mb-3"
            />
          </div>
        </Col>
        <Col lg="6" xs="4">
          <div className="align-end">
            <Button className="button-icon">
              <span className="text-lite-grey">
                <FontAwesomeIcon className="quest-menu-icon mx-1" icon={faFilter} />
              </span>
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="mx-1">
        {agntData && agntData.agntData && agntData.agntData.length > 0
          ? agntData.agntData.map((agent, index) => (
            <Col lg={{ size: 6, order: 1 }} md={{ size: 6, order: 1 }} xs={{ order: 1 }} key={`pending-${index.toString()}`}>
              <Card className="form-card-shadow my-2">
                <CardBody>
                  <Row>
                    <Col xs="12">
                      <div className="align-start">
                        <div>
                          <CardImg alt="logo" className="img-fluid rounded-circle avatar-cards" src={agent.avatar} />
                          <div className="align-start pt-2">
                            {agent.UserTp === 'STADM' ? <span className="box-user-type">State Admin</span> : <span className="box-user-type">State Operator</span>}
                          </div>
                        </div>
                        <div className="ml-1 pt-1">
                          <div className="align-start">
                            <span className="super-user-name">{agent.fNme}{' '}{agent.lNme}</span>
                          </div>
                          <div className="align-start">
                            <span className="input-checkbox-text-grey">
                              <FontAwesomeIcon icon={faEnvelope} className="text-lite-grey mr" />
                              {agent.eId}
                            </span>
                          </div>
                          <div className="align-start">
                            <span className="input-checkbox-text-grey">
                              <FontAwesomeIcon icon={faPhone} className="text-lite-grey mr" />
                              +91 {agent.ph}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="align-end pt-2">
                    <Button className="yocard-button-link mx-1" onClick={() => handleConfReject(agent.UserCd)}>
                      <div className="mx-1">Reject</div>
                    </Button>
                    <Button className="yocard-button-oval mx-1" onClick={() => handleConfApprove(agent.UserCd, agent.UserTp)}>
                      <div className="mx-1">Approve</div>
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          )) : <div className="pt-4 align-center"><span className="form-collapse-card-header">No User Found</span></div>}
      </Row>
      <Modal isOpen={modalConfirmApprove}>
        <ModalBody>
          <span className="mx-2 input-checkbox-text-grey">Do you really want to Approve ?</span>
          <div className="align-end pt-3">
            <Button className="yocard-button-oval mx-1" onClick={() => handleConfirmApprove('cancel')}>
              cancel
            </Button>
            <Button className="yocard-button-oval mx-1" onClick={() => handleConfirmApprove('approve')}>
              Approve
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalConfirmReject}>
        <ModalBody>
          <span className="mx-2 input-checkbox-text-grey">Do you really want to Revoke Access ?</span>
          <div className="align-end pt-3">
            <Button className="yocard-button-oval mx-1" onClick={() => handleConfirmReject('cancel')}>
              cancel
            </Button>
            <Button className="yocard-button-oval mx-1" onClick={() => handleConfirmReject('reject')}>
              Reject
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Row>
  );
};
export default PendingUser;

import React, { useState } from 'react';
import { RootState } from 'typesafe-actions';
import Chart, {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js/auto';
import { useDispatch, useSelector } from 'react-redux';
import { Doughnut } from 'react-chartjs-2';
import './sadmin.scss';
import {
  Button,
  Col, Input, Row,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { successAlert, errorAlert } from '../../../utils/alert';
import LoaderData from '../../../utils/loader';
import { getTokenFromLocalStorage, getUserTpSessionStorage } from '../../../utils/service/localstorage-service';
import {
  clearSuperAdminDashboard, clearSuperAdminTrendSts, getSuperAdminDashboard, getSuperAdminTrendSts,
} from '../../../store/yoCard/actions';
import { APIHeader } from '../../../utils/constants';
import { StateData, StatusCountData, UserCountData } from '../../../services/reports-all/superadmin-dashboard/superadmin-dashboard/superadmin-dashboard.types';
import { SATrendResult } from '../../../services/reports-all/superadmin-dashboard/superadmin-trend-status/superadmin-trend-sts.types';
import LineChartComponent from './line-chart';
import SAdminTable from './sadmin-card-table';
// import BillingTable from './billing-table';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  // chartTrendline,
);

export const SuperAdminDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const userTp = getUserTpSessionStorage();
  const tokenData = getTokenFromLocalStorage();
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [loaderAdmin, setLoaderAdmin] = useState(false);
  const superAdminResponse = useSelector((state: RootState) => state.yoCard.superAdminDashboard);
  const superAdminTrendStsResponse = useSelector((state: RootState) => state.yoCard.superAdminTrendSts);
  const today = new Date().toISOString().split('T')[0];
  const init_today = new Date();
  init_today.setDate(init_today.getDate() - 7);
  const lastSeventhDay = init_today.toISOString().split('T')[0];
  const [inputDate, setInputDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const chartRef = React.useRef<HTMLCanvasElement | null>(null);
  const chartInstance = React.useRef<Chart | null>(null);
  // const data = [402330, 99877, 67811, 2000, 700, 30000];
  const order: (keyof StatusCountData)[] = ['progCnt', 'compCnt'];
  const [dataArrayDough, setDataArrayDough] = useState<number[]>([]);
  const [, setStateLabel] = useState<string[]>([]);
  const [, setData] = useState<number[]>([]);
  const [stateCount, setStateCount] = useState<StateData[]>([]);
  const [userStatus, setUserStatus] = useState<UserCountData[]>([]);
  const [trendStatus, setTrendStatus] = useState<SATrendResult[]>([{
    st: '',
    dt: '',
    statusData: [
      {
        cSts: '',
        tCnt: 0,
        dist: '',
        stsVal: '',
      },
    ],
  }]);
  // const dataArrayDough = [300000, 450000, 15000];
  // const labels = ['Tamil Nadu', 'Karnatka', 'Goa', 'Telangna', 'Maharashtra', 'Bihar'];
  // const userInfo = [{ st: 'Tamil Nadu', cnt: 380 }, { st: 'Karnatka', cnt: 180 }, { st: 'Goa', cnt: 80 }, { st: 'Maharshtra', cnt: 1280 }];

  const handleFieldChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputDate(e.target.value);
  };

  // const statusDataArray: StatusData[] = trendStatus.map((trend) => ({
  //   dt: trend.dt,
  //   st: trend.st,
  //   statusData: trend.statusData.map((district) => ({
  //     dist: district.dist,
  //     cSts: district.dcStst, // Adjust property names if needed
  //     tCnt: district.tCnt,
  //     stsVal: district.stsVal,
  //   })),
  // }));

  const dataDough = {
    labels: ['In-Progress', 'Completed'],
    datasets: [
      {
        data: dataArrayDough,
        backgroundColor: ['#9366d2', '#11404D'],
        hoverBackgroundColor: ['#9366d2', '#11404D'],
      },
    ],
  };
  const optionsDough = {
    plugins: {
      color: '#FAFAFA',
      tooltip: {
        mode: 'index' as any,
        intersect: true,
        backgroundColor: '#383838 ',
        borderColor: '#959191',
        borderWidth: 1,
        titleFont: {
          size: 13,
          weight: 'bold',
        },
        bodyFont: {
          size: 13,
        },
        callbacks: {
          title: (context: any[]) => {
            if (context.length > 0) {
              const { dataIndex } = context[0];
              return dataDough.labels[dataIndex];
            }
            return '';
          },
          label: (context: any) => {
            const label = context.dataset.label || '';
            const value = context.parsed || 0;
            return `${label}: ${value}`;
          },
        },
      },
    },
  };

  React.useEffect(() => {
    const distCdTotalMap = new Map<string, number>();
    const newLabels: string[] = [];
    const newData: number[] = [];
    stateCount.forEach((state) => {
      if (state.distCd && state.distCd.length > 0) {
        if (!distCdTotalMap.has(state.stCd)) {
          distCdTotalMap.set(state.stCd, 0);
        }

        state.distCd.forEach((district) => {
          const tCntAsNumber = parseInt(district.tCnt, 10) || 0; // Convert tCnt to a number
          distCdTotalMap.set(state.stCd, (distCdTotalMap.get(state.stCd) ?? 0) + tCntAsNumber);
        });
      } else {
        // Handle the case where a state has no distCd property
        distCdTotalMap.set(state.stCd, 0);
      }
    });

    // Extracting data from the map

    distCdTotalMap.forEach((total, state) => {
      newLabels.push(state);
      newData.push(total);
    });

    // Clear the existing data in labels and data state
    setStateLabel(newLabels);
    setData(newData);

    if (chartRef.current) {
      if (!chartInstance.current) {
        const ctx = chartRef.current.getContext('2d');
        if (ctx) {
          chartInstance.current = new Chart(ctx, {
            type: 'bar',
            data: {
              labels: newLabels, // Use the newLabels array
              datasets: [
                {
                  label: 'Cards State wise',
                  data: newData, // Use the newData array
                  backgroundColor: '#11407e',
                  borderColor: '#ffffff',
                  borderWidth: 0,
                },
              ],
            },
            options: {
              indexAxis: 'y',
              scales: {
                x: {
                  beginAtZero: true,
                },
              },
            },
          });
        }
      } else {
        // Update chart data if it already exists
        chartInstance.current.data.labels = newLabels; // Use the newLabels array
        chartInstance.current.data.datasets[0].data = newData; // Use the newData array
        chartInstance.current.update();
      }
    }
  }, [stateCount]);

  // React.useEffect(() => {
  //   if (chartRef.current) {
  //     if (!chartInstance.current) {
  //       const ctx = chartRef.current.getContext('2d');
  //       if (ctx) {
  //         chartInstance.current = new Chart(ctx, {
  //           type: 'bar',
  //           data: {
  //             labels,
  //             datasets: [
  //               {
  //                 label: 'Cards State wise',
  //                 data,
  //                 backgroundColor: '#11407e',
  //                 borderColor: '#ffffff',
  //                 borderWidth: 0,
  //               },
  //             ],
  //           },
  //           options: {
  //             indexAxis: 'y',
  //             scales: {
  //               x: {
  //                 beginAtZero: true,
  //               },
  //             },
  //           },
  //         });
  //       }
  //     } else {
  //       // Update chart data if it already exists
  //       chartInstance.current.data.labels = labels;
  //       chartInstance.current.data.datasets[0].data = data;
  //       chartInstance.current.update();
  //     }
  //   }
  // }, [data, labels]);

  React.useEffect(() => {
    if (userTp === 'SADM') {
      setLoaderAdmin(true);
      Promise.resolve(
        dispatch(getSuperAdminDashboard({
          userType: userTp,
          requestType: APIHeader.REQ_SUPERADMIN_DASHBOARD,
          crdDt: inputDate,
          token: tokenData,
        })),
      ).then(() => {
        dispatch(clearSuperAdminDashboard());
      });
    }
  }, [userTp]);

  const handleSearchCardsStsByDate = () => {
    setData([]);
    dispatch(getSuperAdminDashboard({
      userType: userTp,
      requestType: APIHeader.REQ_SUPERADMIN_DASHBOARD,
      crdDt: inputDate,
      token: tokenData,
    }));
  };

  React.useEffect(() => {
    if (superAdminResponse.isError && superAdminResponse.message !== '') {
      setLoaderAdmin(false);
      setErrorShowAlert(true);
      setAlertMsg(superAdminResponse.message);
      dispatch(clearSuperAdminDashboard());
    }
  }, [superAdminResponse.isError, superAdminResponse.message]);

  React.useEffect(() => {
    if (!superAdminResponse.isError && superAdminResponse.message === 'executed') {
      setLoaderAdmin(false);
      // setUserLabel(getCardsDashboardResponse.data.oprSts.agntData.map((agent) => `${agent.fNme} ${agent.lNme}`));
      // setData(getCardsDashboardResponse.data.oprSts.agntData.map((agent) => agent.dTCnt));
      setStateLabel(superAdminResponse.data.stData.map((state) => state.stCd));
      // setData(superAdminResponse.data.stData.map((agent) => agent.tCnt));
      setStateCount(superAdminResponse.data.stData);
      setDataArrayDough(order.map((property) => superAdminResponse.data.crntSts[property]));
      setUserStatus(superAdminResponse.data.usrCnt);
      dispatch(clearSuperAdminDashboard());
    }
  }, [superAdminResponse.isError, superAdminResponse.message]);

  // React.useEffect(() => {
  //   if (!superAdminResponse.isError && superAdminResponse.message === 'executed') {
  //     setLoaderAdmin(false);
  //     setStateLabel(superAdminResponse.data.stData.map((state) => state.stCd));
  //     setData(superAdminResponse.data.stData.map((agent) => agent.tCnt));
  //     setDataArrayDough(order.map((property) => superAdminResponse.data.crntSts[property]));
  //     setUserStatus(superAdminResponse.data.usrCnt);
  //     // setDashboardStatus(superAdminResponse.data.oprSts);
  //     dispatch(clearSuperAdminDashboard());
  //   }
  // }, [superAdminResponse.isError, superAdminResponse.message]);

  React.useEffect(() => {
    if (userTp === 'SADM') {
      setLoaderAdmin(true);
      Promise.resolve(
        dispatch(getSuperAdminTrendSts({
          userType: userTp,
          requestType: APIHeader.REQ_SUPERADMIN_TREND_STS,
          sDt: lastSeventhDay,
          eDt: today,
          stsTrnd: 'TREND',
          token: tokenData,
        })),
      ).then(() => {
        dispatch(clearSuperAdminTrendSts());
      });
    }
  }, [userTp]);

  React.useEffect(() => {
    if (superAdminTrendStsResponse.isError && superAdminTrendStsResponse.message !== '') {
      setLoaderAdmin(false);
      setErrorShowAlert(true);
      setAlertMsg(superAdminTrendStsResponse.message);
      dispatch(clearSuperAdminTrendSts());
    }
  }, [superAdminTrendStsResponse.isError, superAdminTrendStsResponse.message]);

  React.useEffect(() => {
    if (!superAdminTrendStsResponse.isError && superAdminTrendStsResponse.message === 'executed') {
      setLoaderAdmin(false);
      setTrendStatus(superAdminTrendStsResponse.data);
      dispatch(clearSuperAdminTrendSts());
    }
  }, [superAdminTrendStsResponse.isError, superAdminTrendStsResponse.message]);

  return (
    <div className="border-tabs mr">
      { loaderAdmin ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="mb-2 pt-3">
        <span className="yocard-header-with-bg">
          Quick View - Dashboard - New
        </span>
      </div>
      <Row className="pt-3 mx-2">
        <div
          style={{
            backgroundColor: '#f6eeeb', width: '100%', padding: '3px', marginTop: '10px',
          }}
          className="my-3 align-center"
        >
          <span className="mx-2">Card Status & User Information</span>
        </div>
        <Col lg="6" xs="12">
          <div className="pt-5" />
          <div className="sadmin-adjust-doughnut align-center">
            <Doughnut data={dataDough} options={optionsDough} />
          </div>
          <div className="pt-3 align-center">
            <span className="yocard-label-bg">Card Status</span>
          </div>
        </Col>
        <Col lg="4" xs="12">
          <div className="align-cente pt-5">
            <table>
              <thead>
                <tr>
                  <th style={{ backgroundColor: '#f6eeeb' }}><span className="dash-all-header-text">State</span></th>
                  <th style={{ backgroundColor: '#f6eeeb' }}><span className="dash-all-header-text">Total User</span></th>
                </tr>
              </thead>
              <tbody>
                {userStatus && userStatus.map((userData, index) => (
                  <tr key={`file-${index.toString()}`} style={{ backgroundColor: '#FAFCFB' }}>
                    <td className="filename" style={{ border: '1px dashed #D4F9F7' }}>
                      <span className="sadmincard-dashboard-header">{userData.st}</span>
                    </td>
                    <td className="filename" style={{ border: '1px dashed #D4F9F7' }}>
                      <span className="sadmincard-dashboard-header">{userData.uCnt}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pt-4 align-center">
            <span className="yocard-label-bg">User Information</span>
          </div>
        </Col>
      </Row>
      {/* <Row className="pt-5 mx-2  align-center">
        <div
          style={{
            backgroundColor: '#f6eeeb', width: '100%', padding: '3px', marginTop: '10px',
          }}
          className="my-3 align-center"
        >
          <span className="mx-2">Current Billing Status</span>
        </div>
        <Col lg="12">
          <BillingTable />
        </Col>
      </Row> */}
      <Row className="pt-5 mx-2  align-center">
        <div
          style={{
            backgroundColor: '#f6eeeb', width: '100%', padding: '3px', marginTop: '10px',
          }}
          className="my-3 align-center"
        >
          <span className="mx-2">District and Date wise Count</span>
        </div>
        <Col lg="12">
          <SAdminTable data={trendStatus} />
        </Col>
      </Row>
      <Row className="pt-5 mx-2">
        <div
          style={{
            backgroundColor: '#f6eeeb', width: '100%', padding: '3px', marginTop: '10px',
          }}
          className="my-3 align-center"
        >
          <span className="mx-2">State wise Count</span>
        </div>
        <Col xs="12">
          <Row className="pt-1 align-center">
            <Col lg="4">
              <div className="pt-1 align-center">
                <Input
                  max={today}
                  value={inputDate}
                  type="date"
                  name="refDt"
                  onChange={handleFieldChangeDate}
                  className="yocard-input-ref"
                />
                <Button className="button-icon" onClick={handleSearchCardsStsByDate}>
                  <span className="text-lite-grey">
                    <FontAwesomeIcon className="collapse-icon mx-1" icon={faMagnifyingGlass} />
                  </span>
                </Button>
              </div>
            </Col>
            <Col lg="12">
              <div className="align-center sadmin-horizontal-graph pt-3">
                <canvas ref={chartRef} />
              </div>
              <div className="pt-2 align-center">
                <span className="yocard-label-bg">State wise Card data</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="pt-2 mx-2 align-center">
        <div
          style={{
            backgroundColor: '#f6eeeb', width: '100%', padding: '3px', marginTop: '10px',
          }}
          className="my-3 align-center"
        >
          <span className="mx-2">Last 7 Days Trends per State</span>
        </div>
        <Col lg="11" xs="12">
          <div className="pt-5">
            {trendStatus.length > 0
              ? <LineChartComponent data={trendStatus} />
              : (
                <div className="pt-2 align-center my-5">
                  <span className="form-collapse-card-header">No Record Found</span>
                </div>
              )}
            {/* <Line data={dataLine} options={optionsCard} /> */}
          </div>
          <div className="pt-2 align-center">
            <span className="yocard-label-bg">Card progress Trend</span>
          </div>
        </Col>
      </Row>
      <div className="mb-3" />
    </div>
  );
};

export default SuperAdminDashboard;

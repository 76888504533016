import { PrintReadyResponse, PrintReadyResults } from './update-print-ready.service.types';

export const PrintReadyTransform: (response: PrintReadyResponse) => PrintReadyResults = (batchResults) => {
  const { is_error, message } = batchResults;
  const result: PrintReadyResults = {
    message: '',
    error: false,
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.message = 'executed';
  }
  return result;
};

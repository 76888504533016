import { ListTalukaCountResponse, ListTalukaCountResults } from './list-taluka-count.service.types';

export const ListTalukaCountTransform: (response: ListTalukaCountResponse) => ListTalukaCountResults = (listTalukaCntResults) => {
  const { data, is_error, message } = listTalukaCntResults;
  const result: ListTalukaCountResults = {
    tCnt: 0,
    fCnt: 0,
    talukaData: [],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.tCnt = data.tCnt;
    result.fCnt = data.fCnt;
    result.talukaData = data.talukaData;
    result.message = 'executed';
  }
  return result;
};

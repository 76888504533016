import { CardDashboardResponse, CardDashboardResults } from './card-dashboard.service.types';

export const CardDashboardTransform: (response: CardDashboardResponse) => CardDashboardResults = (cardDashboardResults) => {
  const { data, is_error, message } = cardDashboardResults;
  const result: CardDashboardResults = {
    data: {
      oprSts: {
        tCnt: 0,
        fCnt: 0,
        agntData: [
          {
            ph: '',
            UserCd: '',
            lNme: '',
            fNme: '',
            eId: '',
            tCnt: 0,
            dTCnt: 0,
          },
        ],
      },
      stsCnt: {
        errCnt: 0,
        progCnt: 0,
        compCnt: 0,
      },
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data.oprSts.tCnt = data.oprSts.tCnt ?? 0;
    result.data.oprSts.fCnt = data.oprSts.fCnt ?? 0;
    result.data.oprSts.agntData = data.oprSts.agntData ?? [];
    result.data.stsCnt.errCnt = data.stsCnt.errCnt ?? 0;
    result.data.stsCnt.progCnt = data.stsCnt.progCnt ?? 0;
    result.data.stsCnt.compCnt = data.stsCnt.compCnt ?? 0;
    result.message = 'executed';
  }
  return result;
};

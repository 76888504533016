import { PrintLinkResponse, PrintLinkResults } from './generate-print-link.service.types';

export const PrintLinkTransform: (response: PrintLinkResponse) => PrintLinkResults = (fileURLResults) => {
  const { data, is_error, message } = fileURLResults;
  const result :PrintLinkResults = {
    url: '',
    isError: false,
    message: '',
  };
  if (is_error) {
    result.message = message;
    result.isError = is_error;
  } else {
    result.url = data ?? '';
    result.isError = is_error;
    result.message = 'executed';
  }
  return result;
};

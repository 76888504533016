import React, { useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  Col, Input, Modal, ModalBody, ModalHeader, Row,
} from 'reactstrap';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileAlt, faXmarkSquare,
} from '@fortawesome/free-solid-svg-icons';
import './print.scss';
import LoaderData from '../../../../utils/loader';
import { ArrayFileData, CreateAWSFilesResult } from '../../../../services/upload-AWS-files/create-awsfiles.types';
import {
  clearAWSFilesUpload,
  clearCheckRefFileReq,
  clearDistListReq,
  clearRefFileListReq, getAWSFilesUpload, getCheckRefFileReq, getDistListReq,
} from '../../../../store/yoCard/actions';
import { getConfig } from '../../../../utils/config/config';
import { getTokenFromLocalStorage, getUserProfileSessionStorage, getUserTpSessionStorage } from '../../../../utils/service/localstorage-service';
import { APIHeader, CardTypeList } from '../../../../utils/constants';
import { errorAlert } from '../../../../utils/alert';
import PaginationButton from '../../../../utils/pagination/paginate-button';
import { DistResult } from '../../../../services/meta-data/list-district/list-dist.types';

const { AWS_CARD_ACTIVITY_BUCKET } = getConfig();

export const PrintRefUploadPdf: React.FC = () => {
  const dispatch = useDispatch();
  const userTp = getUserTpSessionStorage();
  const tokenData = getTokenFromLocalStorage();
  const userProfile = getUserProfileSessionStorage();
  const [fileSuccess, setFileSuccess] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [alertAPIMsg, setAlertAPIMsg] = useState('');
  const [alertMsg] = useState<CreateAWSFilesResult>();
  const [userId, setUserId] = useState<string>('');
  const [stateCd, setStateCd] = useState<string>('');
  const [uploadLoader, setUploadLoader] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<ArrayFileData[]>([]);
  const [dateData] = useState<string>(new Date().toISOString().split('T')[0]);
  const [isS3Upload, setIsS3Upload] = useState(false);
  const [loaderDist, setLoaderDist] = useState(false);
  const [distData, setDistData] = useState('');
  const [villageData, setVillageData] = useState('');
  const [cardTp, setCardTp] = useState('');
  const [distListData, setDistListData] = useState<DistResult[]>([]);
  const distResData = useSelector((state: RootState) => state.yoCard.getDistMetaList);
  const uploadFilesResponse = useSelector((state: RootState) => state.yoCard.updateAWSFilesData);
  // const getFileRefResponse = useSelector((state: RootState) => state.yoCard.getRefFileList);
  const checkFileRefResponse = useSelector((state: RootState) => state.yoCard.getCheckRefFile);
  // const anyFalseStatus = selectedFiles.some((data) => !data.status);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isApiCalled, setIsApiCalled] = useState(false);
  const isUploadDisabled = selectedFiles.length === 0 || isUploaded; // || anyFalseStatus;
  const itemsPerPage = 10;
  const [totalPages, setTotalPages] = useState(1);
  const [parentPage, setParentPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (parentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleRefData = selectedFiles.slice(startIndex, endIndex);

  const onNextPageClick = () => {
    console.log('NextPage');
  };

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.UserCd) {
      setUserId(userProfile && JSON.parse(userProfile).profileData.UserCd);
    }
  }, [userId, userProfile && JSON.parse(userProfile).profileData.UserCd]);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.st) {
      setStateCd(userProfile && JSON.parse(userProfile).profileData.st.stCd);
    }
  }, [userId, userProfile && JSON.parse(userProfile).profileData.st]);

  const converttoKb = (byteData:number) => {
    const kilobytes = byteData / 1024;
    return Math.ceil(kilobytes);
  };

  const handleClearRequest = () => {
    dispatch(clearCheckRefFileReq());
    dispatch(clearRefFileListReq());
    dispatch(clearAWSFilesUpload());
    setFileSuccess(false);
  };

  // const handleFieldChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setDateData(e.target.value);
  // };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsS3Upload(false);
    setIsUploaded(false);
    // setUploadLoader(true);
    const newFiles: File[] = Array.from(event.target.files || []);
    const newFileItems: ArrayFileData[] = newFiles.map((file, index) => ({
      id: index,
      file,
      status: false,
      loc: '',
    }));
    const nonDupRefFiles = newFileItems.filter((newFileItem) => {
      const existingFile = selectedFiles.find((selectedFile) => selectedFile.file.name === newFileItem.file.name);
      return !existingFile;
    });
    const sanitizedFiles = nonDupRefFiles.map((fileItem) => {
      const fileNameParts = fileItem.file.name.split('.');
      const fileExtension = fileNameParts.pop();
      const sanitizedFileName = fileNameParts.join('').replace(/[.\s\W]+/g, '');
      const sanitizedFullFileName = sanitizedFileName + (fileExtension ? `.${fileExtension}` : '');
      return {
        ...fileItem,
        file: new File([fileItem.file], sanitizedFullFileName, { type: fileItem.file.type }),
      };
    });

    setSelectedFiles([...selectedFiles, ...sanitizedFiles]);
  };

  React.useEffect(() => {
    if (checkFileRefResponse.isError && checkFileRefResponse.message !== '') {
      setUploadLoader(false);
      setAlertAPIMsg(checkFileRefResponse.message);
      setErrorShowAlert(true);
      // setFileSuccess(false);
    }
  }, [checkFileRefResponse.isError, checkFileRefResponse.message]);

  React.useEffect(() => {
    if (!checkFileRefResponse.isError && checkFileRefResponse.message === 'executed') {
      setUploadLoader(false);
      setIsApiCalled(true);
      setAlertAPIMsg('Updated Successfully');
      dispatch(clearCheckRefFileReq());
    }
  }, [checkFileRefResponse.isError, checkFileRefResponse.message]);

  const handleRemoveFile = (filenameToDelete: string) => {
    const updatedFiles = selectedFiles.filter((file) => file.file.name !== filenameToDelete);
    setSelectedFiles(updatedFiles);
  };

  const handleOnDismissAlert = () => {
    setSelectedFiles([]);
    setIsApiCalled(false);
  };

  const formatTimestamp = (timestamp: number) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const handleUpload = () => {
    setUploadLoader(true);
    Promise.resolve(
      dispatch(getAWSFilesUpload({
        bucketNme: AWS_CARD_ACTIVITY_BUCKET,
        fileDate: dateData,
        stCd: stateCd,
        cardTp,
        selectedFiles,
        requestType: '',
        distFolderId: distData,
        isZip: false,
      })),
    )
      .then(() => {
        dispatch(clearAWSFilesUpload());
      });
  };

  React.useEffect(() => {
    if (uploadFilesResponse.message === 'executed' && userTp === 'STADM') {
      setUploadLoader(true);
      const newApiArray = uploadFilesResponse.success.map((fileName) => ({
        fileName: fileName.replace(/\.[^/.]+$/, ''),
        sts: 'S3SS',
      }));
      uploadFilesResponse.failed.forEach((fileName) => {
        newApiArray.push({
          fileName: fileName.replace(/\.[^/.]+$/, ''),
          sts: 'S3ER',
        });
      });
      dispatch(getCheckRefFileReq({
        userType: userTp,
        requestType: APIHeader.REQ_CHECK_FILE_NAMES,
        sts: 'GNTD',
        isSts: 'getStsS3List',
        rsn: '',
        cardDt: dateData,
        token: tokenData,
        dist: distData,
        st: stateCd,
        inputS3Body: { refS3Files: newApiArray, village: villageData, cardTp },
      }));
      const updatedSelectedFiles = selectedFiles.map((fileData) => {
        if (uploadFilesResponse.success.includes(fileData.file.name)) {
          return { ...fileData, status: true };
        } if (uploadFilesResponse.failed.includes(fileData.file.name)) {
          return { ...fileData, status: false };
        }
        return fileData;
      });
      setSelectedFiles(updatedSelectedFiles);
      setIsS3Upload(true);
      setIsUploaded(true);
    } else {
      setUploadLoader(false);
      setIsUploaded(false);
    }
  }, [uploadFilesResponse.message, uploadFilesResponse.success, uploadFilesResponse.failed]);

  const distoptions = distListData && distListData.map((dist) => ({
    label: dist.nme,
    value: dist.MetaId,
  }));

  const selectedDistOptions = distoptions && distoptions.find(
    (option) => option.value === distData,
  );

  React.useEffect(() => {
    if (stateCd !== '') {
      setLoaderDist(true);
      dispatch(getDistListReq({
        requestType: APIHeader.REQ_GET_ALL_DIST,
        state: stateCd,
      }));
    }
  }, [stateCd]);

  React.useEffect(() => {
    if (distResData.isError && distResData.message !== '') {
      setLoaderDist(false);
      dispatch(clearDistListReq());
    }
  }, [distResData.isError, distResData.message]);

  React.useEffect(() => {
    if (!distResData.isError && distResData.message === 'executed') {
      setLoaderDist(false);
      setDistListData(distResData.distData);
      dispatch(clearDistListReq());
    }
  }, [distResData.isError, distResData.message]);

  const handleSelectDistrict = (e:any) => {
    setDistData(e.value);
  };

  const handleVillageChange = (e: any) => {
    setVillageData(e.target.value);
  };

  // Start Billing Card Type List

  const cardTpOptions = CardTypeList.map((cardDet) => ({
    label: cardDet.label,
    value: cardDet.value,
  }));

  const handleSelectCardTypeData = (e: any) => {
    if (e) {
      setCardTp(e.value);
    } else {
      setCardTp('');
    }
  };

  const selectedCardTypeOptions = cardTpOptions.find(
    (option) => option.value === cardTp,
  );
  // End Billing Card Type List

  return (
    <div className="border-tabs">
      { uploadLoader ? <LoaderData /> : null}
      {isApiCalled ? (
        <Alert color="info" isOpen={isApiCalled} toggle={handleOnDismissAlert}>
          {alertAPIMsg}
        </Alert>
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertAPIMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Row className="mx-2">
        {/* <Col lg="5">
          <div className="pt-4">
            <h6 className="input-header-text mx-1 my-1">Date</h6>
            <Input
              type="date"
              name="refDt"
              onChange={handleFieldChangeDate}
              className="yocard-input-ref"
            />
          </div>
        </Col> */}
        <Col lg="5">
          <div className="pt-1">
            <h6 className="input-header-text">District<span className="mandate-star">*</span></h6>
            <Select
              value={distData === '' ? null : selectedDistOptions}
              options={distoptions}
              onChange={handleSelectDistrict}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                menu: (base) => ({
                  ...base,
                  // position: 'relative',
                }),
                control: (base: any) => ({
                  ...base,
                  '&:hover': { borderColor: '#880000' },
                  border: '1px solid #DDE0E4',
                  minHeight: '40px',
                  borderRadius: '5px',
                  padding: 1,
                  fontSize: '15px',
                  fontWeight: 400,
                  color: '#575656',
                  boxShadow: 'none',
                  backgroundColor: '#fff',
                  '&:focus': {
                    borderColor: '#E1EDF8',
                    boxShadow: 'none',
                    color: '#575656',
                    background: '#fff',
                  },
                }),
                option: (provided: any, state: { isSelected: any; }) => ({
                  ...provided,
                  color: state.isSelected ? '#383838' : '#212121',
                  padding: 15,
                  textAlign: 'left',
                  background: state.isSelected ? '#FAFCFB' : '#fff',
                  '&:hover': {
                    background: '#FAFCFB',
                  },
                }),
              }}
              isLoading={loaderDist}
              noOptionsMessage={() => 'No District Found'}
              placeholder="Select District"
            />
          </div>
        </Col>
        <Col lg="3">
          <div className="pt-1">
            <h6 className="input-header-text">Card Type</h6>
            <Select
              value={selectedCardTypeOptions}
              options={cardTpOptions}
              isClearable
              onChange={handleSelectCardTypeData}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                menu: (base) => ({
                  ...base,
                  // position: 'absolute',
                  // overflow: 'visible',
                }),
                control: (base: any) => ({
                  ...base,
                  '&:hover': { borderColor: '#880000' },
                  border: '1px solid #DDE0E4',
                  width: '100%',
                  borderRadius: '5px',
                  fontSize: '15px',
                  fontWeight: 400,
                  color: '#575656',
                  boxShadow: 'none',
                  backgroundColor: '#fff',
                  '&:focus': {
                    borderColor: '#E1EDF8',
                    boxShadow: 'none',
                    color: '#575656',
                    background: '#fff',
                  },
                }),
                option: (provided: any, state: { isSelected: any; }) => ({
                  ...provided,
                  color: state.isSelected ? '#383838' : '#212121',
                  padding: 10,
                  textAlign: 'left',
                  background: state.isSelected ? '#FAFCFB' : '#fff',
                  '&:hover': {
                    background: '#FAFCFB',
                  },
                }),
              }}
            />
          </div>
        </Col>
        <Col lg="4">
          <div className="pt-1">
            <h6 className="input-header-text">Village/Town Name<span className="mandate-star">*</span></h6>
            <Input
              placeholder="Village Name"
              type="text"
              required
              className="yocard-input mx-1"
              value={villageData}
              onChange={handleVillageChange}
            />
          </div>
        </Col>
        <Col lg="12">
          <div className="stadmin-file-upload pt-4 mx-1">
            <span className="mandate-star">Upload Maximum 300 Files in One Batch</span>
            <div className="stadmin-file-upload-box">
              <Input
                accept=".pdf"
                type="file"
                disabled={dateData === '' || isApiCalled}
                id="fileRefupload"
                className="stadmin-file-upload-input"
                onChange={handleFileChange}
                multiple
                key={selectedFiles.length}
              />
              <span>Drag and drop or <span className="dcord-file-link">Choose your files</span></span>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mx-2">
        <div className="pt-4">
          <Card className="form-card-border">
            <CardBody>
              <Row>
                <Col lg="5" xs="5">
                  <span className="text-lite-grey ">Total Records: {selectedFiles.length}</span>
                </Col>
                <Col lg="7" xs="7">
                  <div className="align-end">
                    <PaginationButton
                      tCnt={selectedFiles.length}
                      itemsPerPage={itemsPerPage}
                      totalPages={totalPages === 0 ? 1 : totalPages}
                      setParentPage={setParentPage}
                      setTotalPages={setTotalPages}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      onNextPageClick={onNextPageClick}
                    />
                  </div>
                </Col>
              </Row>
              <div className="table-responsive">
                {selectedFiles.length === 0 ? (
                  <div className="pt-5 align-center">
                    <span className="form-collapse-card-header">No Record Found</span>
                  </div>
                )
                  : (
                    <table>
                      <thead>
                        <tr>
                          <th>File Name</th>
                          <th>Size</th>
                          <th>Status</th>
                          <th>Modified Time</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {visibleRefData && visibleRefData.map((data, index) => (
                          <tr key={`file-${index.toString()}`}>
                            <td data-name="filename" className="filename">
                              <div className="truncate-on-overflow">
                                <FontAwesomeIcon icon={faFileAlt} className="fa-file-alt mr-1" />
                                {data.file.name}
                              </div>
                            </td>
                            <td data-name="size" className="size">
                              {converttoKb(data.file.size)} kb
                            </td>
                            <td className="status">
                              {isS3Upload
                                ? data.status ? <span className="approved">Success</span> : <span className="decline">Failed</span> : null}
                              {/* {isS3Upload
                                ? data.status ? <span className="approved">Upload Success</span> : <span className="decline">Upload Failed</span>
                                : data.status ? <span className="approved">Valid Ref</span> : <span className="decline">Invalid Ref</span>} */}
                            </td>
                            <td data-name="modified" className="modified">
                              {formatTimestamp(data.file.lastModified)}
                            </td>
                            <td data-name="action" className="action">
                              {!data.status
                                ? <Button type="button" className="yocard-button-plain" onClick={() => handleRemoveFile(data.file.name)}>Delete</Button>
                                : null }
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) }
              </div>
            </CardBody>
            <CardFooter className="form-card-footer">
              <div className="align-end mx-1 my-3">
                <Button onClick={handleUpload} className="yocard-button-oval" disabled={isUploadDisabled || villageData === ''}>Upload</Button>
              </div>
            </CardFooter>
          </Card>
        </div>
      </Row>
      <div className="mb-3" />
      <Modal
        isOpen={fileSuccess}
        className="my-modal-container-likes"
      >
        <ModalHeader className="form-card-header">
          <div className="align-end">
            <Button className="button-icon" onClick={handleClearRequest}>
              <FontAwesomeIcon className="dashboard-close-button" icon={faXmarkSquare} />
              <span className="dashboard-close-text mx-1">Close</span>
            </Button>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="mx-2">
            {alertMsg && alertMsg.success.length > 0 && (
              <div className="">
                <span className="files-upload-text-success mb">Successfully uploaded({alertMsg && alertMsg.success.length} files)</span>
                <div className="pt-3 mx-2">
                  {alertMsg && alertMsg.success.map((filename) => (
                    <div className="file-atc-box" key={filename}>
                      <div className="file-image-success">
                        <FontAwesomeIcon icon={faFileAlt} className="fa-file-gen" />
                      </div>
                      <div className="file-detail">
                        <h6 className="input-header-text">{filename}</h6>
                        <p />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {alertMsg && alertMsg.failed.length > 0 && (
              <div className="">
                <span className="files-upload-text-error mb">Failed to upload files</span>
                <div className="pt-3 mx-2">
                  {alertMsg && alertMsg.failed.map((filename) => (
                    <div className="file-atc-box" key={filename}>
                      <div className="file-image-error">
                        <FontAwesomeIcon icon={faFileAlt} className="fa-file-gen" />
                      </div>
                      <div className="file-detail">
                        <h6 className="input-header-text">{filename}</h6>
                        <p />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PrintRefUploadPdf;

import { GetAWSSubFolderResponse, GetAWSSubFolderResults } from './get-subfolders.service.types';

export const GetAWSSubFolderTransform: (response: GetAWSSubFolderResponse) => GetAWSSubFolderResults = (createAWSFilesDBResults) => {
  const { awsDir, message } = createAWSFilesDBResults;
  const result: GetAWSSubFolderResults = {
    awsDir: {
      st: '',
      distDet: [],
    },
    message: '',
  };
  result.awsDir = awsDir;
  result.message = message;
  return result;
};

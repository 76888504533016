import { StaticConfig } from './config-type';

export const getConfig: () => StaticConfig = () => ({
  USERNAME: process.env.REACT_APP_USERNAME || '',
  PASSWORD: process.env.REACT_APP_PASSWORD || '',
  // CAND_USERNAME: process.env.REACT_APP_CAND_USERNAME || '',
  // CAND_PASSWORD: process.env.REACT_APP_CAND_PASSWORD || '',
  YOCARD_API_ROOT: process.env.REACT_APP_YOCARD_API_ROOT || '',
  YOCARD_API_VERSION: parseInt(process.env.REACT_APP_YOCARD_API_VERSION || '1', 10),
  REGION: process.env.REACT_APP_AWS_REGION || '',
  AWS_ACCESS_KEY: process.env.REACT_APP_AWS_ACCESS_KEY || '',
  AWS_SECRET: process.env.REACT_APP_AWS_SECRET || '',
  AWS_REGION: process.env.REACT_APP_AWS_REGION || '',
  AWS_AVATAR_BUCKET: process.env.REACT_APP_AWS_AVATAR_BUCKET || '',
  AWS_CARD_ACTIVITY_BUCKET: process.env.REACT_APP_AWS_CARD_ACTIVITY_BUCKET || '',
});

import { CheckUserResponse, CheckUserResults } from './check-user.service.types';

export const CheckUserTransform: (response: CheckUserResponse) => CheckUserResults = (CheckUserData) => {
  const { data, is_error, message } = CheckUserData;
  const result: CheckUserResults = {
    message: '',
    error: false,
    data: {
      stData: {
        stNme: '',
        stCd: '',
      },
      sts: false,
    },
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.data = data;
    result.message = 'executed';
  }
  return result;
};

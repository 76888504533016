import { GetTxnReportResponse, GetTxnReportResults } from './get-txn-report.service.types';

export const GetTxnReportTransform: (response: GetTxnReportResponse) => GetTxnReportResults = (getTxnReportResults) => {
  const { data, is_error, message } = getTxnReportResults;
  const result: GetTxnReportResults = {
    data: {
      client: 0,
      printer: 0,
      rzEnt: 0,
      vendor1: 0,
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data ?? [];
    result.message = 'executed';
  }
  return result;
};

import { CheckRefFileResponse, CheckRefFileResults } from './check-reffile.service.types';

export const CheckRefFileTransform: (response: CheckRefFileResponse) => CheckRefFileResults = (checkRefFileResults) => {
  const { data, is_error, message } = checkRefFileResults;
  const result: CheckRefFileResults = {
    tCnt: 0,
    fCnt: 0,
    refFileData: [],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.tCnt = data.tCnt;
    result.fCnt = data.fCnt;
    result.refFileData = data.refFileData;
    result.isError = is_error;
    result.message = 'executed';
  }
  return result;
};

import { getCommonApiToken } from '../../utils';
import { getConfig } from '../../utils/config/config';
import { ListRefFileOptions, ListRefFileResponse } from './list-reffile.service.types';

const { YOCARD_API_ROOT } = getConfig();

export const ListRefFileRequest = ({
  token, requestType, userType, dtRef, cSts, evalKey, limit, evalTp,
}: ListRefFileOptions): Promise<ListRefFileResponse> => {
  if (evalKey === '' && evalTp === '') {
    return getCommonApiToken(`${YOCARD_API_ROOT}?userTp=${userType}&dtRef=${dtRef}&cSts=${cSts}&limit=${limit}`, requestType, token);
  }
  return getCommonApiToken(`${YOCARD_API_ROOT}?userTp=${userType}&dtRef=${dtRef}&cSts=${cSts}&evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}`, requestType, token);
};

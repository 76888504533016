import { useNavigate } from 'react-router';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  DropdownComponent, DropdownDate,
} from 'react-dropdown-date';
import { RootState } from 'typesafe-actions';
import {
  Row, Col, CardBody, Card, Input, InputGroup, Button, Form, Label, Modal, ModalBody, CardImg, Spinner,
} from 'reactstrap';
import './register.scss';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { APIHeader } from '../../../utils/constants';
import { LoaderData } from '../../../utils/loader';
import { errorAlert, successAlert } from '../../../utils/alert';
import {
  checkUserExistReq,
  clearRegisterPostReq, clearUserExistReq, getAvatarPublish, getDistListReq, getStateListReq, registerReq,
} from '../../../store/yoCard/actions';
import { RegisterInput } from '../../../services/register/register.types';
import { ImageFileInput } from './image-post.types';
// import { DistResult } from '../../../services/meta-data/list-district/list-dist.types';
import { StateResult } from '../../../services/meta-data/list-state/list-state.types';
import { getConfig } from '../../../utils/config/config';
import { CheckUserData } from '../../../services/check_user_exist/check-user.types';
// import { TalukaAdmResult } from '../../../services/meta-data/list-taluka-adm/list-taluka-adm.types';

const { AWS_AVATAR_BUCKET } = getConfig();

export const RegisterAgent: React.FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const newData = new FormData();
  // const [epochTime] = useState(() => new Date().getTime().toString());
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [invalidField, setInvalidField] = useState(false);
  const [registerLoader, setRegisterLoader] = useState(false);
  const [fileSizeAlert, setFileSizeAlert] = useState(false);
  const [isValidEmail, setIsValidEmail] = React.useState(false);
  const [isValidPhone, setIsValidPhone] = React.useState(false);
  const [isValidAadhar, setIsValidAadhar] = React.useState(false);
  const [isValidGender, setIsValidGender] = React.useState(false);
  const [isValidUsrTp, setIsValidUsrTp] = React.useState(false);
  const [isValidDOB, setIsValidDOB] = React.useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [mediaFileNmeExt, setMediaFileNmeExt] = useState('');
  const [mediaFileNme, setMediaFileNme] = useState('');
  const [loaderState, setLoaderState] = useState(false);
  // const [loaderDist, setLoaderDist] = useState(false);
  const [stateData, setStateData] = useState('');
  // const [distData, setDistData] = useState('');
  // const distData: string[] = [];
  const [stateListData, setStateListData] = useState<StateResult[]>([]);
  const stateResData = useSelector((state: RootState) => state.yoCard.getStateMetaList);
  // const [distListData, setDistListData] = useState<DistResult[]>([]);
  // const distResData = useSelector((state: RootState) => state.yoCard.getDistMetaList);
  const [loaderAdmCd, setLoaderAdmCd] = useState(false);
  const [statusAdmCd, setStatusAdmCd] = useState<CheckUserData>({
    stData: {
      stNme: '',
      stCd: '',
    },
    sts: false,
  });
  const [checkAPICall, setCheckAPICall] = useState(false);
  // const [talukaAdmListData, setTalukaListData] = useState<TalukaAdmResult[]>([]);
  // const talukaAdmData = useSelector((state: RootState) => state.yoCard.getTalukaAdmMetaList);
  const [filePreview, setFilePreview] = useState('/images/common/avatar.png');
  const registerResponse = useSelector((state: RootState) => state.yoCard.registerUser);
  const verifyUserResponse = useSelector((state: RootState) => state.yoCard.checkUserExist);
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
  const phoneRegex = /^\d{10}$/;
  const adhrregex = /^[2-9]{1}[0-9]{11}$/;
  const [selectDate, setSelectDate] = React.useState({
    date: '',
    selectedDate: moment(new Date()).format('YYYY-MM-DD'),
  });
  const [myImageFile, setMyImageDataFile] = useState<ImageFileInput>({
    fileData: new FormData(),
  });
  const [registerData, setRegisterData] = useState<RegisterInput>({
    fNme: '',
    lNme: '',
    uNme: '',
    gnd: '',
    usrTp: '',
    ph: '',
    eId: '',
    admCd: '',
    dOB: '',
    avatar: '',
    st: '',
    adhrId: '',
    isAgree: false,
  });

  const handleTermUse = () => {
    setModalConfirm(!modalConfirm);
  };

  const formatDate = (dateOfBirth: string | number | Date) => {
    const d = new Date(dateOfBirth);
    const month = `${d.getMonth() + 1}`.padStart(2, '0');
    const day = `${d.getDate()}`.padStart(2, '0');
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const handleprofilephoto = (e:any) => {
    if (e.target.files[0].size < 5000000) {
      newData.append('file', e.target.files[0]);
      setMyImageDataFile({ ...myImageFile, [e.target.name]: newData });
      const extFile = e.target.files[0].name.split('.');
      // const extFileData = e.target.files[0].name.split('.')[1];
      const objectUrl = URL.createObjectURL(e.target.files[0]);
      setFilePreview(objectUrl);
      setMediaFileNmeExt(extFile[extFile.length - 1]);
    } else {
      setFileSizeAlert(true);
      setFilePreview('/images/common/avatar.png');
    }
  };
  React.useEffect(() => {
    // const fileNme = `${registerData.adhrId.toUpperCase()}_${registerData.lNme.toUpperCase()}_${registerData.usrTp}_${registerData.ph}.${mediaFileNmeExt}`;
    const fileNme = `${registerData.adhrId.toUpperCase()}.${mediaFileNmeExt}`;
    setMediaFileNme(fileNme);
    setRegisterData({
      ...registerData, avatar: fileNme,
    });
  }, [mediaFileNme, mediaFileNmeExt, registerData.adhrId]);
  // mediaFileNme, mediaFileNmeExt, registerData.ph, registerData.adhrId, registerData.usrTp

  React.useEffect(() => {
    if (registerResponse.error && registerResponse.message !== '') {
      dispatch(clearRegisterPostReq());
      setRegisterLoader(false);
      setErrorShowAlert(true);
      setAlertMsg(registerResponse.message);
    }
  }, [registerResponse.message, registerResponse.error]);

  React.useEffect(() => {
    if (!registerResponse.error && registerResponse.data !== '') {
      dispatch(clearRegisterPostReq());
      setRegisterLoader(false);
      setInvalidField(false);
      setIsValidEmail(false);
      setIsValidPhone(false);
      setRegisterData({
        fNme: '',
        lNme: '',
        uNme: '',
        gnd: '',
        usrTp: '',
        ph: '',
        eId: '',
        admCd: '',
        dOB: '',
        avatar: '',
        st: '',
        adhrId: '',
        isAgree: false,
      });
      if (registerResponse.data === 'Mail sent successfully') {
        setAlertMsg('Your registration has been completed successfully. To gain access to the yoCard platform, please contact your administration');
      }
      setSuccessShowAlert(true);
    }
  }, [registerResponse.data, registerResponse.error]);

  const loginPage = () => {
    history('/login');
    window.scrollTo(0, 0);
  };

  const handleGenderRegister = (gender_data: string) => {
    if (gender_data === 'M') {
      setRegisterData({ ...registerData, gnd: 'M' });
    } else if (gender_data === 'F') {
      setRegisterData({ ...registerData, gnd: 'F' });
    }
  };
  const handleStateAdminRegister = (adm_data: string) => {
    if (adm_data === 'CRDOPT') {
      setRegisterData({ ...registerData, usrTp: 'CRDOPT' });
    } else if (adm_data === 'STADM') {
      setRegisterData({ ...registerData, usrTp: 'STADM' });
    }
  };
  const handleFieldChangeRegister = (e:any) => {
    const fieldName = e.target.name;
    setRegisterData({ ...registerData, [fieldName]: e.target.value });
  };

  const handleTermChange = (e: any) => {
    setRegisterData({ ...registerData, isAgree: e.target.checked });
  };

  const handleSubmit = (e:any) => {
    e.preventDefault();
    const validEmail = emailRegex.test(registerData.eId);
    const validPhone = phoneRegex.test(registerData.ph);
    const validAadhar = adhrregex.test(registerData.adhrId);
    const validGender = registerData.gnd;
    const validUsr = registerData.usrTp;
    const validDOB = registerData.dOB;
    const dOBObj = new Date(registerData.dOB);
    const currentDate = new Date();
    const timeDiff = currentDate.getTime() - dOBObj.getTime();
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    if
    (
      mediaFileNmeExt === ''
      || (validAadhar && validEmail && validPhone && validUsr !== '' && validGender !== '' && validDOB !== '' && daysDiff >= 18 * 365)
    ) {
      setRegisterLoader(true);
      Promise.resolve(
        dispatch(getAvatarPublish({
          bucketNme: AWS_AVATAR_BUCKET,
          fileNme: mediaFileNme,
          formData: myImageFile.fileData,
          requestType: '',
        })),
      ).then(() => {
        dispatch(registerReq({
          inputBody: registerData,
          requestType: APIHeader.REQ_ADD_AGENT,
        }));
      });
    } else if (!validAadhar) {
      setIsValidAadhar(true);
    } else if (!validEmail) {
      setIsValidEmail(true);
    } else if (!validPhone) {
      setIsValidPhone(true);
    } else if (!validGender) {
      setIsValidGender(true);
    } else if (!validUsr) {
      setIsValidUsrTp(true);
    } else if (!validDOB) {
      setIsValidDOB(true);
    } else if (daysDiff < 18 * 365) {
      setIsValidDOB(true);
    }
  };

  const stateoptions = stateListData && stateListData.map((state) => ({
    label: state.nme,
    value: state.MetaId,
  }));

  const selectedStateOptions = stateoptions && stateoptions.find(
    (option) => option.value === stateData,
  );

  React.useEffect(() => {
    setLoaderState(true);
    dispatch(getStateListReq({
      requestType: APIHeader.REQ_GET_ALL_ST,
    }));
  }, []);

  React.useEffect(() => {
    if (!stateResData.isError) {
      setLoaderState(false);
      setStateListData(stateResData.stData);
    }
  }, [stateResData.stData]);

  // React.useEffect(() => {
  //   if (!distResData.isError) {
  //     setLoaderDist(false);
  //     setDistListData(distResData.distData);
  //   }
  // }, [distResData.distData]);

  const handleSelectState = (e:any) => {
    // setLoaderDist(true);
    setStateData(e.value);
    setRegisterData({ ...registerData, st: e.value });
    dispatch(getDistListReq({
      requestType: APIHeader.REQ_GET_ALL_DIST,
      state: e.value,
    }));
  };

  const handleGetByAdminCode = () => {
    setLoaderAdmCd(true);
    dispatch(checkUserExistReq({
      requestType: APIHeader.USER_EXIST_STATUS,
      userCd: registerData.admCd,
      userTp: 'STADM',
    }));
    setCheckAPICall(true);
  };

  React.useEffect(() => {
    if (statusAdmCd.stData.stCd !== '') {
      setRegisterData({ ...registerData, st: statusAdmCd.stData.stCd });
    }
  }, [statusAdmCd.stData.stCd]);

  React.useEffect(() => {
    if (verifyUserResponse.error && verifyUserResponse.message !== '') {
      dispatch(clearUserExistReq());
      setLoaderAdmCd(false);
      setErrorShowAlert(true);
      setAlertMsg(verifyUserResponse.message);
    }
  }, [verifyUserResponse.message, verifyUserResponse.error]);

  React.useEffect(() => {
    if (!verifyUserResponse.error && verifyUserResponse.message === 'executed') {
      setLoaderAdmCd(false);
      setStatusAdmCd(verifyUserResponse.data);
      dispatch(clearUserExistReq());
    }
  }, [verifyUserResponse.message, verifyUserResponse.error]);

  return (
    <div className="mx-3">
      { registerLoader ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {invalidField ? (
        errorAlert(false, 'All Fields Are Mandatory !!!', invalidField, setInvalidField)
      ) : null}
      {isValidEmail ? (
        errorAlert(false, 'Please enter valid Email Id !!!', isValidEmail, setIsValidEmail)
      ) : null}
      {isValidPhone ? (
        errorAlert(false, 'Please enter valid Phone Number !!!', isValidPhone, setIsValidPhone)
      ) : null}
      {isValidAadhar ? (
        errorAlert(false, 'Enter valid Aadhar !!!', isValidAadhar, setIsValidAadhar)
      ) : null}
      {isValidGender ? (
        errorAlert(false, 'Gender Details is missing !!!', isValidGender, setIsValidGender)
      ) : null}
      {isValidUsrTp ? (
        errorAlert(false, 'Choose atleast one registration type !!!', isValidUsrTp, setIsValidUsrTp)
      ) : null}
      {isValidDOB ? (
        errorAlert(false, 'Date of Birth is either invalid or less than 18 Years !!!', isValidDOB, setIsValidDOB)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Card className="login-card-register">
        <CardBody>
          <Row className="pt-">
            <Col lg={{ size: 8, order: 2 }} xs={{ order: 1 }} className="vertical-align-register">
              <div className="align-center pt-1">
                <span className="text-lite-grey">User Registration</span>
              </div>
              <div className="form-register-border">
                <div className="align-end mx-1">
                  {fileSizeAlert ? <span className="alert-error mx-2">File size should not exceed 5mb</span> : null}
                  <Card className="collapse-bg form-card-border-none my-2">
                    <div className="align-center">
                      <CardImg alt="logo" className="img-fluid rounded-circle avatar-user-register-preview" src={filePreview} />
                    </div>
                    <div className="register-file-upload-box">
                      <Input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        name="fileData"
                        className="register-file-upload-input"
                          // className="profile-image-btn avatar"
                        onChange={(e) => handleprofilephoto(e)}
                      />
                      <span>Drag and drop or <span className="register-file-link">Choose your profile</span></span>
                    </div>
                    <div className="pt-3" />
                  </Card>
                </div>
                <Form onSubmit={handleSubmit}>
                  <Row className="mx-1">
                    <Col lg="6" className="pt-4">
                      <h6 className="input-header-text">First Name<span className="mandate-star">*</span></h6>
                      <Input
                        placeholder="Enter your First Name"
                        type="text"
                        required
                        name="fNme"
                        value={registerData.fNme}
                        disabled={registerLoader}
                        onChange={handleFieldChangeRegister}
                        className="yocard-input"
                      />
                    </Col>
                    <Col lg="6" className="pt-4">
                      <h6 className="input-header-text">Last Name<span className="mandate-star">*</span></h6>
                      <Input
                        placeholder="Enter your Last Name"
                        type="text"
                        required
                        name="lNme"
                        value={registerData.lNme}
                        disabled={registerLoader}
                        onChange={handleFieldChangeRegister}
                        className="yocard-input"
                      />
                    </Col>
                    <Col lg="6" className="pt-4">
                      <h6 className="input-header-text">Aadhar Id<span className="mandate-star">*</span></h6>
                      <Input
                        placeholder="Enter your Aadhar Id"
                        type="text"
                        required
                        name="adhrId"
                        value={registerData.adhrId}
                        disabled={registerLoader}
                        onChange={handleFieldChangeRegister}
                        className="yocard-input"
                      />
                      <h6 className="text-lite-grey mx-1 pt-1">
                        Rest assured, this data is encrypted, protecting your ID from others.
                      </h6>
                    </Col>
                    <Col lg="6" className="pt-4">
                      <span className="input-header-text">Phone<span className="mandate-star">*</span></span>
                      <InputGroup>
                        <Input
                          placeholder="Enter your Phone Number"
                          type="number"
                          required
                          name="ph"
                          value={registerData.ph}
                          disabled={registerLoader}
                          onChange={handleFieldChangeRegister}
                          className="yocard-input mx-1"
                        />
                      </InputGroup>
                      <h6 className="text-lite-grey mx-1 pt-1">
                        An incorrect phone number could result in the denial of access
                      </h6>
                    </Col>
                    <Col lg="12" className="pt-4">
                      <h6 className="input-header-text">Email Id<span className="mandate-star">*</span></h6>
                      <Input
                        placeholder="Enter your Email Id"
                        type="text"
                        required
                        name="eId"
                        value={registerData.eId}
                        disabled={registerLoader}
                        onChange={handleFieldChangeRegister}
                        className="yocard-input"
                      />
                      <h6 className="text-lite-grey mx-1 pt-1">
                        All the communications will be sent to your this email id
                      </h6>
                    </Col>
                    <Col lg="7" className="pt-4">
                      <h6 className="input-header-text">Date of Birth<span className="mandate-star">*</span></h6>
                      <div className="align-center1">
                        <DropdownDate
                          startDate={// optional, if not provided 1900-01-01 is startDate
                              '1970-01-01' // 'yyyy-mm-dd' format only
                            }
                        // endDate={// optional, if not provided current date is endDate'2013-12-31' // 'yyyy-mm-dd' format only}
                          order={[
                            DropdownComponent.month,
                            DropdownComponent.day,
                            DropdownComponent.year,
                          ]}
                        // onMonthChange={(month: any) => { // optional
                        //   console.log(month);
                        // }}
                        // onDayChange={(day: any) => { // optional
                        //   console.log(day);
                        // }}
                        // onYearChange={(year: any) => { // optional
                        //   console.log(year);
                        // }}
                          selectedDate={selectDate.selectedDate}
                          onDateChange={(date: string | number | Date) => {
                            const apiDate = moment(date).format('YYYY-MM-DDThh:mm:ss');
                            const apiDateDropDwn = moment(date).format('YYYY-MM-DD');
                            setSelectDate({ ...selectDate, date: apiDateDropDwn, selectedDate: formatDate(date) });
                            setRegisterData({ ...registerData, dOB: apiDate });
                          }}
                          ids={{
                            year: 'select-year',
                            month: 'select-month',
                            day: 'select-day',
                          }}
                          names={{
                            year: 'year',
                            month: 'month',
                            day: 'day',
                          }}
                          classes={{
                            // dateContainer: 'yocard-input-dob',
                            yearContainer: 'inline-dropdown',
                            monthContainer: 'inline-dropdown',
                            dayContainer: 'inline-dropdown',
                            year: 'yocard-input-dob',
                            month: 'yocard-input-dob',
                            day: 'yocard-input-dob',
                            // yearOptions: 'yocard-input-dob-year',
                            // monthOptions: 'classes',
                            // dayOptions: 'yocard-input-dob-option',
                          }}
                          defaultValues={{
                            year: 'select year',
                            month: 'select month',
                            day: 'select day',
                          }}
                          options={{
                            yearReverse: true, // false by default
                            monthShort: true, // false by default
                            monthCaps: true, // false by default
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg="5" className="pt-4">
                      <span className="input-header-text">Gender<span className="mandate-star">*</span></span>
                      <Row className="pt-2">
                        <Col xs="6">
                          <Input
                            type="radio"
                            name="gender"
                            className="yocard-radio-box cursor-pointer"
                            onClick={() => handleGenderRegister('M')}
                          />
                          <Label check className="collapse-header mx-1">Male</Label>
                        </Col>
                        <Col xs="6">
                          <Input
                            type="radio"
                            name="gender"
                            className="yocard-radio-box cursor-pointer"
                            onClick={() => handleGenderRegister('F')}
                          />
                          <Label check className="collapse-header mx-1">FeMale</Label>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg="12" className="pt-4">
                      <span className="input-header-text">User Registration Type<span className="mandate-star">*</span></span>
                      <Row className="pt-2">
                        {/* <Col lg="6" xs="10" className="pt-1">
                          <Input
                            type="radio"
                            name="usrTp"
                            className="yocard-radio-box cursor-pointer"
                            onClick={() => handleDistAdminRegister('DSTCRD')}
                          />
                          <Label check className="collapse-header mx-1">District Coordinator</Label>
                        </Col> */}
                        <Col lg="5" xs="6" className="pt-1">
                          <Input
                            type="radio"
                            name="usrTp"
                            className="yocard-radio-box cursor-pointer"
                            onClick={() => handleStateAdminRegister('CRDOPT')}
                          />
                          <Label check className="collapse-header mx-1">Card Operator</Label>
                        </Col>
                        <Col lg="5" xs="6" className="pt-1">
                          <Input
                            type="radio"
                            name="usrTp"
                            className="yocard-radio-box cursor-pointer"
                            onClick={() => handleStateAdminRegister('STADM')}
                          />
                          <Label check className="collapse-header mx-1">State Admin</Label>
                        </Col>
                      </Row>
                    </Col>
                    {registerData.usrTp !== '' ? (
                      registerData.usrTp === 'CRDOPT' ? (
                        <Row className="pt-4">
                          <Col lg="12" className="adm-bg">
                            <h6 className="input-header-text">Admin Code<span className="mandate-star">*</span></h6>
                            <div className="align-start">
                              <Input
                                placeholder="Enter Admin Code"
                                type="text"
                                name="admCd"
                                value={registerData.admCd}
                                disabled={loaderAdmCd || checkAPICall}
                                onChange={handleFieldChangeRegister}
                                className="yocard-input"
                              />
                              <div className="pt-1">
                                <Button disabled={registerData.admCd === '' || loaderAdmCd} className="yocard-button-oval mx-2" onClick={handleGetByAdminCode}>
                                  {loaderAdmCd ? (
                                    <div className="align-start">
                                      <Spinner
                                        style={{
                                          height: '1.5rem',
                                          width: '1.5rem',
                                        }}
                                        type="grow"
                                      >
                                        Loading...
                                      </Spinner>
                                      <span className="mx-1">
                                        Loading
                                      </span>
                                    </div>
                                  ) : 'Verify'}
                                </Button>
                              </div>
                            </div>
                            <h6 className="text-lite-grey mx-1 pt-1">
                              Get Admin code from your administrator {checkAPICall ? statusAdmCd.sts ? <span className="correct-pass-key"><FontAwesomeIcon className="mx-2" icon={faCircleCheck} />Verified</span> : <span className="wrong-pass-key"><FontAwesomeIcon className="mx-2" icon={faCircleXmark} />Failed</span> : null}
                            </h6>
                          </Col>
                          {statusAdmCd.stData.stCd !== ''
                            ? (
                              <Col lg="12" className="adm-bg">
                                <h6 className="input-header-text">Assigned State: <span className="mandate-star">{statusAdmCd.stData.stNme}</span></h6>
                              </Col>
                            ) : null }
                        </Row>
                      )
                        : null) : null }
                    {registerData.usrTp !== '' ? (
                      registerData.usrTp === 'STADM' ? (
                        <Col lg="12" className="pt-4">
                          <h6 className="input-header-text">State<span className="mandate-star">*</span></h6>
                          <Select
                            value={stateData === '' ? null : selectedStateOptions}
                            options={stateoptions}
                            onChange={handleSelectState}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              menu: (base) => ({
                                ...base,
                                // position: 'relative',
                              }),
                              control: (base: any) => ({
                                ...base,
                                '&:hover': { borderColor: '#880000' },
                                border: '1px solid #DDE0E4',
                                minHeight: '40px',
                                borderRadius: '5px',
                                padding: 1,
                                fontSize: '15px',
                                fontWeight: 400,
                                color: '#575656',
                                boxShadow: 'none',
                                backgroundColor: '#fff',
                                '&:focus': {
                                  borderColor: '#E1EDF8',
                                  boxShadow: 'none',
                                  color: '#575656',
                                  background: '#fff',
                                },
                              }),
                              option: (provided: any, state: { isSelected: any; }) => ({
                                ...provided,
                                color: state.isSelected ? '#383838' : '#212121',
                                padding: 15,
                                textAlign: 'left',
                                background: state.isSelected ? '#FAFCFB' : '#fff',
                                '&:hover': {
                                  background: '#FAFCFB',
                                },
                              }),
                            }}
                            isLoading={loaderState}
                            noOptionsMessage={() => 'No State Found'}
                            placeholder="Select State"
                          />
                        </Col>
                      ) : null) : null}
                  </Row>
                  {/* <div className="pt-3 mx-3">
                    {registerData.usrTp !== '' ? (
                      registerData.usrTp === 'DSTCRD' ? (
                        <Row className="adm-bg">
                          <Col lg="12" className="pt-3">
                            <h6 className="input-header-text">Your District Admin Code<span className="mandate-star">*</span></h6>
                            <div className="align-start">
                              <Input
                                placeholder="Enter Your District Admin Code"
                                type="text"
                                name="admCd"
                                value={registerData.admCd}
                                disabled={registerLoader}
                                onChange={handleFieldChangeRegister}
                                className="yocard-input"
                              />
                              <div className="pt-1">
                                <Button disabled={registerData.admCd === ''} className="yocard-button-oval mx-2" onClick={handleGetTalukaByAdminCode}>
                                  Verify
                                </Button>
                              </div>
                            </div>
                          </Col>
                          <Col lg="12" className="pt-3">
                            <h6 className="input-header-text">Tehsil/Taluka</h6>
                            <Select
                              key="taluka"
                              options={talukaNameoptions}
                              isClearable
                              onChange={(e) => handleSelectTalukaData(e)}
                              isMulti
                              // value={talukaAdmListData}
                              menuPortalTarget={document.body}
                              styles={{
                                multiValue: (provided: any) => ({
                                  ...provided,
                                  background: '#f6eeeb',
                                  padding: '1px',
                                }),
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                menu: (base) => ({
                                  ...base,
                                  position: 'relative',
                                }),
                                control: (base: any) => ({
                                  ...base,
                                  '&:hover': { borderColor: '#F6B26B' },
                                  border: '1px solid #DDE0E4',
                                  minHeight: '50px',
                                  borderRadius: '5px',
                                  padding: 1,
                                  fontSize: '15px',
                                  fontWeight: 400,
                                  color: '#575656',
                                  boxShadow: 'none',
                                  backgroundColor: '#fff',
                                  '&:focus': {
                                    borderColor: '#E1EDF8',
                                    boxShadow: 'none',
                                    color: '#575656',
                                    background: '#fff',
                                  },
                                }),
                                option: (provided: any, state: { isSelected: any; }) => ({
                                  ...provided,
                                  color: state.isSelected ? '#383838' : '#212121',
                                  padding: 15,
                                  textAlign: 'left',
                                  background: state.isSelected ? '#FAFCFB' : '#fff',
                                  '&:hover': {
                                    background: '#FAFCFB',
                                  },
                                }),
                              }}
                              noOptionsMessage={() => 'No Taluka Found'}
                              isLoading={loaderTaluka}
                              placeholder="Enter Taluka Name"
                            />
                            <h6 className="text-lite-grey mx-1 pt-1">
                              Choose the Talukas as provided by the District Administration.
                            </h6>
                          </Col>
                        </Row>
                      )
                        : registerData.usrTp === 'DADM' ? (
                          <Row>
                            <Col lg="12" className="pt-3">
                              <h6 className="input-header-text">State<span className="mandate-star">*</span></h6>
                              <Select
                                value={stateData === '' ? null : selectedStateOptions}
                                options={stateoptions}
                                onChange={handleSelectState}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                  menu: (base) => ({
                                    ...base,
                                    // position: 'relative',
                                  }),
                                  control: (base: any) => ({
                                    ...base,
                                    '&:hover': { borderColor: '#F6B26B' },
                                    border: '1px solid #DDE0E4',
                                    minHeight: '50px',
                                    borderRadius: '5px',
                                    padding: 1,
                                    fontSize: '15px',
                                    fontWeight: 400,
                                    color: '#575656',
                                    boxShadow: 'none',
                                    backgroundColor: '#fff',
                                    '&:focus': {
                                      borderColor: '#E1EDF8',
                                      boxShadow: 'none',
                                      color: '#575656',
                                      background: '#fff',
                                    },
                                  }),
                                  option: (provided: any, state: { isSelected: any; }) => ({
                                    ...provided,
                                    color: state.isSelected ? '#383838' : '#212121',
                                    padding: 15,
                                    textAlign: 'left',
                                    background: state.isSelected ? '#FAFCFB' : '#fff',
                                    '&:hover': {
                                      background: '#FAFCFB',
                                    },
                                  }),
                                }}
                                isLoading={loaderState}
                                noOptionsMessage={() => 'No State Found'}
                                placeholder="Select State"
                              />
                            </Col>
                            <Col lg="12" className="pt-3">
                              <h6 className="input-header-text">District<span className="mandate-star">*</span></h6>
                              <Select
                                key="dist"
                                options={distOptions}
                                isClearable
                                onChange={(e) => handleSelectDistData(e)}
                                isMulti
                                menuPortalTarget={document.body}
                                styles={{
                                  multiValue: (provided: any) => ({
                                    ...provided,
                                    background: '#f6eeeb',
                                    padding: '1px',
                                  }),
                                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                  menu: (base) => ({
                                    ...base,
                                    position: 'relative',
                                  }),
                                  control: (base: any) => ({
                                    ...base,
                                    '&:hover': { borderColor: '#F6B26B' },
                                    border: '1px solid #DDE0E4',
                                    minHeight: '50px',
                                    borderRadius: '5px',
                                    padding: 1,
                                    fontSize: '15px',
                                    fontWeight: 400,
                                    color: '#575656',
                                    boxShadow: 'none',
                                    backgroundColor: '#fff',
                                    '&:focus': {
                                      borderColor: '#E1EDF8',
                                      boxShadow: 'none',
                                      color: '#575656',
                                      background: '#fff',
                                    },
                                  }),
                                  option: (provided: any, state: { isSelected: any; }) => ({
                                    ...provided,
                                    color: state.isSelected ? '#383838' : '#212121',
                                    padding: 15,
                                    textAlign: 'left',
                                    background: state.isSelected ? '#FAFCFB' : '#fff',
                                    '&:hover': {
                                      background: '#FAFCFB',
                                    },
                                  }),
                                }}
                                noOptionsMessage={() => 'No District Found'}
                                isLoading={loaderDist}
                                placeholder="Select District"
                              />
                            </Col>
                          </Row>
                        ) : null
                    ) : null}
                  </div> */}
                  <Row className="pt-4 my-4">
                    <Col lg="7">
                      <div className="align-start">
                        <Label check>
                          <Input
                            type="checkbox"
                            disabled={registerLoader}
                            onChange={handleTermChange}
                            className="yocard-check-box ml-1 pt-1 cursor-pointer"
                          />{' '}
                          <span className="text-lite-grey">I have read the <Button className="button-term-use" onClick={handleTermUse}>terms &amp; use</Button> and agree.</span>
                        </Label>
                      </div>
                    </Col>
                    <Col lg="5">
                      <div className="text-right-login-yocard-register align-end">
                        <Button
                          className="yocard-button-oval mx-1"
                          disabled={
                            mediaFileNmeExt === '' || !registerData.isAgree || (registerData.usrTp === 'CRDOPT' ? !statusAdmCd : false)
                          }
                        >
                          Register
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="align-start pt-2">
                <span className="mx-3 already-text">Already signed up ? <Button className="text-login-already button-already" onClick={loginPage}>Login </Button></span>
              </div>
            </Col>
            <Col lg={{ size: 4, order: 1 }} xs={{ order: 2 }}>
              <Row className="align-center mx-1">
                <div className="align-center pt-1">
                  <span className="sign-up-header-register">Connect with Us</span>
                </div>
                <Col lg="9" xs="12">
                  <div className="pt-2">
                    <Card className="form-card-shadow">
                      <CardImg
                        className="process-image"
                        src="images/common/register-before-print.png"
                      />
                      <CardBody>
                        <span className="already-text pt-3 align-text-center">
                          Explore the Agent login for a more convenient and faster task simplification experience
                        </span>
                      </CardBody>
                    </Card>
                  </div>
                  <div className="pt-2">
                    <div className="pt-5 align-center">
                      <Card className="form-card-shadow">
                        <CardImg
                          className="process-image"
                          src="images/common/register-processing.png"
                        />
                        <CardBody>
                          <span className="already-text pt-3 align-text-center">
                            Access your personalized dashboard to conveniently monitor your activities
                          </span>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                  <div className="pt-3">
                    <Card className="form-card-shadow">
                      <CardImg
                        className="process-image"
                        src="images/common/register-after-print.png"
                      />
                      <CardBody>
                        <span className="already-text pt-3 align-text-center">
                          Embrace ease as the system processes to create ready-to-use cards effortlessly
                        </span>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Modal isOpen={modalConfirm} className="width-100">
            <ModalBody>
              <div className="width-100">
                <span>Member Terms and Conditions</span>
              </div>
              <div className="pt-2">
                <span className="text-lite-grey">These Member Terms and Conditions (&quot;Terms&quot;) govern your participation as a member in our data registration program. By joining and participating in our program, you agree to abide by the following terms and conditions:</span>
              </div>
              <div className="pt-2">
                <span className="text-lite-grey">1. Accurate Data Collection:</span>
              </div>
              <div className="pt-1 ml-1">
                <span className="text-lite-grey">1.1. You are responsible for accurately and truthfully collecting and recording data as per the government-issued identification provided by the individuals you register.</span>
              </div>
              <div className="pt-1 ml-1">
                <span className="text-lite-grey">1.2. Any data you collect must be in compliance with local, state, and national laws and regulations.</span>
              </div>
              <div className="pt-2">
                <span className="text-lite-grey">2. Non-Misuse of Data:</span>
              </div>
              <div className="pt-1 ml-1">
                <span className="text-lite-grey">2.1. You agree not to misuse or exploit any data collected during the registration process for personal gain, illegal activities, or unauthorized purposes.</span>
              </div>
              <div className="pt-1 ml-1">
                <span className="text-lite-grey">2.2. Data collected must be used solely for the purpose of registration and in accordance with our data handling policies.</span>
              </div>
              <div className="pt-2">
                <span className="text-lite-grey">3. Identity Verification:</span>
              </div>
              <div className="pt-1 ml-1">
                <span className="text-lite-grey">3.1. You must not use fake or fraudulent identification for yourself during the registration process.</span>
              </div>
              <div className="pt-1 ml-1">
                <span className="text-lite-grey">3.2. Any misrepresentation of your identity is strictly prohibited.</span>
              </div>
              <div className="pt-2">
                <span className="text-lite-grey">4. Data Integrity:</span>
              </div>
              <div className="pt-1 ml-1">
                <span className="text-lite-grey">4.1. Once data is recorded and submitted, you must not alter or manipulate it in any way, unless explicitly instructed to do so by authorized personnel.</span>
              </div>
              <div className="pt-1 ml-1">
                <span className="text-lite-grey">4.2. Any unauthorized modification of data is a breach of these terms.</span>
              </div>
              <div className="pt-2">
                <span className="text-lite-grey">5. Confidentiality:</span>
              </div>
              <div className="pt-1 ml-1">
                <span className="text-lite-grey">5.1. You must maintain the confidentiality and security of all data collected and not disclose it to any unauthorized parties.</span>
              </div>
              <div className="pt-1 ml-1">
                <span className="text-lite-grey">5.2. Data confidentiality is critical, and violations of this policy may result in legal action.</span>
              </div>
              <div className="pt-2">
                <span className="text-lite-grey">6. Compliance with Policies:</span>
              </div>
              <div className="pt-1 ml-1">
                <span className="text-lite-grey">6.1. You agree to comply with all policies, guidelines, and instructions provided to you by our organization.</span>
              </div>
              <div className="pt-1 ml-1">
                <span className="text-lite-grey">6.2. Failure to comply with policies may lead to termination of your membership.</span>
              </div>
              <div className="pt-2">
                <span className="text-lite-grey">7. Termination:</span>
              </div>
              <div className="pt-1 ml-1">
                <span className="text-lite-grey">7.1. We reserve the right to terminate your membership if you violate these terms and conditions.</span>
              </div>
              <div className="pt-2">
                <span className="text-lite-grey">8. Jurisdiction for Legal Matters:</span>
              </div>
              <div className="pt-1 ml-1">
                <span className="text-lite-grey">8.1. In the event of any legal disputes or concerns arising from your participation in this program, both parties agree that the exclusive jurisdiction for resolution of such matters shall be the courts located in Pune, Maharashtra India..</span>
              </div>
              <div className="pt-1 ml-1">
                <span className="text-lite-grey">8.2. This provision applies to all legal matters and disputes, including but not limited to those related to the interpretation, enforcement, or breach of these terms and conditions.</span>
              </div>
              <div className="pt-3">
                <span className="text-lite-grey">By joining our data registration program, you acknowledge your understanding and acceptance of these terms and conditions. If you have any questions or concerns, please contact us before proceeding.</span>
              </div>
              <div className="pt-1">
                <span className="text-lite-grey">These terms and conditions are subject to change, and any updates will be communicated to you.</span>
              </div>
              <div className="align-end pt-3">
                <Button className="yocard-button-oval mx-1" onClick={handleTermUse}>
                  cancel
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </CardBody>
      </Card>
      <div className="pt-5" />
    </div>
  );
};
export default RegisterAgent;

import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import PublicCampusXcelComponent from '../../scenes/public/yoCard.component';
import RegisterComponent from '../../scenes/public/register.component';
import CXUnauthorized from '../../scenes/unauthorized/unauthorized.component';
import { LoginComponent } from '../../scenes/public/login.component';
import { LoginSAdminComponent } from '../../scenes/public/sadmin.login.component';
import { SuperAdminComponent } from '../../scenes/exclusive/Super-Admin/super-admin.component';
import { StateAdminComponent } from '../../scenes/exclusive/State-Admin/state-admin.component';
import { StateOperatorComponent } from '../../scenes/exclusive/State-Operator/state-operator.component';
import LoginPrintComponent from '../../scenes/public/print.login.component';
import { PrinterComponent } from '../../scenes/exclusive/Printer/printer.component';

const AppBase: React.FC = (): ReactElement => (
  <Routes>
    <Route path="/unathorized" element={<CXUnauthorized />} />
    <Route path="/" element={<PublicCampusXcelComponent />} />
    <Route path="/home" element={<PublicCampusXcelComponent />} />
    <Route path="/register" element={<RegisterComponent />} />
    <Route path="/login" element={<LoginComponent />} />
    <Route path="/sadmin/login" element={<LoginSAdminComponent />} />
    <Route path="/sadmin/*" element={<SuperAdminComponent />} />
    <Route path="/state-admin/*" element={<StateAdminComponent />} />
    <Route path="/operator/*" element={<StateOperatorComponent />} />
    <Route path="/print/login" element={<LoginPrintComponent />} />
    <Route path="/print/*" element={<PrinterComponent />} />
    {/* <Route path="/contact-us" element={<ContactUsComponent />} />
    <Route path="/cardcord/login" element={<LoginCardCordComponent />} />
    <Route path="/dadmin/login" element={<LoginDAdminComponent />} />
    <Route path="/dc/*" element={<DistrictCoordinatorComponent />} />
    <Route path="/dadmin/*" element={<DistrictAdminComponent />} />
    <Route path="/cardcord/*" element={<CardCoordinatorComponent />} /> */}
  </Routes>
);

export default AppBase;

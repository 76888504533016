import { DownloadFileResponse, DownloadFileResults } from './get-download-file.service.types';

export const DownloadFileTransform: (response: DownloadFileResponse) => DownloadFileResults = (downloadFiletResults) => {
  const { data } = downloadFiletResults;
  const result: DownloadFileResults = {
    url: '',
    message: '',
  };
  result.url = data ?? '';
  result.message = 'executed';
  return result;
};

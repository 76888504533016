import { ListSubFolCountResponse, ListSubFolCountResults } from './list-subFol-count.service.types';

export const ListSubFolCountTransform: (response: ListSubFolCountResponse) => ListSubFolCountResults = (listSubFolCntResults) => {
  const { data, is_error, message } = listSubFolCntResults;
  const result: ListSubFolCountResults = {
    printDet: [{
      PrntId: '',
      PrntTp: '',
      updtCnt: 0,
      tCnt: '0',
      cSts: '',
      courId: '',
      updtOn: '',
      costCrd: '',
      cStsV: '',
      gstCrd: '',
      gstPrint: '',
      costVendor1: '',
      costPrint: '',
      gstVendor1: '',
      sndrNme: '',
      aCmnt: '',
      miscCst: '',
      courTp: '',
      distDet: {
        distNme: '',
        stCd: '',
        stNme: '',
      },
      prntUsrCd: {
        ph: '',
        st: '',
        UserCd: '',
        lNme: '',
        fNme: '',
        eId: '',
      },
    }],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.printDet = data;
    result.message = 'executed';
  }
  return result;
};

import { DelFileURLResponse, DelFileURLResults } from './del-file-url.service.types';

export const DelFileURLTransform: (response: DelFileURLResponse) => DelFileURLResults = (fileURLResults) => {
  const { data, is_error, message } = fileURLResults;
  const result :DelFileURLResults = {
    data: '',
    error: false,
    message: '',
  };
  if (is_error) {
    result.message = message;
    result.error = is_error;
  } else {
    result.data = data;
    result.message = 'executed';
  }
  return result;
};

import { CardMassStatusResponse, CardMassStatusResults } from './update-mass-status.service.types';

export const CardMassStatusTransform: (response: CardMassStatusResponse) => CardMassStatusResults = (massResults) => {
  const { data, is_error, message } = massResults;
  const result: CardMassStatusResults = {
    data: '',
    message: '',
    error: false,
  };
  if (is_error) {
    result.error = is_error;
    // result.errorMessage = 'Backend Issue';
    result.message = message;
  } else {
    result.data = data ?? '';
    result.message = 'executed';
  }
  return result;
};

import { getCommonApiToken, getPublicJSON } from '../../../utils';
import { getConfig } from '../../../utils/config/config';
import { ListCityOptions, ListCityResponse } from './list-city.service.types';

const { YOCARD_API_ROOT } = getConfig();

export const ListCityRequest = ({
  token, userType, requestType, searchData, limit,
}: ListCityOptions): Promise<ListCityResponse> => {
  if (userType === 'PUBLIC') {
    return getPublicJSON(`${YOCARD_API_ROOT}?userType=${userType}&searchData=${searchData}&limit=${limit}`, requestType);
  }
  return getCommonApiToken(`${YOCARD_API_ROOT}?userType=${userType}&searchData=${searchData}&limit=${limit}`, requestType, token);
};

import React, { MutableRefObject, useRef } from 'react';
import { useLocation } from 'react-router-dom';
// import ClientsCampusXcel from '../../components/public/home/clients-yoCard';
// import TestimonialsCampusXcel from '../../components/public/home/testimonials-yoCard';
import './public.scss';
import Navbar from '../../components/public/navbar/yoCard.navbar';
import AboutYoCard from '../../components/public/home/about-yoCard';
import ProcessYoCard from '../../components/public/home/process-yocard';
import WDSFooter from '../../components/public/home/footer';
import AboutWDS from '../../components/public/home/about-wds';
import WDSContactus from '../../components/public/home/contact-us';
// import DataDemographic from '../../components/public/home/data-demographic';

export const PublicCampusXcelComponent: React.FC = () => {
  const location = useLocation();
  const aboutDiv = useRef() as MutableRefObject<HTMLDivElement>;
  const connectDiv = useRef() as MutableRefObject<HTMLDivElement>;
  const yoDiv = useRef() as MutableRefObject<HTMLDivElement>;
  React.useLayoutEffect(() => {
    if (location.hash === '#about') {
      aboutDiv.current.scrollIntoView({ behavior: 'smooth' });
    } else if (location.hash === '#connect') {
      connectDiv.current.scrollIntoView({ behavior: 'smooth' });
    } else if (location.hash === '#yocard') {
      yoDiv.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <div className="yocard-media">
      <Navbar />
      <AboutYoCard />
      <div ref={yoDiv}>
        <ProcessYoCard />
      </div>
      {/* <div>
        <DataDemographic />
      </div> */}
      <div ref={aboutDiv}>
        <AboutWDS />
      </div>
      <div ref={connectDiv}>
        <WDSContactus />
      </div>
      <WDSFooter />
    </div>
  );
};

export default PublicCampusXcelComponent;

/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Button, Tooltip } from 'reactstrap';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SATrendResult } from '../../../services/reports-all/superadmin-dashboard/superadmin-trend-status/superadmin-trend-sts.types';

interface TableProps {
  data: SATrendResult[];
}

const SAdminTable: React.FC<TableProps> = ({ data }) => {
  const [tooltipOpen, setTooltipOpen] = React.useState<Record<string, boolean>>({});

  const toggle = (target: string) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <table className="styled-table">
      <thead>
        <tr>
          <th>State</th>
          <th>District</th>
          <th>Date</th>
          <th>Status</th>
          <th>Count</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <React.Fragment key={`row-${row.st}-${row.dt}`}>
            {row.statusData.map((status, statusIndex) => (
              <tr key={`cell-${row.st}-${row.dt}-${status.dist}-${statusIndex}`}>
                {statusIndex === 0 && (
                  <>
                    <td rowSpan={row.statusData.length}>{row.st}</td>
                    <td rowSpan={row.statusData.length}>{status.dist}</td>
                    <td rowSpan={row.statusData.length}>{row.dt}</td>
                  </>
                )}
                <td>
                  {status ? (
                    <span className="stadmincard-dashboard-header">
                      {status.cSts}
                      <Button className="button-icon" id={`tooltip-${rowIndex}-${statusIndex}`}>
                        <FontAwesomeIcon className="info-stadm-icon my-1" icon={faCircleInfo} />
                      </Button>
                      <Tooltip
                        placement="top"
                        isOpen={tooltipOpen[`tooltip-${rowIndex}-${statusIndex}`] || false}
                        target={`tooltip-${rowIndex}-${statusIndex}`}
                        toggle={() => toggle(`tooltip-${rowIndex}-${statusIndex}`)}
                      >
                        {status.stsVal}
                      </Tooltip>
                    </span>
                  ) : ''}
                </td>
                <td>{status ? status.tCnt : ''}</td>
              </tr>
            ))}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default SAdminTable;

import {
  CardImg,
  Col, Row,
} from 'reactstrap';
import './home.scss';

export const WDSContactus: React.FC = () => (
  <div className="contact-wds-bg mb-4">
    <Row className="mx-2 align-center">
      <Col lg={{ size: 4, order: 2 }} md={{ size: 7, order: 1 }} xs={{ order: 1 }}>
        <div>
          <div className="align-center">
            <span className="display-6 text-intro-header">
              Connect With Us
            </span>
          </div>
          <div className="pt-4 text-center-aligned mb-3">
            <span className="text-why pt-5">
              Be a part of our amazing expedition and embark on an unforgettable voyage with us!
            </span>
            <br />
            <br />
            Email us : <a href="mailto:support@wdsinfotech.com" target="_blank" className="text-email-camps" rel="noreferrer">support@wdsinfotech.com</a>

          </div>
        </div>
      </Col>
      <Col lg={{ size: 4, order: 1 }} md={{ size: 5, order: 2 }} xs={{ order: 2 }}>
        <div className="align-center">
          <Row>
            <Col xs="12">
              <CardImg
                alt="..."
                // height="250px"
                // className="card-campus-360-img"
                src="images/common/contact-us.png"
              />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  </div>
);

export default WDSContactus;

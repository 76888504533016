import {
  CardImg,
  Col, Row,
} from 'reactstrap';
import './home.scss';

export const AboutYoCard: React.FC = () => (
  <div className="about-yoCard-bg pt-5 my-5">
    <Row className="mx-1 pt-5">
      <Col lg={{ size: 6, order: 1 }} md={{ size: 1, order: 2 }} xs={{ order: 1 }}>
        <div className="text-center-aligned">
          <span className="display-5 text-intro-sub-header">
            Simplified Printable Cards <br />
          </span>
          <span className="text-intro-sub-header-grey">
            Visual Data Tracking
          </span>
          <div className="pt-5 text-center-aligned">
            <span className="text-about pt-5">
              Acquire your ready-to-print PDF cards, perfect for efficiently converting bulk
              PDF data into printable layouts. Furthermore, efficiently supervise employee
              assignments across diverse domains to ensure smooth task administration and
              improved oversight of team activities.
            </span>
          </div>
        </div>
      </Col>
      <Col lg={{ size: 6, order: 2 }} md={{ size: 5, order: 2 }} xs={{ order: 2 }}>
        <div className="align-center pt-1">
          <CardImg
            alt="..."
            // className="yocard-image-about"
            src="images/home/about.png"
          />
        </div>
      </Col>
    </Row>
    <div className="pt-5 my-5" />
  </div>
);

export default AboutYoCard;

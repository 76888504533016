import { ListDistResponse, ListDistResults } from './list-dist.service.types';

export const ListDistTransform: (response: ListDistResponse) => ListDistResults = (listDistResults) => {
  const { data, is_error, message } = listDistResults;
  const result: ListDistResults = {
    tCnt: 0,
    fCnt: 0,
    distData: [],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.tCnt = data.tCnt;
    result.fCnt = data.fCnt;
    result.distData = data.distData;
    result.message = 'executed';
  }
  return result;
};
